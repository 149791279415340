import { useDashboardControls } from 'contexts/Dashboard/useDashboardControls';
import DashboardWidgetsWrapper from 'components/Dashboard/WidgetWrapper';
import { TLWidgetsEnum } from './utils';
import { memo, useMemo } from 'react';
import { WidgetConfig } from 'components/Dashboard/types';
import FTsOnStandbyWidget from './widgets/FTsOnStandbyWidget';
import PlaceholderAssignmentsWidget from './widgets/PlaceholderAssignmentsWidget';
import MissingExpectedExpensesWidget from './widgets/MissingExpectedExpensesWidget';
import IncompleteDayPlanWidget from './widgets/IncompleteDayPlanTomorrowWidget';
import useHasTestAccessToPlaceholderCases from 'hooks/useHasTestAccessToPlaceholderCases';

const widgetConfigGoSupport: WidgetConfig<TLWidgetsEnum>[] = [
  {
    widgetType: TLWidgetsEnum.FTsOnStandby,
    component: memo(FTsOnStandbyWidget),
  },
  {
    widgetType: TLWidgetsEnum.PlaceholderAssignments,
    component: memo(PlaceholderAssignmentsWidget),
  },
  {
    widgetType: TLWidgetsEnum.MissingExpectedExpenses,
    component: memo(MissingExpectedExpensesWidget),
  },
  {
    widgetType: TLWidgetsEnum.IncompleteDayPlan,
    component: memo(IncompleteDayPlanWidget),
  },
];

const DashboardGoSupport = () => {
  const hasAccessToPlaceholderBookings = useHasTestAccessToPlaceholderCases();

  const {
    dashboardState: { widgets },
  } = useDashboardControls();

  const getIsActive = (widgetType: TLWidgetsEnum) => {
    return widgets.some(
      (w) => w.identifier === widgetType && w.active === true
    );
  };

  // During testing we only show all widgets for those with access
  const visibleWidgets = useMemo(() => {
    if (hasAccessToPlaceholderBookings) {
      return widgetConfigGoSupport;
    }
    return widgetConfigGoSupport.filter(
      ({ widgetType }) => widgetType === TLWidgetsEnum.FTsOnStandby
    );
  }, [hasAccessToPlaceholderBookings]);

  return (
    <DashboardWidgetsWrapper>
      {visibleWidgets.map(
        ({ widgetType, component: Component }) =>
          getIsActive(widgetType) && <Component key={widgetType} />
      )}
    </DashboardWidgetsWrapper>
  );
};

export default DashboardGoSupport;
