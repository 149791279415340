import {
  FilterButton,
  FilterPickerContainer,
} from 'components/filterBar/FilterPicker';
import { useDashboardControls } from 'contexts/Dashboard/useDashboardControls';
import { useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { isSameDay } from 'utils/date-helpers';

enum DateSelection {
  None,
  Today,
  Tomorrow,
}

const QuickDateFilter = () => {
  const [selectedQuickFilter, setSelectedQuickFilter] =
    useLocalStorage<DateSelection>('QuickDateFilter', DateSelection.None);
  const {
    dashboardState: { daterange },
    setSelectedDateRange,
  } = useDashboardControls();

  const setQuickDateFilter = (selected: DateSelection) => {
    setSelectedQuickFilter(selected);
  };

  const handleQuickDateFilter = (select: DateSelection) => {
    if (select === selectedQuickFilter) {
      setQuickDateFilter(DateSelection.None);
    } else {
      setQuickDateFilter(select);
    }
  };

  // Set initial date range for tomorrow on mount to override the default date range
  useEffect(() => {
    if (selectedQuickFilter === DateSelection.Tomorrow) {
      setSelectedDateRange([
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() + 1,
          0,
          0,
          0,
          0
        ),
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() + 1,
          23,
          59,
          59,
          999
        ),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update the date range when a quick filter is selected
  useEffect(() => {
    if (selectedQuickFilter === DateSelection.Today) {
      setSelectedDateRange([
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          0,
          0,
          0,
          0
        ),
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          23,
          59,
          59,
          999
        ),
      ]);
    } else if (selectedQuickFilter === DateSelection.Tomorrow) {
      setSelectedDateRange([
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() + 1,
          0,
          0,
          0,
          0
        ),
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() + 1,
          23,
          59,
          59,
          999
        ),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedQuickFilter]);

  useEffect(() => {
    // Reset quick date filter if daterange is changed manually when already has quick filter selected
    if (
      selectedQuickFilter !== DateSelection.None &&
      (!daterange[0] ||
        !daterange[1] ||
        (!!daterange[0] &&
          !!daterange[1] &&
          !isSameDay(daterange[0], daterange[1])))
    ) {
      setQuickDateFilter(DateSelection.None);
    }

    // If no quick filter is selected, set the quick filter based on the daterange if selected date is today or tomorrow
    if (selectedQuickFilter === DateSelection.None) {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      if (
        !!daterange[0] &&
        isSameDay(daterange[0], new Date()) &&
        !!daterange[1] &&
        isSameDay(daterange[1], new Date())
      ) {
        setQuickDateFilter(DateSelection.Today);
      } else if (
        !!daterange[0] &&
        isSameDay(daterange[0], new Date(tomorrow)) &&
        !!daterange[1] &&
        isSameDay(daterange[1], new Date(tomorrow))
      ) {
        setQuickDateFilter(DateSelection.Tomorrow);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [daterange]);

  return (
    <FilterPickerContainer>
      <FilterButton
        selected={selectedQuickFilter === DateSelection.Today}
        onClick={() => handleQuickDateFilter(DateSelection.Today)}
      >
        Idag
      </FilterButton>
      <FilterButton
        selected={selectedQuickFilter === DateSelection.Tomorrow}
        onClick={() => handleQuickDateFilter(DateSelection.Tomorrow)}
      >
        Imorgon
      </FilterButton>
    </FilterPickerContainer>
  );
};

export default QuickDateFilter;
