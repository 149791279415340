/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  CaseEventDTO,
  ExpenseUploadFileBody,
  FileFindFileUploadsByCaseParams,
  FileGetFileParams,
  FileGetSignedUriParams,
} from '.././model';
import useFileUploadFileMutator from '.././mutator/custom-instance';
import useFileGetFileMutator from '.././mutator/custom-instance';
import useFileGetSignedUriMutator from '.././mutator/custom-instance';
import useFileFindFileUploadsByCaseMutator from '.././mutator/custom-instance';

export const useFileUploadFileHook = () => {
  const fileUploadFile = useFileUploadFileMutator<string>();

  return (expenseUploadFileBody: ExpenseUploadFileBody) => {
    const formData = new FormData();
    if (expenseUploadFileBody.imageFile !== undefined) {
      formData.append('imageFile', expenseUploadFileBody.imageFile);
    }
    if (expenseUploadFileBody.fileEnding !== undefined) {
      formData.append('fileEnding', expenseUploadFileBody.fileEnding);
    }

    return fileUploadFile({
      url: `/api/File/UploadFile`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    });
  };
};

export const useFileUploadFileMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useFileUploadFileHook>>>,
    TError,
    { data: ExpenseUploadFileBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useFileUploadFileHook>>>,
  TError,
  { data: ExpenseUploadFileBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const fileUploadFile = useFileUploadFileHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useFileUploadFileHook>>>,
    { data: ExpenseUploadFileBody }
  > = (props) => {
    const { data } = props ?? {};

    return fileUploadFile(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type FileUploadFileMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useFileUploadFileHook>>>
>;
export type FileUploadFileMutationBody = ExpenseUploadFileBody;
export type FileUploadFileMutationError = unknown;

export const useFileUploadFile = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useFileUploadFileHook>>>,
    TError,
    { data: ExpenseUploadFileBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useFileUploadFileHook>>>,
  TError,
  { data: ExpenseUploadFileBody },
  TContext
> => {
  const mutationOptions = useFileUploadFileMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useFileGetFileHook = () => {
  const fileGetFile = useFileGetFileMutator<Blob>();

  return (params?: FileGetFileParams, signal?: AbortSignal) => {
    return fileGetFile({
      url: `/api/File/GetFile`,
      method: 'GET',
      params,
      responseType: 'blob',
      signal,
    });
  };
};

export const getFileGetFileQueryKey = (params?: FileGetFileParams) => {
  return [`/api/File/GetFile`, ...(params ? [params] : [])] as const;
};

export const useFileGetFileQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useFileGetFileHook>>>,
  TError = unknown,
>(
  params?: FileGetFileParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useFileGetFileHook>>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFileGetFileQueryKey(params);

  const fileGetFile = useFileGetFileHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useFileGetFileHook>>>
  > = ({ signal }) => fileGetFile(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useFileGetFileHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FileGetFileQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useFileGetFileHook>>>
>;
export type FileGetFileQueryError = unknown;

export const useFileGetFile = <
  TData = Awaited<ReturnType<ReturnType<typeof useFileGetFileHook>>>,
  TError = unknown,
>(
  params?: FileGetFileParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useFileGetFileHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useFileGetFileQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useFileGetSignedUriHook = () => {
  const fileGetSignedUri = useFileGetSignedUriMutator<string>();

  return (params?: FileGetSignedUriParams, signal?: AbortSignal) => {
    return fileGetSignedUri({
      url: `/api/File/GetSignedUri`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getFileGetSignedUriQueryKey = (
  params?: FileGetSignedUriParams
) => {
  return [`/api/File/GetSignedUri`, ...(params ? [params] : [])] as const;
};

export const useFileGetSignedUriQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useFileGetSignedUriHook>>>,
  TError = unknown,
>(
  params?: FileGetSignedUriParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useFileGetSignedUriHook>>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getFileGetSignedUriQueryKey(params);

  const fileGetSignedUri = useFileGetSignedUriHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useFileGetSignedUriHook>>>
  > = ({ signal }) => fileGetSignedUri(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useFileGetSignedUriHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FileGetSignedUriQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useFileGetSignedUriHook>>>
>;
export type FileGetSignedUriQueryError = unknown;

export const useFileGetSignedUri = <
  TData = Awaited<ReturnType<ReturnType<typeof useFileGetSignedUriHook>>>,
  TError = unknown,
>(
  params?: FileGetSignedUriParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useFileGetSignedUriHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useFileGetSignedUriQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useFileFindFileUploadsByCaseHook = () => {
  const fileFindFileUploadsByCase =
    useFileFindFileUploadsByCaseMutator<CaseEventDTO[]>();

  return (params?: FileFindFileUploadsByCaseParams) => {
    return fileFindFileUploadsByCase({
      url: `/api/File/FindFileUploadsByCase`,
      method: 'POST',
      params,
    });
  };
};

export const useFileFindFileUploadsByCaseMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useFileFindFileUploadsByCaseHook>>>,
    TError,
    { params?: FileFindFileUploadsByCaseParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useFileFindFileUploadsByCaseHook>>>,
  TError,
  { params?: FileFindFileUploadsByCaseParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const fileFindFileUploadsByCase = useFileFindFileUploadsByCaseHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useFileFindFileUploadsByCaseHook>>>,
    { params?: FileFindFileUploadsByCaseParams }
  > = (props) => {
    const { params } = props ?? {};

    return fileFindFileUploadsByCase(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type FileFindFileUploadsByCaseMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useFileFindFileUploadsByCaseHook>>>
>;

export type FileFindFileUploadsByCaseMutationError = unknown;

export const useFileFindFileUploadsByCase = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useFileFindFileUploadsByCaseHook>>>,
    TError,
    { params?: FileFindFileUploadsByCaseParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useFileFindFileUploadsByCaseHook>>>,
  TError,
  { params?: FileFindFileUploadsByCaseParams },
  TContext
> => {
  const mutationOptions = useFileFindFileUploadsByCaseMutationOptions(options);

  return useMutation(mutationOptions);
};
