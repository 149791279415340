import CommentsWidget from './widgets/CommentsWidget';
import FaultyCarWidget from './widgets/FaultyCarWidget';
import { useDashboardControls } from 'contexts/Dashboard/useDashboardControls';
import DashboardWidgetsWrapper from 'components/Dashboard/WidgetWrapper';
import { GoSupportWidgetsEnum } from './utils';
import { memo } from 'react';
import { WidgetConfig } from 'components/Dashboard/types';
import BookedToEditedByFTWidget from './widgets/BookedToEditedByFTWidget';
import DeviationReportsWidget from './widgets/DeviationReportsWidget';
import NotAcceptedTomorrowsAssignmentsWidget from './widgets/NotAcceptedTomorrowsAssignmentsWidget';
import BookedToTimePassedWidget from './widgets/BookedToTimePassedWidget';
import EstimatedEndTimePassedWidget from './widgets/EstimatedEndTimePassedWidget';
import EstimatedStartTimePassedWidget from './widgets/EstimatedStartTimePassedWidget';
import WorkGearReportsWidget from './widgets/WorkGearReportsWidget';
import FlaggedForSpeedingWidget from './widgets/FlaggedForSpeedingWidget';
import IncompleteDayPlanWidget from './widgets/IncompleteDayPlanWidget';
import NotLoadedTyresWidget from './widgets/NotLoadedTyresWidget';
import AssignmentRescheduledToAfterMidnightWidget from './widgets/AssignmentRescheduledToAfterMidnightWidget';

const widgetConfigGoSupport: WidgetConfig<GoSupportWidgetsEnum>[] = [
  {
    widgetType: GoSupportWidgetsEnum.FaultyCar,
    component: memo(FaultyCarWidget),
  },
  {
    widgetType: GoSupportWidgetsEnum.Comments,
    component: memo(CommentsWidget),
  },
  {
    widgetType: GoSupportWidgetsEnum.DeviationReports,
    component: memo(DeviationReportsWidget),
  },
  {
    widgetType: GoSupportWidgetsEnum.BookedToEditedByFT,
    component: memo(BookedToEditedByFTWidget),
  },
  {
    widgetType: GoSupportWidgetsEnum.NotAcceptedTomorrowsAssignments,
    component: memo(NotAcceptedTomorrowsAssignmentsWidget),
  },
  {
    widgetType: GoSupportWidgetsEnum.EstimatedStartTimePassed,
    component: memo(EstimatedStartTimePassedWidget),
  },
  {
    widgetType: GoSupportWidgetsEnum.EstimatedEndTimePassed,
    component: memo(EstimatedEndTimePassedWidget),
  },
  {
    widgetType: GoSupportWidgetsEnum.BookedToTimePassed,
    component: memo(BookedToTimePassedWidget),
  },
  {
    widgetType: GoSupportWidgetsEnum.WorkGearReports,
    component: memo(WorkGearReportsWidget),
  },
  {
    widgetType: GoSupportWidgetsEnum.FlaggedForSpeeding,
    component: memo(FlaggedForSpeedingWidget),
  },
  {
    widgetType: GoSupportWidgetsEnum.NotLoadedTyres,
    component: memo(NotLoadedTyresWidget),
  },
  {
    widgetType: GoSupportWidgetsEnum.IncompleteDayPlan,
    component: memo(IncompleteDayPlanWidget),
  },
  {
    widgetType: GoSupportWidgetsEnum.AssignmentRescheduledToAfterMidnight,
    component: memo(AssignmentRescheduledToAfterMidnightWidget),
  },
];

const DashboardGoSupport = () => {
  const {
    dashboardState: { widgets },
  } = useDashboardControls();

  const getIsActive = (widgetType: GoSupportWidgetsEnum) => {
    return widgets.some(
      (w) => w.identifier === widgetType && w.active === true
    );
  };

  return (
    <DashboardWidgetsWrapper>
      {widgetConfigGoSupport.map(
        ({ widgetType, component: Component }) =>
          getIsActive(widgetType) && <Component key={widgetType} />
      )}
    </DashboardWidgetsWrapper>
  );
};

export default DashboardGoSupport;
