import { ZipCodeArea } from 'api';
import useTranslations from 'contexts/basicData/useTranslations';
import { useMemo } from 'react';

const useZipCodeAreas = () => {
  const { zipCodeAreas } = useTranslations();
  const largeCounties = useMemo(
    () =>
      Object.values(zipCodeAreas).filter(
        (zca) => zca.areaName === 'Stockholm' || zca.areaName === 'Göteborg'
      ),
    [zipCodeAreas]
  );

  const allZipCodeAreas = useMemo(
    () =>
      Object.values(zipCodeAreas).sort((a, b) =>
        a.areaName > b.areaName ? 1 : -1
      ),
    [zipCodeAreas]
  );

  const zipCodeAreaByName = useMemo(() => {
    const areasByName = {} as { [areaName: string]: ZipCodeArea };
    allZipCodeAreas.forEach((zca) => {
      if (!(zca.areaName in areasByName)) {
        areasByName[zca.areaName] = zca;
      }
    });

    return areasByName;
  }, [allZipCodeAreas]);

  const selectableZipCodeAreas = useMemo(() => {
    return Object.values(zipCodeAreaByName).sort((a, b) =>
      a.areaName > b.areaName ? 1 : -1
    );
  }, [zipCodeAreaByName]);

  const zipCodeAreaFromZipCode = (zipCode: string) => {
    const zipNumber = Number(zipCode.replace(' ', ''));
    if (!Number.isNaN(zipNumber) && zipNumber >= 10000) {
      const zipArea = allZipCodeAreas.find(
        (zca) => zca.minCode <= zipNumber && zca.maxCode >= zipNumber
      );
      return zipArea?.areaName !== undefined
        ? zipCodeAreaByName[zipArea.areaName]
        : undefined;
    }

    return undefined;
  };

  const isOutsideAllowedRange = (zipCode1: string, zipCode2: string) => {
    const matchingFirstTwoDigits =
      zipCode1.substring(0, 2) === zipCode2.substring(0, 2);

    if (matchingFirstTwoDigits) {
      return false; // Is always within the same county
    }

    if (largeCounties.length === 0) {
      return true; // No large counties that need to be checked
    }

    const zip1 = parseInt(zipCode1.replace(' ', ''), 10);
    const zip2 = parseInt(zipCode2.replace(' ', ''), 10);

    const county1 = largeCounties.find(
      (r) => r.minCode <= zip1 && r.maxCode >= zip1
    );
    const county2 = largeCounties.find(
      (r) => r.minCode <= zip2 && r.maxCode >= zip2
    );

    if (!county1 || !county2) {
      return true; // One of the zips are not within a large county, so we assume they are different
    }

    if (county1.id === county2.id) {
      return false; // Both zips are within the same large county, which is allowed
    }

    return true; // The zips are within different large counties
  };

  return {
    allZipCodeAreas,
    zipCodeAreaByName,
    selectableZipCodeAreas,
    zipCodeAreaFromZipCode,
    isOutsideAllowedRange,
  };
};

export default useZipCodeAreas;
