/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */
import { useQuery } from '@tanstack/react-query';
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  AssignmentDTO,
  CaseEventDTO,
  FieldTesterIncompleteDayPlanDTO,
  GoSupportGetAssignmentBookedToEditedByFieldtesterParams,
  GoSupportGetAssignmentsBookedToTimePassedParams,
  GoSupportGetAssignmentsEstimatedEndTimePassedParams,
  GoSupportGetAssignmentsEstimatedStartTimePassedParams,
  GoSupportGetAssignmentsNotYetAcceptedByFTForTomorrowParams,
  GoSupportGetFieldtestersWithOutsideHomeCountyParams,
  GoSupportGetUnhandledAssignmentRescheduledToAfterMidnightParams,
  GoSupportGetUnhandledDeviationReportsParams,
  GoSupportGetUnhandledFaultyCarsParams,
  GoSupportGetUnhandledFlaggedForSpeedingParams,
  GoSupportGetUnhandledNoTyresLoadedEventsParams,
  GoSupportGetUnhandledWorkGearReportsParams,
  GoSupportGetUnreadCommentsParams,
} from '.././model';
import useGoSupportGetUnreadCommentsMutator from '.././mutator/custom-instance';
import useGoSupportGetUnhandledFaultyCarsMutator from '.././mutator/custom-instance';
import useGoSupportGetUnhandledDeviationReportsMutator from '.././mutator/custom-instance';
import useGoSupportGetAssignmentBookedToEditedByFieldtesterMutator from '.././mutator/custom-instance';
import useGoSupportGetAssignmentsNotYetAcceptedByFTForTomorrowMutator from '.././mutator/custom-instance';
import useGoSupportGetAssignmentsEstimatedStartTimePassedMutator from '.././mutator/custom-instance';
import useGoSupportGetAssignmentsEstimatedEndTimePassedMutator from '.././mutator/custom-instance';
import useGoSupportGetAssignmentsBookedToTimePassedMutator from '.././mutator/custom-instance';
import useGoSupportGetUnhandledWorkGearReportsMutator from '.././mutator/custom-instance';
import useGoSupportGetUnhandledFlaggedForSpeedingMutator from '.././mutator/custom-instance';
import useGoSupportGetFieldtestersWithOutsideHomeCountyMutator from '.././mutator/custom-instance';
import useGoSupportGetUnhandledNoTyresLoadedEventsMutator from '.././mutator/custom-instance';
import useGoSupportGetUnhandledAssignmentRescheduledToAfterMidnightMutator from '.././mutator/custom-instance';

export const useGoSupportGetUnreadCommentsHook = () => {
  const goSupportGetUnreadComments =
    useGoSupportGetUnreadCommentsMutator<CaseEventDTO[]>();

  return (params?: GoSupportGetUnreadCommentsParams, signal?: AbortSignal) => {
    return goSupportGetUnreadComments({
      url: `/api/GoSupport/GetUnreadComments`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getGoSupportGetUnreadCommentsQueryKey = (
  params?: GoSupportGetUnreadCommentsParams
) => {
  return [
    `/api/GoSupport/GetUnreadComments`,
    ...(params ? [params] : []),
  ] as const;
};

export const useGoSupportGetUnreadCommentsQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGoSupportGetUnreadCommentsHook>>
  >,
  TError = unknown,
>(
  params?: GoSupportGetUnreadCommentsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGoSupportGetUnreadCommentsHook>>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGoSupportGetUnreadCommentsQueryKey(params);

  const goSupportGetUnreadComments = useGoSupportGetUnreadCommentsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGoSupportGetUnreadCommentsHook>>>
  > = ({ signal }) => goSupportGetUnreadComments(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGoSupportGetUnreadCommentsHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GoSupportGetUnreadCommentsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGoSupportGetUnreadCommentsHook>>>
>;
export type GoSupportGetUnreadCommentsQueryError = unknown;

export const useGoSupportGetUnreadComments = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGoSupportGetUnreadCommentsHook>>
  >,
  TError = unknown,
>(
  params?: GoSupportGetUnreadCommentsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGoSupportGetUnreadCommentsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGoSupportGetUnreadCommentsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGoSupportGetUnhandledFaultyCarsHook = () => {
  const goSupportGetUnhandledFaultyCars =
    useGoSupportGetUnhandledFaultyCarsMutator<CaseEventDTO[]>();

  return (
    params?: GoSupportGetUnhandledFaultyCarsParams,
    signal?: AbortSignal
  ) => {
    return goSupportGetUnhandledFaultyCars({
      url: `/api/GoSupport/GetUnhandledFaultyCars`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getGoSupportGetUnhandledFaultyCarsQueryKey = (
  params?: GoSupportGetUnhandledFaultyCarsParams
) => {
  return [
    `/api/GoSupport/GetUnhandledFaultyCars`,
    ...(params ? [params] : []),
  ] as const;
};

export const useGoSupportGetUnhandledFaultyCarsQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGoSupportGetUnhandledFaultyCarsHook>>
  >,
  TError = unknown,
>(
  params?: GoSupportGetUnhandledFaultyCarsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useGoSupportGetUnhandledFaultyCarsHook>>
      >,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGoSupportGetUnhandledFaultyCarsQueryKey(params);

  const goSupportGetUnhandledFaultyCars =
    useGoSupportGetUnhandledFaultyCarsHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<ReturnType<typeof useGoSupportGetUnhandledFaultyCarsHook>>
    >
  > = ({ signal }) => goSupportGetUnhandledFaultyCars(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useGoSupportGetUnhandledFaultyCarsHook>>
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GoSupportGetUnhandledFaultyCarsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGoSupportGetUnhandledFaultyCarsHook>>>
>;
export type GoSupportGetUnhandledFaultyCarsQueryError = unknown;

export const useGoSupportGetUnhandledFaultyCars = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGoSupportGetUnhandledFaultyCarsHook>>
  >,
  TError = unknown,
>(
  params?: GoSupportGetUnhandledFaultyCarsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useGoSupportGetUnhandledFaultyCarsHook>>
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGoSupportGetUnhandledFaultyCarsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGoSupportGetUnhandledDeviationReportsHook = () => {
  const goSupportGetUnhandledDeviationReports =
    useGoSupportGetUnhandledDeviationReportsMutator<CaseEventDTO[]>();

  return (
    params?: GoSupportGetUnhandledDeviationReportsParams,
    signal?: AbortSignal
  ) => {
    return goSupportGetUnhandledDeviationReports({
      url: `/api/GoSupport/GetUnhandledDeviationReports`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getGoSupportGetUnhandledDeviationReportsQueryKey = (
  params?: GoSupportGetUnhandledDeviationReportsParams
) => {
  return [
    `/api/GoSupport/GetUnhandledDeviationReports`,
    ...(params ? [params] : []),
  ] as const;
};

export const useGoSupportGetUnhandledDeviationReportsQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGoSupportGetUnhandledDeviationReportsHook>>
  >,
  TError = unknown,
>(
  params?: GoSupportGetUnhandledDeviationReportsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<typeof useGoSupportGetUnhandledDeviationReportsHook>
        >
      >,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGoSupportGetUnhandledDeviationReportsQueryKey(params);

  const goSupportGetUnhandledDeviationReports =
    useGoSupportGetUnhandledDeviationReportsHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useGoSupportGetUnhandledDeviationReportsHook>
      >
    >
  > = ({ signal }) => goSupportGetUnhandledDeviationReports(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useGoSupportGetUnhandledDeviationReportsHook>
      >
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GoSupportGetUnhandledDeviationReportsQueryResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useGoSupportGetUnhandledDeviationReportsHook>>
  >
>;
export type GoSupportGetUnhandledDeviationReportsQueryError = unknown;

export const useGoSupportGetUnhandledDeviationReports = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGoSupportGetUnhandledDeviationReportsHook>>
  >,
  TError = unknown,
>(
  params?: GoSupportGetUnhandledDeviationReportsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<typeof useGoSupportGetUnhandledDeviationReportsHook>
        >
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGoSupportGetUnhandledDeviationReportsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGoSupportGetAssignmentBookedToEditedByFieldtesterHook = () => {
  const goSupportGetAssignmentBookedToEditedByFieldtester =
    useGoSupportGetAssignmentBookedToEditedByFieldtesterMutator<
      CaseEventDTO[]
    >();

  return (
    params?: GoSupportGetAssignmentBookedToEditedByFieldtesterParams,
    signal?: AbortSignal
  ) => {
    return goSupportGetAssignmentBookedToEditedByFieldtester({
      url: `/api/GoSupport/GetAssignmentBookedToEditedByFieldtester`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getGoSupportGetAssignmentBookedToEditedByFieldtesterQueryKey = (
  params?: GoSupportGetAssignmentBookedToEditedByFieldtesterParams
) => {
  return [
    `/api/GoSupport/GetAssignmentBookedToEditedByFieldtester`,
    ...(params ? [params] : []),
  ] as const;
};

export const useGoSupportGetAssignmentBookedToEditedByFieldtesterQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        ReturnType<
          typeof useGoSupportGetAssignmentBookedToEditedByFieldtesterHook
        >
      >
    >,
    TError = unknown,
  >(
    params?: GoSupportGetAssignmentBookedToEditedByFieldtesterParams,
    options?: {
      query?: UseQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<
              typeof useGoSupportGetAssignmentBookedToEditedByFieldtesterHook
            >
          >
        >,
        TError,
        TData
      >;
    }
  ) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGoSupportGetAssignmentBookedToEditedByFieldtesterQueryKey(params);

    const goSupportGetAssignmentBookedToEditedByFieldtester =
      useGoSupportGetAssignmentBookedToEditedByFieldtesterHook();

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useGoSupportGetAssignmentBookedToEditedByFieldtesterHook
          >
        >
      >
    > = ({ signal }) =>
      goSupportGetAssignmentBookedToEditedByFieldtester(params, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useGoSupportGetAssignmentBookedToEditedByFieldtesterHook
          >
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GoSupportGetAssignmentBookedToEditedByFieldtesterQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useGoSupportGetAssignmentBookedToEditedByFieldtesterHook
        >
      >
    >
  >;
export type GoSupportGetAssignmentBookedToEditedByFieldtesterQueryError =
  unknown;

export const useGoSupportGetAssignmentBookedToEditedByFieldtester = <
  TData = Awaited<
    ReturnType<
      ReturnType<
        typeof useGoSupportGetAssignmentBookedToEditedByFieldtesterHook
      >
    >
  >,
  TError = unknown,
>(
  params?: GoSupportGetAssignmentBookedToEditedByFieldtesterParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useGoSupportGetAssignmentBookedToEditedByFieldtesterHook
          >
        >
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    useGoSupportGetAssignmentBookedToEditedByFieldtesterQueryOptions(
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGoSupportGetAssignmentsNotYetAcceptedByFTForTomorrowHook =
  () => {
    const goSupportGetAssignmentsNotYetAcceptedByFTForTomorrow =
      useGoSupportGetAssignmentsNotYetAcceptedByFTForTomorrowMutator<
        AssignmentDTO[]
      >();

    return (
      params?: GoSupportGetAssignmentsNotYetAcceptedByFTForTomorrowParams,
      signal?: AbortSignal
    ) => {
      return goSupportGetAssignmentsNotYetAcceptedByFTForTomorrow({
        url: `/api/GoSupport/GetAssignmentsNotYetAcceptedByFTForTomorrow`,
        method: 'GET',
        params,
        signal,
      });
    };
  };

export const getGoSupportGetAssignmentsNotYetAcceptedByFTForTomorrowQueryKey = (
  params?: GoSupportGetAssignmentsNotYetAcceptedByFTForTomorrowParams
) => {
  return [
    `/api/GoSupport/GetAssignmentsNotYetAcceptedByFTForTomorrow`,
    ...(params ? [params] : []),
  ] as const;
};

export const useGoSupportGetAssignmentsNotYetAcceptedByFTForTomorrowQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        ReturnType<
          typeof useGoSupportGetAssignmentsNotYetAcceptedByFTForTomorrowHook
        >
      >
    >,
    TError = unknown,
  >(
    params?: GoSupportGetAssignmentsNotYetAcceptedByFTForTomorrowParams,
    options?: {
      query?: UseQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<
              typeof useGoSupportGetAssignmentsNotYetAcceptedByFTForTomorrowHook
            >
          >
        >,
        TError,
        TData
      >;
    }
  ) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGoSupportGetAssignmentsNotYetAcceptedByFTForTomorrowQueryKey(params);

    const goSupportGetAssignmentsNotYetAcceptedByFTForTomorrow =
      useGoSupportGetAssignmentsNotYetAcceptedByFTForTomorrowHook();

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useGoSupportGetAssignmentsNotYetAcceptedByFTForTomorrowHook
          >
        >
      >
    > = ({ signal }) =>
      goSupportGetAssignmentsNotYetAcceptedByFTForTomorrow(params, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useGoSupportGetAssignmentsNotYetAcceptedByFTForTomorrowHook
          >
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GoSupportGetAssignmentsNotYetAcceptedByFTForTomorrowQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useGoSupportGetAssignmentsNotYetAcceptedByFTForTomorrowHook
        >
      >
    >
  >;
export type GoSupportGetAssignmentsNotYetAcceptedByFTForTomorrowQueryError =
  unknown;

export const useGoSupportGetAssignmentsNotYetAcceptedByFTForTomorrow = <
  TData = Awaited<
    ReturnType<
      ReturnType<
        typeof useGoSupportGetAssignmentsNotYetAcceptedByFTForTomorrowHook
      >
    >
  >,
  TError = unknown,
>(
  params?: GoSupportGetAssignmentsNotYetAcceptedByFTForTomorrowParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useGoSupportGetAssignmentsNotYetAcceptedByFTForTomorrowHook
          >
        >
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    useGoSupportGetAssignmentsNotYetAcceptedByFTForTomorrowQueryOptions(
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGoSupportGetAssignmentsEstimatedStartTimePassedHook = () => {
  const goSupportGetAssignmentsEstimatedStartTimePassed =
    useGoSupportGetAssignmentsEstimatedStartTimePassedMutator<
      AssignmentDTO[]
    >();

  return (
    params?: GoSupportGetAssignmentsEstimatedStartTimePassedParams,
    signal?: AbortSignal
  ) => {
    return goSupportGetAssignmentsEstimatedStartTimePassed({
      url: `/api/GoSupport/GetAssignmentsEstimatedStartTimePassed`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getGoSupportGetAssignmentsEstimatedStartTimePassedQueryKey = (
  params?: GoSupportGetAssignmentsEstimatedStartTimePassedParams
) => {
  return [
    `/api/GoSupport/GetAssignmentsEstimatedStartTimePassed`,
    ...(params ? [params] : []),
  ] as const;
};

export const useGoSupportGetAssignmentsEstimatedStartTimePassedQueryOptions = <
  TData = Awaited<
    ReturnType<
      ReturnType<typeof useGoSupportGetAssignmentsEstimatedStartTimePassedHook>
    >
  >,
  TError = unknown,
>(
  params?: GoSupportGetAssignmentsEstimatedStartTimePassedParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useGoSupportGetAssignmentsEstimatedStartTimePassedHook
          >
        >
      >,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGoSupportGetAssignmentsEstimatedStartTimePassedQueryKey(params);

  const goSupportGetAssignmentsEstimatedStartTimePassed =
    useGoSupportGetAssignmentsEstimatedStartTimePassedHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useGoSupportGetAssignmentsEstimatedStartTimePassedHook
        >
      >
    >
  > = ({ signal }) =>
    goSupportGetAssignmentsEstimatedStartTimePassed(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useGoSupportGetAssignmentsEstimatedStartTimePassedHook
        >
      >
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GoSupportGetAssignmentsEstimatedStartTimePassedQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useGoSupportGetAssignmentsEstimatedStartTimePassedHook
        >
      >
    >
  >;
export type GoSupportGetAssignmentsEstimatedStartTimePassedQueryError = unknown;

export const useGoSupportGetAssignmentsEstimatedStartTimePassed = <
  TData = Awaited<
    ReturnType<
      ReturnType<typeof useGoSupportGetAssignmentsEstimatedStartTimePassedHook>
    >
  >,
  TError = unknown,
>(
  params?: GoSupportGetAssignmentsEstimatedStartTimePassedParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useGoSupportGetAssignmentsEstimatedStartTimePassedHook
          >
        >
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    useGoSupportGetAssignmentsEstimatedStartTimePassedQueryOptions(
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGoSupportGetAssignmentsEstimatedEndTimePassedHook = () => {
  const goSupportGetAssignmentsEstimatedEndTimePassed =
    useGoSupportGetAssignmentsEstimatedEndTimePassedMutator<AssignmentDTO[]>();

  return (
    params?: GoSupportGetAssignmentsEstimatedEndTimePassedParams,
    signal?: AbortSignal
  ) => {
    return goSupportGetAssignmentsEstimatedEndTimePassed({
      url: `/api/GoSupport/GetAssignmentsEstimatedEndTimePassed`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getGoSupportGetAssignmentsEstimatedEndTimePassedQueryKey = (
  params?: GoSupportGetAssignmentsEstimatedEndTimePassedParams
) => {
  return [
    `/api/GoSupport/GetAssignmentsEstimatedEndTimePassed`,
    ...(params ? [params] : []),
  ] as const;
};

export const useGoSupportGetAssignmentsEstimatedEndTimePassedQueryOptions = <
  TData = Awaited<
    ReturnType<
      ReturnType<typeof useGoSupportGetAssignmentsEstimatedEndTimePassedHook>
    >
  >,
  TError = unknown,
>(
  params?: GoSupportGetAssignmentsEstimatedEndTimePassedParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useGoSupportGetAssignmentsEstimatedEndTimePassedHook
          >
        >
      >,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGoSupportGetAssignmentsEstimatedEndTimePassedQueryKey(params);

  const goSupportGetAssignmentsEstimatedEndTimePassed =
    useGoSupportGetAssignmentsEstimatedEndTimePassedHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useGoSupportGetAssignmentsEstimatedEndTimePassedHook>
      >
    >
  > = ({ signal }) =>
    goSupportGetAssignmentsEstimatedEndTimePassed(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useGoSupportGetAssignmentsEstimatedEndTimePassedHook>
      >
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GoSupportGetAssignmentsEstimatedEndTimePassedQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<typeof useGoSupportGetAssignmentsEstimatedEndTimePassedHook>
      >
    >
  >;
export type GoSupportGetAssignmentsEstimatedEndTimePassedQueryError = unknown;

export const useGoSupportGetAssignmentsEstimatedEndTimePassed = <
  TData = Awaited<
    ReturnType<
      ReturnType<typeof useGoSupportGetAssignmentsEstimatedEndTimePassedHook>
    >
  >,
  TError = unknown,
>(
  params?: GoSupportGetAssignmentsEstimatedEndTimePassedParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useGoSupportGetAssignmentsEstimatedEndTimePassedHook
          >
        >
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    useGoSupportGetAssignmentsEstimatedEndTimePassedQueryOptions(
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGoSupportGetAssignmentsBookedToTimePassedHook = () => {
  const goSupportGetAssignmentsBookedToTimePassed =
    useGoSupportGetAssignmentsBookedToTimePassedMutator<AssignmentDTO[]>();

  return (
    params?: GoSupportGetAssignmentsBookedToTimePassedParams,
    signal?: AbortSignal
  ) => {
    return goSupportGetAssignmentsBookedToTimePassed({
      url: `/api/GoSupport/GetAssignmentsBookedToTimePassed`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getGoSupportGetAssignmentsBookedToTimePassedQueryKey = (
  params?: GoSupportGetAssignmentsBookedToTimePassedParams
) => {
  return [
    `/api/GoSupport/GetAssignmentsBookedToTimePassed`,
    ...(params ? [params] : []),
  ] as const;
};

export const useGoSupportGetAssignmentsBookedToTimePassedQueryOptions = <
  TData = Awaited<
    ReturnType<
      ReturnType<typeof useGoSupportGetAssignmentsBookedToTimePassedHook>
    >
  >,
  TError = unknown,
>(
  params?: GoSupportGetAssignmentsBookedToTimePassedParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<typeof useGoSupportGetAssignmentsBookedToTimePassedHook>
        >
      >,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGoSupportGetAssignmentsBookedToTimePassedQueryKey(params);

  const goSupportGetAssignmentsBookedToTimePassed =
    useGoSupportGetAssignmentsBookedToTimePassedHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useGoSupportGetAssignmentsBookedToTimePassedHook>
      >
    >
  > = ({ signal }) => goSupportGetAssignmentsBookedToTimePassed(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useGoSupportGetAssignmentsBookedToTimePassedHook>
      >
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GoSupportGetAssignmentsBookedToTimePassedQueryResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<typeof useGoSupportGetAssignmentsBookedToTimePassedHook>
    >
  >
>;
export type GoSupportGetAssignmentsBookedToTimePassedQueryError = unknown;

export const useGoSupportGetAssignmentsBookedToTimePassed = <
  TData = Awaited<
    ReturnType<
      ReturnType<typeof useGoSupportGetAssignmentsBookedToTimePassedHook>
    >
  >,
  TError = unknown,
>(
  params?: GoSupportGetAssignmentsBookedToTimePassedParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<typeof useGoSupportGetAssignmentsBookedToTimePassedHook>
        >
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGoSupportGetAssignmentsBookedToTimePassedQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGoSupportGetUnhandledWorkGearReportsHook = () => {
  const goSupportGetUnhandledWorkGearReports =
    useGoSupportGetUnhandledWorkGearReportsMutator<CaseEventDTO[]>();

  return (
    params?: GoSupportGetUnhandledWorkGearReportsParams,
    signal?: AbortSignal
  ) => {
    return goSupportGetUnhandledWorkGearReports({
      url: `/api/GoSupport/GetUnhandledWorkGearReports`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getGoSupportGetUnhandledWorkGearReportsQueryKey = (
  params?: GoSupportGetUnhandledWorkGearReportsParams
) => {
  return [
    `/api/GoSupport/GetUnhandledWorkGearReports`,
    ...(params ? [params] : []),
  ] as const;
};

export const useGoSupportGetUnhandledWorkGearReportsQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGoSupportGetUnhandledWorkGearReportsHook>>
  >,
  TError = unknown,
>(
  params?: GoSupportGetUnhandledWorkGearReportsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<typeof useGoSupportGetUnhandledWorkGearReportsHook>
        >
      >,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGoSupportGetUnhandledWorkGearReportsQueryKey(params);

  const goSupportGetUnhandledWorkGearReports =
    useGoSupportGetUnhandledWorkGearReportsHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<ReturnType<typeof useGoSupportGetUnhandledWorkGearReportsHook>>
    >
  > = ({ signal }) => goSupportGetUnhandledWorkGearReports(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useGoSupportGetUnhandledWorkGearReportsHook>>
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GoSupportGetUnhandledWorkGearReportsQueryResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useGoSupportGetUnhandledWorkGearReportsHook>>
  >
>;
export type GoSupportGetUnhandledWorkGearReportsQueryError = unknown;

export const useGoSupportGetUnhandledWorkGearReports = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGoSupportGetUnhandledWorkGearReportsHook>>
  >,
  TError = unknown,
>(
  params?: GoSupportGetUnhandledWorkGearReportsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<typeof useGoSupportGetUnhandledWorkGearReportsHook>
        >
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGoSupportGetUnhandledWorkGearReportsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGoSupportGetUnhandledFlaggedForSpeedingHook = () => {
  const goSupportGetUnhandledFlaggedForSpeeding =
    useGoSupportGetUnhandledFlaggedForSpeedingMutator<CaseEventDTO[]>();

  return (
    params?: GoSupportGetUnhandledFlaggedForSpeedingParams,
    signal?: AbortSignal
  ) => {
    return goSupportGetUnhandledFlaggedForSpeeding({
      url: `/api/GoSupport/GetUnhandledFlaggedForSpeeding`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getGoSupportGetUnhandledFlaggedForSpeedingQueryKey = (
  params?: GoSupportGetUnhandledFlaggedForSpeedingParams
) => {
  return [
    `/api/GoSupport/GetUnhandledFlaggedForSpeeding`,
    ...(params ? [params] : []),
  ] as const;
};

export const useGoSupportGetUnhandledFlaggedForSpeedingQueryOptions = <
  TData = Awaited<
    ReturnType<
      ReturnType<typeof useGoSupportGetUnhandledFlaggedForSpeedingHook>
    >
  >,
  TError = unknown,
>(
  params?: GoSupportGetUnhandledFlaggedForSpeedingParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<typeof useGoSupportGetUnhandledFlaggedForSpeedingHook>
        >
      >,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGoSupportGetUnhandledFlaggedForSpeedingQueryKey(params);

  const goSupportGetUnhandledFlaggedForSpeeding =
    useGoSupportGetUnhandledFlaggedForSpeedingHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useGoSupportGetUnhandledFlaggedForSpeedingHook>
      >
    >
  > = ({ signal }) => goSupportGetUnhandledFlaggedForSpeeding(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useGoSupportGetUnhandledFlaggedForSpeedingHook>
      >
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GoSupportGetUnhandledFlaggedForSpeedingQueryResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<typeof useGoSupportGetUnhandledFlaggedForSpeedingHook>
    >
  >
>;
export type GoSupportGetUnhandledFlaggedForSpeedingQueryError = unknown;

export const useGoSupportGetUnhandledFlaggedForSpeeding = <
  TData = Awaited<
    ReturnType<
      ReturnType<typeof useGoSupportGetUnhandledFlaggedForSpeedingHook>
    >
  >,
  TError = unknown,
>(
  params?: GoSupportGetUnhandledFlaggedForSpeedingParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<typeof useGoSupportGetUnhandledFlaggedForSpeedingHook>
        >
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGoSupportGetUnhandledFlaggedForSpeedingQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGoSupportGetFieldtestersWithOutsideHomeCountyHook = () => {
  const goSupportGetFieldtestersWithOutsideHomeCounty =
    useGoSupportGetFieldtestersWithOutsideHomeCountyMutator<
      FieldTesterIncompleteDayPlanDTO[]
    >();

  return (
    params?: GoSupportGetFieldtestersWithOutsideHomeCountyParams,
    signal?: AbortSignal
  ) => {
    return goSupportGetFieldtestersWithOutsideHomeCounty({
      url: `/api/GoSupport/GetFieldtestersWithOutsideHomeCounty`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getGoSupportGetFieldtestersWithOutsideHomeCountyQueryKey = (
  params?: GoSupportGetFieldtestersWithOutsideHomeCountyParams
) => {
  return [
    `/api/GoSupport/GetFieldtestersWithOutsideHomeCounty`,
    ...(params ? [params] : []),
  ] as const;
};

export const useGoSupportGetFieldtestersWithOutsideHomeCountyQueryOptions = <
  TData = Awaited<
    ReturnType<
      ReturnType<typeof useGoSupportGetFieldtestersWithOutsideHomeCountyHook>
    >
  >,
  TError = unknown,
>(
  params?: GoSupportGetFieldtestersWithOutsideHomeCountyParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useGoSupportGetFieldtestersWithOutsideHomeCountyHook
          >
        >
      >,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGoSupportGetFieldtestersWithOutsideHomeCountyQueryKey(params);

  const goSupportGetFieldtestersWithOutsideHomeCounty =
    useGoSupportGetFieldtestersWithOutsideHomeCountyHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useGoSupportGetFieldtestersWithOutsideHomeCountyHook>
      >
    >
  > = ({ signal }) =>
    goSupportGetFieldtestersWithOutsideHomeCounty(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useGoSupportGetFieldtestersWithOutsideHomeCountyHook>
      >
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GoSupportGetFieldtestersWithOutsideHomeCountyQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<typeof useGoSupportGetFieldtestersWithOutsideHomeCountyHook>
      >
    >
  >;
export type GoSupportGetFieldtestersWithOutsideHomeCountyQueryError = unknown;

export const useGoSupportGetFieldtestersWithOutsideHomeCounty = <
  TData = Awaited<
    ReturnType<
      ReturnType<typeof useGoSupportGetFieldtestersWithOutsideHomeCountyHook>
    >
  >,
  TError = unknown,
>(
  params?: GoSupportGetFieldtestersWithOutsideHomeCountyParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useGoSupportGetFieldtestersWithOutsideHomeCountyHook
          >
        >
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    useGoSupportGetFieldtestersWithOutsideHomeCountyQueryOptions(
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGoSupportGetUnhandledNoTyresLoadedEventsHook = () => {
  const goSupportGetUnhandledNoTyresLoadedEvents =
    useGoSupportGetUnhandledNoTyresLoadedEventsMutator<CaseEventDTO[]>();

  return (
    params?: GoSupportGetUnhandledNoTyresLoadedEventsParams,
    signal?: AbortSignal
  ) => {
    return goSupportGetUnhandledNoTyresLoadedEvents({
      url: `/api/GoSupport/GetUnhandledNoTyresLoadedEvents`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getGoSupportGetUnhandledNoTyresLoadedEventsQueryKey = (
  params?: GoSupportGetUnhandledNoTyresLoadedEventsParams
) => {
  return [
    `/api/GoSupport/GetUnhandledNoTyresLoadedEvents`,
    ...(params ? [params] : []),
  ] as const;
};

export const useGoSupportGetUnhandledNoTyresLoadedEventsQueryOptions = <
  TData = Awaited<
    ReturnType<
      ReturnType<typeof useGoSupportGetUnhandledNoTyresLoadedEventsHook>
    >
  >,
  TError = unknown,
>(
  params?: GoSupportGetUnhandledNoTyresLoadedEventsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<typeof useGoSupportGetUnhandledNoTyresLoadedEventsHook>
        >
      >,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGoSupportGetUnhandledNoTyresLoadedEventsQueryKey(params);

  const goSupportGetUnhandledNoTyresLoadedEvents =
    useGoSupportGetUnhandledNoTyresLoadedEventsHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useGoSupportGetUnhandledNoTyresLoadedEventsHook>
      >
    >
  > = ({ signal }) => goSupportGetUnhandledNoTyresLoadedEvents(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useGoSupportGetUnhandledNoTyresLoadedEventsHook>
      >
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GoSupportGetUnhandledNoTyresLoadedEventsQueryResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<typeof useGoSupportGetUnhandledNoTyresLoadedEventsHook>
    >
  >
>;
export type GoSupportGetUnhandledNoTyresLoadedEventsQueryError = unknown;

export const useGoSupportGetUnhandledNoTyresLoadedEvents = <
  TData = Awaited<
    ReturnType<
      ReturnType<typeof useGoSupportGetUnhandledNoTyresLoadedEventsHook>
    >
  >,
  TError = unknown,
>(
  params?: GoSupportGetUnhandledNoTyresLoadedEventsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<typeof useGoSupportGetUnhandledNoTyresLoadedEventsHook>
        >
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGoSupportGetUnhandledNoTyresLoadedEventsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGoSupportGetUnhandledAssignmentRescheduledToAfterMidnightHook =
  () => {
    const goSupportGetUnhandledAssignmentRescheduledToAfterMidnight =
      useGoSupportGetUnhandledAssignmentRescheduledToAfterMidnightMutator<
        CaseEventDTO[]
      >();

    return (
      params?: GoSupportGetUnhandledAssignmentRescheduledToAfterMidnightParams,
      signal?: AbortSignal
    ) => {
      return goSupportGetUnhandledAssignmentRescheduledToAfterMidnight({
        url: `/api/GoSupport/GetUnhandledAssignmentRescheduledToAfterMidnight`,
        method: 'GET',
        params,
        signal,
      });
    };
  };

export const getGoSupportGetUnhandledAssignmentRescheduledToAfterMidnightQueryKey =
  (
    params?: GoSupportGetUnhandledAssignmentRescheduledToAfterMidnightParams
  ) => {
    return [
      `/api/GoSupport/GetUnhandledAssignmentRescheduledToAfterMidnight`,
      ...(params ? [params] : []),
    ] as const;
  };

export const useGoSupportGetUnhandledAssignmentRescheduledToAfterMidnightQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        ReturnType<
          typeof useGoSupportGetUnhandledAssignmentRescheduledToAfterMidnightHook
        >
      >
    >,
    TError = unknown,
  >(
    params?: GoSupportGetUnhandledAssignmentRescheduledToAfterMidnightParams,
    options?: {
      query?: UseQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<
              typeof useGoSupportGetUnhandledAssignmentRescheduledToAfterMidnightHook
            >
          >
        >,
        TError,
        TData
      >;
    }
  ) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGoSupportGetUnhandledAssignmentRescheduledToAfterMidnightQueryKey(
        params
      );

    const goSupportGetUnhandledAssignmentRescheduledToAfterMidnight =
      useGoSupportGetUnhandledAssignmentRescheduledToAfterMidnightHook();

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useGoSupportGetUnhandledAssignmentRescheduledToAfterMidnightHook
          >
        >
      >
    > = ({ signal }) =>
      goSupportGetUnhandledAssignmentRescheduledToAfterMidnight(params, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useGoSupportGetUnhandledAssignmentRescheduledToAfterMidnightHook
          >
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GoSupportGetUnhandledAssignmentRescheduledToAfterMidnightQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useGoSupportGetUnhandledAssignmentRescheduledToAfterMidnightHook
        >
      >
    >
  >;
export type GoSupportGetUnhandledAssignmentRescheduledToAfterMidnightQueryError =
  unknown;

export const useGoSupportGetUnhandledAssignmentRescheduledToAfterMidnight = <
  TData = Awaited<
    ReturnType<
      ReturnType<
        typeof useGoSupportGetUnhandledAssignmentRescheduledToAfterMidnightHook
      >
    >
  >,
  TError = unknown,
>(
  params?: GoSupportGetUnhandledAssignmentRescheduledToAfterMidnightParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useGoSupportGetUnhandledAssignmentRescheduledToAfterMidnightHook
          >
        >
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    useGoSupportGetUnhandledAssignmentRescheduledToAfterMidnightQueryOptions(
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
