import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AuthorizedButton from 'components/inputs/AuthorizedButton';
import Roles from 'constants/Roles';
import SendCasesToFieldTestersModal from 'pages/Cases/SendCasesToFieldTestersModal';
import { StyledAuthorizedButton } from 'pages/InternalDeliveryGroup/components/filterBars';
import React, { useState } from 'react';

type Props = {
  dateRange?: [Date | null, Date | null];
  compact?: boolean;
  disabled?: boolean;
  disabledMessage?: string;
};
const SendToFieldTestersButton: React.FC<Props> = ({
  dateRange,
  compact = true,
  disabled = false,
  disabledMessage = '',
}) => {
  const [showSendToFieldTestersModal, setShowSendToFieldTestersModal] =
    useState(false);

  return (
    <>
      {showSendToFieldTestersModal && (
        <SendCasesToFieldTestersModal
          dateRange={dateRange}
          onAssignmentsSent={() => {
            setShowSendToFieldTestersModal(false);
            //   update(false);
          }}
          onCancel={() => setShowSendToFieldTestersModal(false)}
        />
      )}
      {compact ? (
        <StyledAuthorizedButton
          onClick={() => setShowSendToFieldTestersModal(true)}
          roles={[Roles.Admin]}
          disabled={disabled}
          title={disabledMessage}
        >
          Skicka uppdrag till fälttestare{' '}
          <FontAwesomeIcon icon={faPaperPlane} />
        </StyledAuthorizedButton>
      ) : (
        <AuthorizedButton
          onClick={() => setShowSendToFieldTestersModal(true)}
          roles={[Roles.Admin]}
          disabled={disabled}
          title={disabledMessage}
        >
          Skicka uppdrag till fälttestare{' '}
          <FontAwesomeIcon icon={faPaperPlane} />
        </AuthorizedButton>
      )}
    </>
  );
};

export default SendToFieldTestersButton;
