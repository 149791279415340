/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */

export type DayIncompleteReasonEnum =
  (typeof DayIncompleteReasonEnum)[keyof typeof DayIncompleteReasonEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DayIncompleteReasonEnum = {
  StartingOutsideCounty: 1,
  EndingOutsideCounty: 2,
  MidDayDifferentCounty: 3,
} as const;
