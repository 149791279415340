import { Suspense, useCallback, useMemo } from 'react';

import {
  BrowserRouter,
  Navigate,
  Route,
  Routes as Routing,
} from 'react-router-dom';

import {
  createGlobalStyle,
  DefaultTheme,
  ThemeProvider,
} from 'styled-components';

import AppLayout from 'components/AppLayout';
import Login from 'pages/Login';
import { getThemeColors } from 'constants/Colors';
import Routes from 'constants/Routes';
import themeSizes from 'constants/Sizes';
import ApiCallConfiguration from 'contexts/ApiCallConfiguration';
import { AssignmentsFiltersProvider } from 'contexts/assignmentsFilters/useAssignmentsFilters';
import CasesPage from 'pages/Cases';
import { RecoilRoot } from 'recoil';
import { CaseTypeEnum } from 'api';
import RequireAuth from 'components/protectedRoute/RequireAuth';
import CaseModal from 'pages/Case';
import AppInitialization from 'components/AppInitialization';
import 'app.css';
import ExcelExportsPages from 'pages/ExcelExports';
import ConfirmAccountPage from 'pages/ConfirmAccount';
import ResetPasswordPage from 'pages/ResetPassword';
import Forgot from 'pages/Forgot';
import CompaniesPage from 'pages/Companies';
import GlobalSearchPage from 'pages/GlobalSearch';
import InstallAppPage from 'pages/InstallApp';
import ReconfirmEmailPage from 'pages/ReconfirmEmail';
import RideUpdatesPage from 'pages/CasesWithRideUpdates';
import TestMessageGeneratorPage from 'pages/Tests/TestMessageGenerator';
import PendingAssignmentsPage from 'pages/PendingAssignments';
import ExportHistory from 'pages/ExcelExports/ExportHistory';
import { GoogleMapsProvider } from 'components/map/GoogleMapsContext';
import MapPage from 'pages/Map';
import PrivacyPolicyPage from 'pages/Privacy/PrivacyPolicy';
import EmployeesPage from 'pages/Employees';
import EmployeeOverview from 'pages/Employees/EmployeeOverview';
import ZipCodeAreas from 'pages/Settings/ZipCodeAreas';
import SettingsPage from 'pages/Settings';
import AssignmentDurationsPage from 'pages/Settings/AssignmentDurations';
import BusinessUnits from 'pages/Settings/BusinessUnits';
import HolidaysPage from 'pages/Settings/Holidays';
import Availability from 'pages/Employees/Availability';
import BetaFeaturesPage from 'pages/Settings/BetaFeatures';
import StandardizedCommentsSettingsPage from 'pages/Settings/StandardizedComments';
import LoadingSpinner from 'components/spinners/LoadingSpinner';
import TeamsOverview from 'pages/Employees/Teams';
import CaseAssignmentRequests from 'pages/CaseAssignmentRequests';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import GlobalSettingsPage from 'pages/Settings/GlobalSettings';
import AbsenceRequests from 'pages/Employees/AbsenceRequests';
import { IdleProvider } from 'contexts/IdleContext/useIdle';
import DrivingPolicyOverview from 'pages/Employees/DrivingPolicy';
import TimeReports from 'pages/Employees/TimeReports';
import InternalDeliveryGroup from 'pages/InternalDeliveryGroup';
import InternalDeliveryAssignments from 'pages/InternalDeliveryGroup/Assignments';
import InternalDeliveryRequests from 'pages/InternalDeliveryGroup/Requests';
import InternalDeliveryOverview from 'pages/InternalDeliveryGroup/Overview';
import InternalDeliveryGroupSettings from 'pages/InternalDeliveryGroup/Budget';
import AssignmentRequestPage from 'pages/InternalDeliveryGroup/Assignments/components/AssignmentRequestPage';
import DrivingPolicyWarningsPage from 'pages/Employees/DrivingPolicyWarnings';
import useAxiosDateConverter from 'hooks/useAxiosDateConverter';
import { AssignmentRequestsFiltersProvider } from 'pages/InternalDeliveryGroup/Requests/components/useAssignmentRequestFilters';
import ExcelExports from 'pages/ExcelExports/ExcelExports';
import ReceiptsList from 'pages/ExcelExports/ReceiptsList';
import ProductExpenseTypeMappingsPage from 'pages/Settings/ProductExpenseTypeMappings';
import PaymentCards from 'pages/Employees/PaymentCards';
import { ApiExceptionResponse } from 'api/types';
import MobileAvail from 'pages/Employees/Availability/MobileAvail';
import { showException } from 'utils/exception-helper';
import CompanyDepartmentsSettingsPage from 'pages/Settings/CompanyDepartments';
import GoSupportPage from 'pages/GoSupport';
import TransportLogisticsPage from 'pages/TransportLogistics';
import TestAIPromptPage from 'pages/Tests/TestAIPrompt';

const GlobalStyle = createGlobalStyle`
  html, body {
    font-size: ${({ theme }) => theme.sizes.font.small};
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html, body, #root {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

const App: React.FC = () => {
  useAxiosDateConverter();
  const theme = useMemo(
    (): DefaultTheme => ({
      colors: getThemeColors(),
      sizes: themeSizes,
    }),
    []
  );

  const makeCasesRoutes = (
    caseType: CaseTypeEnum,
    casesPagePath: string,
    caseModalPath: string
  ) => (
    <Route
      element={
        <RequireAuth>
          <CasesPage casePathPattern={caseModalPath} caseType={caseType} />
        </RequireAuth>
      }
      path={casesPagePath}
    >
      <Route
        element={
          <RequireAuth>
            <CaseModal navigatesBackTo={casesPagePath} />
          </RequireAuth>
        }
        path={caseModalPath}
      />
    </Route>
  );

  const handleError = useCallback((error: any) => {
    try {
      const errorResponse = error.response?.data as ApiExceptionResponse;

      switch (errorResponse.status) {
        case 403: {
          showException(error);
          break;
        }
        case 401: {
          // toast.error(errorResponse.title, {
          //   duration: DEFAULT_TOAST_DURATION,
          // });
          // console.log('401 error', errorResponse.title);

          break;
        }
        case 409: {
          showException(error);
          break;
        }
        default: {
          showException(error);
          break;
        }
      }
    } catch (e) {
      // console.log('Error parsing error response', e, error);
    }
  }, []);

  const queryClient = new QueryClient({
    // defaultOptions: {
    //   queries: {
    //     refetchOnWindowFocus: false,
    //     retry: false,
    //   },
    // },
    queryCache: new QueryCache({
      onError: (error) => {
        handleError(error);
      },
    }),
    mutationCache: new MutationCache({
      onError: (error) => {
        handleError(error);
      },
    }),
  });

  return (
    <IdleProvider milliseconds={60000}>
      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <ThemeProvider theme={theme}>
            <ApiCallConfiguration>
              <GlobalStyle />
              <AppInitialization />
              <BrowserRouter>
                <AssignmentsFiltersProvider>
                  <AssignmentRequestsFiltersProvider>
                    <GoogleMapsProvider>
                      <Suspense fallback="Laddar!!!">
                        <Routing>
                          <Route element={<AppLayout />} path={Routes.index}>
                            {/* automatically navigate to Routes.cases.index if user lands on Routes.index */}
                            <Route
                              element={
                                <RequireAuth>
                                  <Navigate
                                    replace
                                    to={Routes.purchases.index}
                                  />
                                </RequireAuth>
                              }
                              path={Routes.index}
                            />

                            <Route
                              element={<Login />}
                              path={Routes.account.login}
                            />
                            <Route
                              element={<ConfirmAccountPage />}
                              path={Routes.account.confirm}
                            />
                            <Route
                              element={<ReconfirmEmailPage />}
                              path={Routes.account.reconfirm}
                            />
                            {makeCasesRoutes(
                              CaseTypeEnum.Purchase,
                              Routes.purchases.index,
                              Routes.purchases.case
                            )}
                            {makeCasesRoutes(
                              CaseTypeEnum.Extra,
                              Routes.extrajobs.index,
                              Routes.extrajobs.case
                            )}
                            {makeCasesRoutes(
                              CaseTypeEnum.HomeDelivery,
                              Routes.homeDeliveries.index,
                              Routes.homeDeliveries.case
                            )}
                            {makeCasesRoutes(
                              CaseTypeEnum.InternalDelivery,
                              Routes.internalDeliveries.index,
                              Routes.internalDeliveries.case
                            )}
                            {makeCasesRoutes(
                              CaseTypeEnum.FaciliyExchange,
                              Routes.facilityExchange.index,
                              Routes.facilityExchange.case
                            )}
                            {makeCasesRoutes(
                              CaseTypeEnum.InternalDeliverySales,
                              Routes.internalDeliverySales.index,
                              Routes.internalDeliverySales.case
                            )}
                            {makeCasesRoutes(
                              CaseTypeEnum.ExtraCostExternalWorkshopOut,
                              Routes.extraCostExternalWorkshopOut.index,
                              Routes.extraCostExternalWorkshopOut.case
                            )}
                            {makeCasesRoutes(
                              CaseTypeEnum.ExtraCostExternalWorkshopReturn,
                              Routes.extraCostExternalWorkshopReturn.index,
                              Routes.extraCostExternalWorkshopReturn.case
                            )}
                            {makeCasesRoutes(
                              CaseTypeEnum.FacilityToFacility,
                              Routes.facilityToFacility.index,
                              Routes.facilityToFacility.case
                            )}
                            {makeCasesRoutes(
                              CaseTypeEnum.RMOther,
                              Routes.rmOther.index,
                              Routes.rmOther.case
                            )}
                            {makeCasesRoutes(
                              CaseTypeEnum.Swamp,
                              Routes.swamp.index,
                              Routes.swamp.case
                            )}
                            {makeCasesRoutes(
                              CaseTypeEnum.Placeholder,
                              Routes.placeholder.index,
                              Routes.placeholder.case
                            )}

                            <Route
                              element={
                                <RequireAuth>
                                  <PendingAssignmentsPage />
                                </RequireAuth>
                              }
                              path={Routes.pendingAssignments.index}
                            >
                              <Route
                                element={
                                  <RequireAuth>
                                    <CaseModal
                                      navigatesBackTo={
                                        Routes.pendingAssignments.index
                                      }
                                    />
                                  </RequireAuth>
                                }
                                path={Routes.pendingAssignments.case}
                              />
                            </Route>

                            <Route
                              element={
                                <RequireAuth>
                                  <TransportLogisticsPage />
                                </RequireAuth>
                              }
                              path={Routes.transportLogistics.index}
                            />

                            <Route
                              element={
                                <RequireAuth>
                                  <GoSupportPage />
                                </RequireAuth>
                              }
                              path={Routes.goSupport.index}
                            />

                            <Route
                              element={
                                <RequireAuth>
                                  <RideUpdatesPage />
                                </RequireAuth>
                              }
                              path={Routes.rideUpdates.index}
                            >
                              <Route
                                element={
                                  <RequireAuth>
                                    <CaseModal
                                      navigatesBackTo={Routes.rideUpdates.index}
                                    />
                                  </RequireAuth>
                                }
                                path={Routes.rideUpdates.case}
                              />
                            </Route>

                            <Route
                              element={
                                <RequireAuth>
                                  <ExcelExportsPages />
                                </RequireAuth>
                              }
                              path={Routes.excelExports.index}
                            >
                              <Route
                                element={
                                  <RequireAuth>
                                    <ExcelExports />
                                  </RequireAuth>
                                }
                                path={Routes.excelExports.exports}
                              />
                              <Route
                                element={
                                  <RequireAuth>
                                    <ReceiptsList />
                                  </RequireAuth>
                                }
                                path={Routes.excelExports.receipts}
                              />
                            </Route>
                            <Route
                              element={
                                <RequireAuth>
                                  <ExportHistory />
                                </RequireAuth>
                              }
                              path={Routes.excelExports.history}
                            />

                            <Route
                              element={
                                <RequireAuth>
                                  <CompaniesPage />
                                </RequireAuth>
                              }
                              path={Routes.companies.index}
                            />
                            <Route
                              element={
                                <RequireAuth>
                                  <GlobalSearchPage
                                    casePathPattern={Routes.search.case}
                                  />
                                </RequireAuth>
                              }
                              path={Routes.search.index}
                            >
                              <Route
                                element={
                                  <RequireAuth>
                                    <CaseModal
                                      navigatesBackTo={Routes.search.index}
                                    />
                                  </RequireAuth>
                                }
                                path={Routes.search.case}
                              />
                            </Route>

                            <Route
                              element={
                                <RequireAuth>
                                  <MapPage />
                                </RequireAuth>
                              }
                              path={Routes.map.index}
                            />

                            <Route
                              element={
                                <RequireAuth>
                                  <EmployeesPage />
                                </RequireAuth>
                              }
                              path={Routes.employees.index}
                            >
                              <Route
                                element={
                                  <RequireAuth>
                                    <MobileAvail />
                                  </RequireAuth>
                                }
                                path={Routes.employees.mobile}
                              />
                              <Route
                                element={
                                  <RequireAuth>
                                    <EmployeeOverview />
                                  </RequireAuth>
                                }
                                path={Routes.employees.index}
                              />
                              <Route
                                element={
                                  <RequireAuth>
                                    <TimeReports />
                                  </RequireAuth>
                                }
                                path={Routes.employees.timeReports}
                              />
                              <Route
                                element={
                                  <RequireAuth>
                                    <TeamsOverview />
                                  </RequireAuth>
                                }
                                path={Routes.employees.teams}
                              />
                              <Route
                                element={
                                  <RequireAuth>
                                    <Availability />
                                  </RequireAuth>
                                }
                                path={Routes.employees.availability}
                              />
                              <Route
                                element={
                                  <RequireAuth>
                                    <AbsenceRequests />
                                  </RequireAuth>
                                }
                                path={Routes.employees.absenceRequests}
                              />
                              <Route
                                element={
                                  <RequireAuth>
                                    <DrivingPolicyWarningsPage />
                                  </RequireAuth>
                                }
                                path={Routes.employees.drivingPolicyWarnings}
                              />
                              <Route
                                element={
                                  <RequireAuth>
                                    <DrivingPolicyOverview />
                                  </RequireAuth>
                                }
                                path={Routes.employees.drivingPolicy}
                              />
                              <Route
                                element={
                                  <RequireAuth>
                                    <PaymentCards />
                                  </RequireAuth>
                                }
                                path={Routes.employees.paymentCards}
                              />
                            </Route>

                            <Route
                              element={<Forgot />}
                              path={Routes.forgot.index}
                            />
                            <Route
                              element={<ResetPasswordPage />}
                              path={Routes.resetpassword.index}
                            />

                            <Route
                              element={<TestMessageGeneratorPage />}
                              path={Routes.tests.testMessageGenerator}
                            />
                            <Route
                              element={<TestAIPromptPage />}
                              path={Routes.tests.testAIPrompt}
                            />

                            <Route
                              element={<InstallAppPage />}
                              path={Routes.appInstall.index}
                            />

                            <Route
                              element={<PrivacyPolicyPage />}
                              path={Routes.privacy.index}
                            />
                            <Route
                              element={
                                <RequireAuth>
                                  <SettingsPage />
                                </RequireAuth>
                              }
                              path={Routes.settings.index}
                            >
                              <Route
                                element={
                                  <RequireAuth>
                                    <AssignmentDurationsPage />
                                  </RequireAuth>
                                }
                                path={Routes.settings.assignmentDurations}
                              />
                              <Route
                                element={
                                  <RequireAuth>
                                    <ZipCodeAreas />
                                  </RequireAuth>
                                }
                                path={Routes.settings.zipCodeAreas}
                              />
                              <Route
                                element={
                                  <RequireAuth>
                                    <BusinessUnits />
                                  </RequireAuth>
                                }
                                path={Routes.settings.businessUnits}
                              />
                              <Route
                                element={
                                  <RequireAuth>
                                    <HolidaysPage />
                                  </RequireAuth>
                                }
                                path={Routes.settings.holidays}
                              />
                              <Route
                                element={
                                  <RequireAuth>
                                    <BetaFeaturesPage />
                                  </RequireAuth>
                                }
                                path={Routes.settings.beta}
                              />
                              <Route
                                element={
                                  <RequireAuth>
                                    <GlobalSettingsPage />
                                  </RequireAuth>
                                }
                                path={Routes.settings.globalSettings}
                              />
                              <Route
                                element={
                                  <RequireAuth>
                                    <ProductExpenseTypeMappingsPage />
                                  </RequireAuth>
                                }
                                path={
                                  Routes.settings.productExpenseTypeMappings
                                }
                              />
                              <Route
                                element={
                                  <RequireAuth>
                                    <HolidaysPage />
                                  </RequireAuth>
                                }
                                path={Routes.settings.index}
                              />
                            </Route>
                            <Route
                              element={<PrivacyPolicyPage />}
                              path={Routes.privacy.index}
                            />
                            <Route
                              element={
                                <RequireAuth>
                                  <SettingsPage />
                                </RequireAuth>
                              }
                              path={Routes.settings.index}
                            >
                              <Route
                                element={
                                  <RequireAuth>
                                    <AssignmentDurationsPage />
                                  </RequireAuth>
                                }
                                path={Routes.settings.assignmentDurations}
                              />
                              <Route
                                element={
                                  <RequireAuth>
                                    <ZipCodeAreas />
                                  </RequireAuth>
                                }
                                path={Routes.settings.zipCodeAreas}
                              />
                              <Route
                                element={
                                  <RequireAuth>
                                    <BusinessUnits />
                                  </RequireAuth>
                                }
                                path={Routes.settings.businessUnits}
                              />
                              <Route
                                element={
                                  <RequireAuth>
                                    <HolidaysPage />
                                  </RequireAuth>
                                }
                                path={Routes.settings.holidays}
                              />
                              <Route
                                element={
                                  <RequireAuth>
                                    <BetaFeaturesPage />
                                  </RequireAuth>
                                }
                                path={Routes.settings.beta}
                              />
                              <Route
                                element={
                                  <RequireAuth>
                                    <HolidaysPage />
                                  </RequireAuth>
                                }
                                path={Routes.settings.index}
                              />
                              <Route
                                element={
                                  <RequireAuth>
                                    <Suspense fallback={<LoadingSpinner />}>
                                      <StandardizedCommentsSettingsPage />
                                    </Suspense>
                                  </RequireAuth>
                                }
                                path={Routes.settings.standardizedComments}
                              />
                              <Route
                                element={
                                  <RequireAuth>
                                    <Suspense fallback={<LoadingSpinner />}>
                                      <CompanyDepartmentsSettingsPage />
                                    </Suspense>
                                  </RequireAuth>
                                }
                                path={Routes.settings.companyDepartments}
                              />
                            </Route>

                            <Route
                              element={
                                <RequireAuth>
                                  <CaseAssignmentRequests />
                                </RequireAuth>
                              }
                              path={Routes.caseAssignmentRequests.index}
                            />

                            <Route
                              element={
                                <RequireAuth>
                                  <InternalDeliveryGroup />
                                </RequireAuth>
                              }
                              // Annons-v2 Uppdrags-översikt
                              path={Routes.internalDeliveryGroup.index}
                            >
                              <Route
                                element={
                                  <RequireAuth>
                                    <InternalDeliveryAssignments />
                                  </RequireAuth>
                                }
                                path={Routes.internalDeliveryGroup.index}
                              />
                              {makeCasesRoutes(
                                CaseTypeEnum.Extra,
                                Routes.internalDeliveryGroup.index,
                                Routes.internalDeliveryGroup.assignments.case
                              )}
                              {makeCasesRoutes(
                                CaseTypeEnum.FaciliyExchange,
                                Routes.internalDeliveryGroup.index,
                                Routes.internalDeliveryGroup.assignments.case
                              )}
                              <Route
                                element={
                                  <RequireAuth>
                                    <InternalDeliveryAssignments />
                                  </RequireAuth>
                                }
                                path={Routes.internalDeliveryGroup.index}
                              />

                              <Route
                                element={
                                  <RequireAuth>
                                    <InternalDeliveryRequests />
                                  </RequireAuth>
                                }
                                path={
                                  Routes.internalDeliveryGroup.requests.index
                                }
                              >
                                <Route
                                  element={
                                    <RequireAuth>
                                      <AssignmentRequestPage
                                        navigatesBackTo={
                                          Routes.internalDeliveryGroup.requests
                                            .index
                                        }
                                      />
                                    </RequireAuth>
                                  }
                                  path={
                                    Routes.internalDeliveryGroup.requests
                                      .request
                                  }
                                />
                              </Route>

                              <Route
                                element={
                                  <RequireAuth>
                                    <InternalDeliveryOverview />
                                  </RequireAuth>
                                }
                                path={Routes.internalDeliveryGroup.overview}
                              />
                              <Route
                                element={
                                  <RequireAuth>
                                    <InternalDeliveryGroupSettings />
                                  </RequireAuth>
                                }
                                path={Routes.internalDeliveryGroup.budget}
                              />
                            </Route>

                            <Route
                              element={<h1>Sidan hittades inte</h1>}
                              path="*"
                            />
                          </Route>
                        </Routing>
                      </Suspense>
                    </GoogleMapsProvider>
                  </AssignmentRequestsFiltersProvider>
                </AssignmentsFiltersProvider>
              </BrowserRouter>
            </ApiCallConfiguration>
          </ThemeProvider>
        </RecoilRoot>
      </QueryClientProvider>
    </IdleProvider>
  );
};

export default App;
