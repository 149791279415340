import {
  faBell,
  faComments,
  faExclamationTriangle,
  faFileText,
  faList,
  faMobileAlt,
  faRobot,
  faSquarePen,
  faTaxi,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CaseEventType, EventModel, ITranslationLookups } from 'api';
import { FC } from 'react';
import styled, { useTheme } from 'styled-components';
import Comment from './eventComponents/Comment';
import CommentEditor from './eventComponents/CommentEditor';
import { EventSection } from './eventComponents/components';
import DefaultEventHeader from './eventComponents/DefaultEventHeader';
import TitledEvent from './eventComponents/TitledEvent';
import CaseAssignmentRequest from './eventComponents/CaseAssignmentRequest';

const HeaderTextSpan = styled.span`
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 5px;
`;

const DivWithBreaks = styled.div`
  white-space: pre-wrap;
`;

interface Props {
  event: EventModel;
  translations: ITranslationLookups;
  editMode?: boolean;
  onEditClick?(): void;
  onDeleteClick?(): void;
  onCancelSave?(): void;
  onSave?(
    caseId: number,
    message: string,
    eventId?: number,
    internal?: boolean,
    readByAdmin?: boolean
  ): void;
  onUpdate?(): void;
}

const Event: FC<Props> = ({
  event,
  translations,
  editMode,
  onEditClick,
  onDeleteClick,
  onCancelSave,
  onSave,
  onUpdate,
}) => {
  const theme = useTheme();
  const { caseEventTypes } = translations;

  switch (event.caseEventType) {
    case CaseEventType.TaxiRequest:
    case CaseEventType.BringVehicleHomeRequest:
    case CaseEventType.RequestAssignFieldTesterOutsideDrivingPolicy:
      return <CaseAssignmentRequest event={event} onUpdate={onUpdate} />;
    case CaseEventType.Comment:
    case CaseEventType.InternalComment:
      return editMode ? (
        <CommentEditor event={event} onCancel={onCancelSave} onSave={onSave} />
      ) : (
        <Comment
          event={event}
          onDeleteClick={onDeleteClick}
          onEditClick={onEditClick}
        />
      );

    case CaseEventType.CustomerStatusSMSSuccess:
      return (
        <TitledEvent
          event={event}
          icon={<FontAwesomeIcon icon={faMobileAlt} />}
          title="SMS-uppdatering utskickat"
        />
      );

    case CaseEventType.CustomerStatusSMSFail:
      return (
        <TitledEvent
          event={event}
          icon={<FontAwesomeIcon icon={faExclamationTriangle} />}
          title="SMS-uppdatering FEL"
        />
      );

    case CaseEventType.RideComment:
      return (
        <TitledEvent
          event={event}
          icon={<FontAwesomeIcon icon={faComments} />}
          title="Ride-kommentar"
        />
      );

    case CaseEventType.RideChange:
      return (
        <EventSection>
          <DefaultEventHeader
            position={event.position}
            created={event.created}
            headerText={
              <>
                <FontAwesomeIcon icon={faSquarePen} />{' '}
                {event.message || caseEventTypes[event.caseEventType]}
              </>
            }
            modified={event.modified}
            userName={event.modifiedBy}
          />
        </EventSection>
      );
    case CaseEventType.CarKeyAndDrivingLicensePhoto:
    case CaseEventType.CarKeyPhoto:
    case CaseEventType.CarParkPhoto:
    case CaseEventType.WorkGearPhoto:
      return (
        <EventSection>
          <DefaultEventHeader
            position={event.position}
            attachmentFileEnding={event.attachmentFileEnding}
            attachmentFileGuid={event.attachmentFileGuid}
            created={event.created}
            headerText={
              <span>
                {event.message || caseEventTypes[event.caseEventType]}
              </span>
            }
            modified={event.modified}
            userName={event.modifiedBy}
          />
        </EventSection>
      );
    case CaseEventType.FlaggedForSpeeding:
      return (
        <EventSection>
          <DefaultEventHeader
            created={event.created}
            headerText={
              <HeaderTextSpan>
                <FontAwesomeIcon
                  color={theme.colors.foreground.error}
                  icon={faTaxi}
                />
                <span>
                  {event.message || caseEventTypes[event.caseEventType]}
                </span>
              </HeaderTextSpan>
            }
            modified={event.modified}
            userName={event.modifiedBy}
          />
        </EventSection>
      );
    case CaseEventType.DeviationReport:
      return (
        <EventSection>
          <DefaultEventHeader
            position={event.position}
            created={event.created}
            headerText={
              <HeaderTextSpan>
                <FontAwesomeIcon
                  color={
                    event.readByAdmin
                      ? theme.colors.foreground.tint
                      : theme.colors.foreground.error
                  }
                  icon={faFileText}
                />

                <span>{caseEventTypes[event.caseEventType]}</span>
              </HeaderTextSpan>
            }
            modified={event.modified}
            userName={event.modifiedBy}
          />
          {event.message}
        </EventSection>
      );
    case CaseEventType.AutoApprove:
      return (
        <EventSection>
          <DefaultEventHeader
            created={event.created}
            headerText={
              <HeaderTextSpan>
                <FontAwesomeIcon icon={faRobot} />
                <span>{caseEventTypes[event.caseEventType]}</span>
              </HeaderTextSpan>
            }
            modified={event.modified}
            userName={event.modifiedBy}
          />
          {event.message}
        </EventSection>
      );
    case CaseEventType.WorkGearReport:
      return (
        <EventSection>
          <DefaultEventHeader
            position={event.position}
            created={event.created}
            headerText={
              <HeaderTextSpan>
                <FontAwesomeIcon icon={faList} />{' '}
                {caseEventTypes[event.caseEventType]}
                {!event.readByAdmin && (
                  <span>
                    <FontAwesomeIcon
                      color={theme.colors.foreground.newMessage}
                      icon={faBell}
                    />
                  </span>
                )}
              </HeaderTextSpan>
            }
            modified={event.modified}
            userName={event.modifiedBy}
          />
          <DivWithBreaks>{event.message}</DivWithBreaks>
        </EventSection>
      );

    case CaseEventType.AssignmentVehicleFaultDiscovered:
      return (
        <EventSection>
          <DefaultEventHeader
            position={event.position}
            created={event.created}
            headerText={
              <HeaderTextSpan>
                <FontAwesomeIcon icon={faExclamationTriangle} />
                <span>{caseEventTypes[event.caseEventType]}</span>
              </HeaderTextSpan>
            }
            modified={event.modified}
            userName={event.modifiedBy}
          />
          <DivWithBreaks>{event.message}</DivWithBreaks>
        </EventSection>
      );

    default:
      return (
        <EventSection key={event.id}>
          <DefaultEventHeader
            position={event.position}
            created={event.created}
            headerText={
              (caseEventTypes[event.caseEventType] || event.message) ?? '-'
            }
            httpTitle={
              event.assignmentID ? `Uppdrag ${event.assignmentID}` : ''
            }
            modified={event.modified}
            userName={event.modifiedBy}
          />
          <DivWithBreaks>{event.message}</DivWithBreaks>
        </EventSection>
      );
  }
};

export default Event;
