import useAuthenticationState from 'contexts/authentication/useAuthentication';
// import { useCompanyInitialization } from 'contexts/basicData/useCompanies';
import { useSpeedLimitAlertsInitialization } from 'contexts/basicData/useSpeedLimitAlerts';
// import { useTranslationInitialization } from 'contexts/basicData/useTranslations';
import useFrontendVersionPoll from 'hooks/useFrontendVersionCheck';
import { useRideUpdatesCountPoll } from 'pages/CasesWithRideUpdates/useRideUpdates';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import TransparentSpinner from './spinners/TransparentSpinner';
// import { useBusinessUnitsInitialization } from 'contexts/basicData/useBusinessUnits';
import { useUnhandledAvailabilityRequestsCountPoll } from 'pages/Employees/useUnhandledCount';
import {
  businessUnitsAtom,
  useRefreshBusinessUnitsCall,
} from 'contexts/basicData/useBusinessUnits';
import { useRefreshUsersCall, usersAtom } from 'contexts/basicData/useUsers';
import {
  companiesAtom,
  useRefreshCompaniesCall,
} from 'contexts/basicData/useCompanies';
import { useRecoilState } from 'recoil';
// import { useRefreshUsersCall } from 'contexts/basicData/useUsers';
// import { useTeamGroupsInitialization } from 'contexts/basicData/useTeamGroups';
// import { useTeamsInitialization } from 'contexts/basicData/useTeams';
// import { useUserInitialization } from 'contexts/basicData/useUsers';

const Overlay = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  background-color: ${({ theme }) => theme.colors.background.opacityOverlay};
`;

/** Show loading indicator while initializing/fetching all required data for the app to function */
const AppInitialization: FC = () => {
  const [, setUsers] = useRecoilState(usersAtom);
  const [, setBusinessUnits] = useRecoilState(businessUnitsAtom);
  const [, setCompanies] = useRecoilState(companiesAtom);

  // const translationsFetching = useTranslationInitialization();
  const speedLimitsFetching = useSpeedLimitAlertsInitialization();
  // const usersFetching = useUserInitialization();
  // useCompanyInitialization();
  // useBusinessUnitsInitialization();
  useFrontendVersionPoll();
  useRideUpdatesCountPoll();
  useUnhandledAvailabilityRequestsCountPoll();
  // useTeamGroupsInitialization();
  // useTeamsInitialization();

  const {
    state: { isAuthenticated },
  } = useAuthenticationState();

  const refreshUsers = useRefreshUsersCall();
  const refreshBusinessUnits = useRefreshBusinessUnitsCall();
  const refreshCompanies = useRefreshCompaniesCall();

  useEffect(() => {
    if (isAuthenticated) {
      setTimeout(() => {
        refreshUsers.run();
        refreshBusinessUnits.run();
        refreshCompanies.run();
      }, 0);
    } else {
      setUsers({});
      setBusinessUnits({
        units: [],
        unitsById: new Map(),
      });
      setCompanies({
        companies: [],
        allCompanies: [],
        companiesById: new Map(),
        activeCompaniesByAddrDict: new Map(),
        companiesByAddrDict: new Map(),
        getCompanyAddressDictKey: () => '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  // if (translationsFetching || usersFetching || speedLimitsFetching) {
  //   return (
  //     <Overlay>
  //       <TransparentSpinner />
  //     </Overlay>
  //   );
  // }
  if (speedLimitsFetching) {
    return (
      <Overlay>
        <TransparentSpinner />
      </Overlay>
    );
  }

  return null;
};

// Dirty hack to remount AppInitialization when user authenticates.
const DirtyHack = () => {
  const {
    state: { isAuthenticated },
  } = useAuthenticationState();

  const [showApp, setShowApp] = useState(false);
  useEffect(() => {
    if (isAuthenticated) {
      // slight delay in order to make sure that the auth state is not stale
      setTimeout(() => {
        setShowApp(true);
      }, 1500);
    }
  }, [isAuthenticated]);
  return showApp ? <AppInitialization /> : null;
};

export default DirtyHack;
