import { useEffect, useMemo, useState } from 'react';
import Widget from '../../../components/Dashboard/Widget';
import Table from 'components/Table';
import { ColumnSetting } from 'components/Table/utils';
import useUsers from 'contexts/basicData/useUsers';
import { formatDate } from 'utils/date-helpers';
import { useDashboardControls } from 'contexts/Dashboard/useDashboardControls';
import { GoSupportWidgetsEnum, LongTitles, UPDATE_INTERVAL } from '../utils';
import {
  //   getGoSupportGetFieldtestersWithOutsideHomeCountyQueryKey,
  useGoSupportGetFieldtestersWithOutsideHomeCounty,
} from 'api/go-support/go-support';
import {
  Centered,
  getTableRowClassName,
  //   SavingRowAnimationWrapper,
} from '../table-helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay, faWarning } from '@fortawesome/free-solid-svg-icons';
import useModalStack from 'contexts/modal/useModalStack';
import AvailabilityFormModal from 'pages/Employees/Availability/AvailabilityFormModal';
import styled from 'styled-components';
import TextButton from 'components/inputs/TextButton';
import useTranslations from 'contexts/basicData/useTranslations';
import { FieldTesterIncompleteDayPlanDTO } from 'api/model/fieldTesterIncompleteDayPlanDTO';
import { DayIncompleteReasonEnum } from 'api/model';
// import { useQueryClient } from '@tanstack/react-query';
// import { useCaseMarkEventsAsReadByAdmin } from 'api/case/case';
// import Checkbox from 'components/inputs/Checkbox';

const MyTextButton = styled(TextButton)`
  padding: 0px;
`;

const TABLE_NAME = 'incomplete-day-plan';

const IncompleteDayPlanWidget = () => {
  const [fieldTestersOutsideHomeCounty, setFieldTestersOutsideHomeCounty] =
    useState<FieldTesterIncompleteDayPlanDTO[]>([]);
  //   const [ongoingSaves, setOngoingSaves] = useState<number[]>([]);
  const users = useUsers();
  const {
    dashboardState: { daterange, selectedBusinessUnits, searchText },
  } = useDashboardControls();

  const modalStack = useModalStack();
  const translations = useTranslations();

  //   const queryClient = useQueryClient();
  //   const { mutateAsync: markEventAsRead, status: markAsReadStatus } =
  //     useCaseMarkEventsAsReadByAdmin({
  //       mutation: {
  //         onSuccess: () => {
  //           queryClient.invalidateQueries([
  //             `${getGoSupportGetFieldtestersWithOutsideHomeCountyQueryKey()}`,
  //           ]);
  //         },
  //       },
  //     });

  const getAssignments = useGoSupportGetFieldtestersWithOutsideHomeCounty(
    {
      startDate: daterange[0] ?? undefined,
      endDate: daterange[1] ?? undefined,
      businessUnitIDs: selectedBusinessUnits,
    },
    {
      query: {
        refetchInterval: UPDATE_INTERVAL,
      },
    }
  );

  useEffect(() => {
    if (getAssignments.data) {
      setFieldTestersOutsideHomeCounty(getAssignments.data);
    } else {
      setFieldTestersOutsideHomeCounty([] as FieldTesterIncompleteDayPlanDTO[]);
    }
  }, [getAssignments.data]);

  const filteredAssignments = useMemo(() => {
    if (!searchText.length) {
      return fieldTestersOutsideHomeCounty;
    }
    return fieldTestersOutsideHomeCounty
      ? fieldTestersOutsideHomeCounty.filter((fieldtester) => {
          return (users[fieldtester.userId]?.name ?? '')
            .toLowerCase()
            .includes(searchText.toLowerCase());
        })
      : [];
  }, [fieldTestersOutsideHomeCounty, searchText, users]);

  const columnSettings: ColumnSetting<FieldTesterIncompleteDayPlanDTO, any>[] =
    useMemo(() => {
      const columns: ColumnSetting<FieldTesterIncompleteDayPlanDTO, any>[] = [
        {
          head: 'Nr',
          render: (e, t, f, i) => i + 1,
          width: 15,
        },
        {
          head: 'Datum',
          render: (row) => `${formatDate(row.date)}`,
          width: 100,
          sortFunction: (a, b) => a.date.getTime() - b.date.getTime(),
        },
        {
          head: 'Fälttestare',
          render: (row) => {
            return (
              <span>{users[row.userId] ? users[row.userId].name : ''}</span>
            );
          },
          width: 100,
          sortFunction: (a, b) =>
            (users[a.userId]?.name ?? '').localeCompare(
              users[b.userId]?.name ?? ''
            ),
        },
        {
          head: 'Telefonnummer',
          render: (row) => {
            return (
              <span>
                {users[row.userId] ? users[row.userId].phoneNumber : ''}
              </span>
            );
          },
          width: 100,
          sortFunction: (a, b) =>
            (users[a.userId]?.phoneNumber ?? '').localeCompare(
              users[b.userId]?.phoneNumber ?? ''
            ),
        },
        {
          head: 'För långa avstånd',
          render: (row) => {
            return (
              <span>
                {row.dayIncompleteReasons
                  .map(
                    (reason) => translations.fieldtestersDayIncomplete[reason]
                  )
                  .join(' ')}
              </span>
            );
          },
          width: 200,
          sortFunction: (a, b) =>
            a.dayIncompleteReasons
              .join(', ')
              .localeCompare(b.dayIncompleteReasons.join(' ')),
        },
        {
          head: ' ',
          render: (row) =>
            row.dayIncompleteReasons.includes(
              DayIncompleteReasonEnum.EndingOutsideCounty
            ) && users[row.userId]?.canStayAtHotels === false ? (
              <Centered>
                <FontAwesomeIcon
                  title="OBS! Fälttestaren kan ej bo på hotell!"
                  icon={faWarning}
                  fontSize="18px"
                  color="red"
                />
              </Centered>
            ) : (
              ''
            ),
          width: 20,
        },
        {
          head: ' ',
          render: (row) => (
            <Centered>
              <MyTextButton>
                <FontAwesomeIcon
                  onClick={() => {
                    modalStack.push(
                      <AvailabilityFormModal
                        userId={row.userId}
                        initialDate={row.date}
                        name={users[row.userId]?.name ?? ''}
                        onClose={() => modalStack.pop()}
                        onSaved={() => modalStack.pop()}
                        viewOnly
                        showUserFields
                      />
                    );
                  }}
                  icon={faCalendarDay}
                  fontSize="18px"
                  style={{ cursor: 'pointer' }}
                />
              </MyTextButton>
            </Centered>
          ),
          width: 20,
        },
        //   {
        //     head: 'Klart',
        //     render: (row) => (
        //       <Centered>
        //         <Checkbox
        //           disabled={markAsReadStatus === 'loading'}
        //           onChange={async () => {
        //             setOngoingSaves([...ongoingSaves, row.id]);
        //             await markEventAsRead({ data: [row.id] });
        //             setOngoingSaves(ongoingSaves.filter((id) => id !== row.id));
        //           }}
        //         />
        //       </Centered>
        //     ),
        //     width: 30,
        //   },
      ];
      return columns;
    }, [modalStack, translations.fieldtestersDayIncomplete, users]);
  return (
    <Widget
      title={LongTitles[GoSupportWidgetsEnum.IncompleteDayPlan]}
      count={fieldTestersOutsideHomeCounty.length}
      isLoading={getAssignments.isLoading}
      info="Fälttestarens dag har luckor i planeringen, där uppdragen antingen startar/slutar för långt från hemmet eller har uppdrag som startar/slutar för långt ifrån varandra."
    >
      {/* <SavingRowAnimationWrapper
        tableName={TABLE_NAME}
        savingIds={ongoingSaves}
      > */}
      <Table
        columnSettings={columnSettings}
        rows={filteredAssignments}
        rowClassName={(row) => getTableRowClassName(TABLE_NAME, row.userId)}
        useColumnWidthAsFlexAmount
      />
      {/* </SavingRowAnimationWrapper> */}
    </Widget>
  );
};

export default IncompleteDayPlanWidget;
