/* eslint-disable @typescript-eslint/no-unused-vars */
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAssignmentReplacePlaceholderAssignment } from 'api/assignment/assignment';
import { AssignmentViewModel } from 'api/model';
import Dropdown from 'components/Dropdown';
import TextButton from 'components/inputs/TextButton';
import ConfirmModal from 'components/Modal/ConfirmModal';
import useModalStack from 'contexts/modal/useModalStack';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  formatDateTime,
  getHoursAndMinutesFromMillisecondsString,
} from 'utils/date-helpers';
import { showException } from 'utils/exception-helper';

const StyledTextButton = styled(TextButton)`
  margin-left: 0;
  width: 10px;
`;

const AssignmentActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 250px;
  min-width: 150px;
  gap: 5px;
  padding: 5px;
  text-align: left;
  word-wrap: break-word;
  white-space: normal;
  text-align: left;
`;

type Props = {
  currentAssignment: AssignmentViewModel;
  selectedAssignment: AssignmentViewModel;
  onUpdated: () => void;
};

const ReplacePlaceholderAssignmentActionDropdown: React.FC<Props> = ({
  currentAssignment,
  selectedAssignment,
  onUpdated,
}) => {
  const navigate = useNavigate();
  const modalStack = useModalStack();

  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const replacePlaceholderAssignmentCall =
    useAssignmentReplacePlaceholderAssignment();

  const estimatedEndTimeDiffInMillisFromCurrentAssignmentEndTime =
    useMemo(() => {
      const currentAssignmentEstimatedStartTime =
        currentAssignment.assignment.estimatedStartTime;
      const currentAssignmentEstimatedDuration =
        currentAssignment.assignment.estimatedDuration;
      const selectedAssignmentEstimatedStartTime =
        selectedAssignment.assignment.estimatedStartTime;
      const selectedAssignmentEstimatedDuration =
        selectedAssignment.assignment.estimatedDuration;
      if (
        !currentAssignmentEstimatedStartTime ||
        !currentAssignmentEstimatedDuration ||
        !selectedAssignmentEstimatedStartTime ||
        !selectedAssignmentEstimatedDuration
      ) {
        return undefined;
      }
      const currentAssignmentEstimatedEndTime = new Date(
        currentAssignmentEstimatedStartTime.getTime() +
          currentAssignmentEstimatedDuration
      );
      const selectedAssignmentEstimatedEndTime = new Date(
        selectedAssignmentEstimatedStartTime.getTime() +
          selectedAssignmentEstimatedDuration
      );

      return (
        selectedAssignmentEstimatedEndTime.getTime() -
        currentAssignmentEstimatedEndTime.getTime()
      );
    }, [
      currentAssignment.assignment.estimatedDuration,
      currentAssignment.assignment.estimatedStartTime,
      selectedAssignment.assignment.estimatedDuration,
      selectedAssignment.assignment.estimatedStartTime,
    ]);

  const diffInEstimatedDuration = useMemo(() => {
    if (
      !currentAssignment.assignment.estimatedDuration ||
      !selectedAssignment.assignment.estimatedDuration
    ) {
      return undefined;
    }
    return (
      selectedAssignment.assignment.estimatedDuration -
      currentAssignment.assignment.estimatedDuration
    );
  }, [
    currentAssignment.assignment.estimatedDuration,
    selectedAssignment.assignment.estimatedDuration,
  ]);

  const newRequiredStartTimeToMeetEstimatedEndTime = useMemo(() => {
    const selectedAssignmentEstimatedStartTime =
      selectedAssignment.assignment.estimatedStartTime;
    if (
      !selectedAssignmentEstimatedStartTime ||
      !estimatedEndTimeDiffInMillisFromCurrentAssignmentEndTime
    ) {
      return undefined;
    }

    return new Date(
      selectedAssignmentEstimatedStartTime.getTime() -
        estimatedEndTimeDiffInMillisFromCurrentAssignmentEndTime
    );
  }, [
    estimatedEndTimeDiffInMillisFromCurrentAssignmentEndTime,
    selectedAssignment.assignment.estimatedStartTime,
  ]);

  const replacePlaceholderAssignment = async () => {
    try {
      await replacePlaceholderAssignmentCall.mutateAsync({
        params: {
          placeholderAssignmentId: currentAssignment.assignment.assignmentID,
          newAssignmentId: selectedAssignment.assignment.assignmentID,
          newEstimatedStartTime: newRequiredStartTimeToMeetEstimatedEndTime,
        },
      });
      // redirect to case
      navigate(`/sok/${selectedAssignment.case.caseID}`);

      // onUpdated();
      // modalStack.pop();
      // setDropdownOpen(false);
    } catch (error) {
      showException(error);
    }
  };

  const handleLostFocus = () => {
    if (dropdownOpen) {
      setDropdownOpen(false);
    }
  };

  const handleAssignUser = () => {
    modalStack.push(
      <ConfirmModal
        title="Bekräfta tilldelning"
        content={
          <div>
            <p>
              {`Är du säker på att du vill tilldela ${selectedAssignment.case.registrationNumber} till ${currentAssignment.assignment.assignedTo?.name}?`}
            </p>
            <p>
              Utfyllnadsuppdraget kommer att tas bort och ersättas med det nya
              uppdraget.
            </p>
            {estimatedEndTimeDiffInMillisFromCurrentAssignmentEndTime &&
              newRequiredStartTimeToMeetEstimatedEndTime && (
                <p>
                  <strong>
                    {`Estimerad starttid för det nya uppdraget kommer att justeras med ${getHoursAndMinutesFromMillisecondsString(
                      estimatedEndTimeDiffInMillisFromCurrentAssignmentEndTime
                    )} från ${
                      selectedAssignment.assignment.estimatedStartTime
                        ? formatDateTime(
                            selectedAssignment.assignment.estimatedStartTime
                          )
                        : '-'
                    } till ${formatDateTime(newRequiredStartTimeToMeetEstimatedEndTime)}.`}
                  </strong>
                </p>
              )}
          </div>
        }
        onConfirm={async () => {
          await replacePlaceholderAssignment();
          modalStack.pop();
          onUpdated();
        }}
        onCancel={() => {
          modalStack.pop();
          setDropdownOpen(false);
        }}
      />
    );
  };

  return (
    <Dropdown
      content={
        dropdownOpen && (
          <AssignmentActionsWrapper>
            <StyledTextButton onClick={handleAssignUser}>
              Välj bil
            </StyledTextButton>
            {diffInEstimatedDuration === undefined && (
              <span>
                * Estimerad uppdragstid för det nya uppdraget är okänd.
              </span>
            )}
            {diffInEstimatedDuration !== undefined &&
              diffInEstimatedDuration > 0 && (
                <span>
                  * Estimerad uppdragstid för det nya uppdraget är{' '}
                  {getHoursAndMinutesFromMillisecondsString(
                    Math.abs(diffInEstimatedDuration)
                  )}{' '}
                  längre än det nuvarande uppdraget.
                </span>
              )}
          </AssignmentActionsWrapper>
        )
      }
      onClick={() => {
        setDropdownOpen(true);
      }}
      onLostFocus={handleLostFocus}
      position="left"
    >
      <TextButton
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setDropdownOpen(!dropdownOpen);
        }}
      >
        <FontAwesomeIcon icon={faEllipsisV} />
      </TextButton>
    </Dropdown>
  );
};

export default ReplacePlaceholderAssignmentActionDropdown;
