import {
  faCircleDot,
  faClock,
  faClockFour,
  faComment,
  faLink,
  faLock,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ColumnSetting,
  tableCellClassName,
  TableColumnEnum,
  tableHeadClassName,
} from 'components/Table/utils';
import Roles from 'constants/Roles';
import Routes from 'constants/Routes';
import useMe from 'contexts/authentication/useMe';
import { useCallback, useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import {
  formatDateTime,
  formatTime,
  getHoursAndMinutesFromMillisecondsString,
} from 'utils/date-helpers';
import {
  AssignmentStatusEnum,
  AssignmentTypeEnum,
  CaseStatusEnum,
  CaseTypeEnum,
  CompanyModel,
  IAssignmentViewModel,
  ITranslationLookups,
} from 'api';
import {
  DateTimePickerCell,
  EstimatedDurationCell,
  ExtraInfoCell,
  StandardizedCommentsCell,
  StatusPickerCell,
  TimePickerCell,
  // UserPickerCell,
} from './EditableCells';
import { AssignmentEditOperation } from './useQueuedAssignmentUpdates';
import Cars from 'components/icons/Cars';
import { booleanCompare, makeStringComparator } from 'utils/sorting';
import useCompanies from 'contexts/basicData/useCompanies';
import useTranslations from 'contexts/basicData/useTranslations';
import TooltipInfo from 'components/TooltipInfo';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import useAssignmentSearch from 'contexts/assignmentSearch/useAssignmentSearch';
import FakeCheckbox from 'components/inputs/FakeCheckbox';
import CombinedUserAndTimeEstimationPickerCell from './EditableCells/CombinedUserAndEstimationPickerCell';
import { AssignmentViewModel } from 'api/model';
import moment from 'moment';
import assignmentWarnings, { WarningInfo } from 'utils/assignment-warnings';
import TooltipInfoWarning from 'components/TooltipInfoWarning';
import ExpandableWarningsWidget from './ExpandableWarningsWidget';

const CaseStatusIcons = {
  [CaseStatusEnum.Open]: faCircle,
  [CaseStatusEnum.Closed]: faLock,
  [CaseStatusEnum.Pending]: faClock,
};

const DotSpan = styled.span<{ dotColor?: string }>`
  height: 12px;
  width: 12px;
  background-color: ${({ dotColor }) =>
    dotColor ? `#${dotColor}` : 'transparent'};
  border-radius: 50%;
  display: inline-block;
  margin-right: 3px;
`;

const CaseStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: ${({ theme }) => theme.colors.foreground.primary};
`;

const CaseStatusCell = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: normal;
`;

const renderedCheckboxClassName = 'fake-checkbox';
const CheckboxCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 3px;
  width: 100%;
  height: 100%;

  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};

  &:hover .${renderedCheckboxClassName} {
    background-color: ${({ theme }) => theme.colors.background.button};
    color: ${({ theme }) => theme.colors.foreground.button};
  }
`;

const getDeadlineReady = (avm: IAssignmentViewModel) => {
  // if home delivery, show deadline ready as the closest prior half-hour mark, but at least 15 minutes before the estimated start time
  // otherwise show the deadline ready as the estimated start time
  if (
    avm.case.caseTypeID === CaseTypeEnum.HomeDelivery &&
    avm.assignment.estimatedStartTime
  ) {
    // start with the estimated start time
    const deadlineReady = new Date(avm.assignment.estimatedStartTime.getTime());
    // move it back 60 minutes as a minimum buffer
    deadlineReady.setMinutes(deadlineReady.getMinutes() - 60);
    // now, move it to the closest earlier half-hour mark
    const minutes = deadlineReady.getMinutes();
    deadlineReady.setMinutes(minutes - (minutes % 30));
    // return the formatted time
    return formatDateTime(deadlineReady);
  }
  return null;
};

export interface ColumnProps {
  onSelectClick(assignment: AssignmentViewModel): void;
  selectedAssignmentIds: Set<number>;
  translations: ITranslationLookups;
  onUpdateAssignment(
    assignmentId: number,
    editOperation: AssignmentEditOperation
  ): void;
  companies: { [lookupString: string]: CompanyModel };
}

const useAssignmentListColumnSettings = (
  hideEstimatedDurationAndStartTime?: boolean
): ColumnSetting<IAssignmentViewModel, ColumnProps>[] => {
  const location = useLocation();
  const translationsLookup = useTranslations();
  const {
    state: { assignmentStatuses: visibleAssignmentStatuses },
  } = useAssignmentSearch();
  const { companies: companiesLookup } = useCompanies();

  const companiesDict = useMemo(() => {
    return companiesLookup.reduce(
      (acc, curr) => {
        let companyAddress = `${curr.address}${curr.zip}${curr.city}`;
        companyAddress = companyAddress.toLowerCase().replaceAll(' ', '');
        acc[companyAddress] = curr;
        return acc;
      },
      {} as { [lookupString: string]: CompanyModel }
    );
  }, [companiesLookup]);
  const logisticsStrangnas = useMemo(
    () => companiesLookup.find((c) => c.isLogisticsCenter),
    [companiesLookup]
  );
  const me = useMe();
  const userIsAdmin = me?.roles.includes(Roles.Admin);
  const userIsGoSupport = me?.roles.includes(Roles.GoSupport);

  const isAssignmentApprovedFilter = visibleAssignmentStatuses.includes(
    AssignmentStatusEnum.Approved
  );

  const isInternalDeliveriesRoute =
    !!matchPath(Routes.internalDeliveries.index, location.pathname) ||
    !!matchPath(Routes.internalDeliveries.case, location.pathname);

  const isHomeDeliveriesRoute =
    !!matchPath(Routes.homeDeliveries.index, location.pathname) ||
    !!matchPath(Routes.homeDeliveries.case, location.pathname);

  const isPurchasesRoute =
    !!matchPath(Routes.purchases.index, location.pathname) ||
    !!matchPath(Routes.purchases.case, location.pathname);

  const isSearchRoute =
    !!matchPath(Routes.search.index, location.pathname) ||
    !!matchPath(Routes.search.case, location.pathname);

  const isPendingAssignmentsRoute =
    !!matchPath(Routes.pendingAssignments.index, location.pathname) ||
    !!matchPath(Routes.pendingAssignments.case, location.pathname);

  const fullDateTimeColumn = isSearchRoute || isPendingAssignmentsRoute;

  const bookedToIsOver24hAgo = (avm: IAssignmentViewModel) =>
    moment(avm.assignment.bookedTo).add(24, 'hours').isBefore(moment());

  const getVisibleAddressName = useCallback(
    (model: IAssignmentViewModel) => {
      const address =
        `${model.assignment.fromAddress}${model.assignment.fromZip}${model.assignment.fromCity}`
          .toLowerCase()
          .replaceAll(' ', '');

      const addressName =
        companiesDict[address]?.name ??
        `${model.assignment.fromAddress}, ${model.assignment.fromZip} ${model.assignment.fromCity}`;

      return addressName;
    },
    [companiesDict]
  );

  return useMemo(() => {
    type Column = ColumnSetting<IAssignmentViewModel, ColumnProps>;
    const columns: Column[] = [
      userIsGoSupport && {
        head: 'Välj',
        excludeFromDownload: true,
        render: (avm, { onSelectClick, selectedAssignmentIds }) => {
          return (
            <CheckboxCell
              onClick={(e) => {
                e.stopPropagation();
                onSelectClick(avm);
              }}
            >
              <FakeCheckbox
                checked={selectedAssignmentIds.has(avm.assignment.assignmentID)}
                className={renderedCheckboxClassName}
              />
            </CheckboxCell>
          );
        },
        css: css`
          max-width: 60px;

          &:not(.${tableHeadClassName}) {
            padding: 0;
          }
        `,
        width: 60,
      },
      {
        identifier: TableColumnEnum.RowNumber,
        head: 'Nr',
        excludeFromDownload: true,
        render: (_avm, _props, _focused, rowIndex) => rowIndex + 1,
        width: 32,
        csvValue: (_avm, rowIndex) => rowIndex + 1,
      },
      isAssignmentApprovedFilter && {
        head: (
          <TooltipInfo
            hoverable
            info={
              <CaseStatusWrapper>
                <h3>Ärendestatus</h3>
                <CaseStatusCell>
                  <FontAwesomeIcon
                    icon={CaseStatusIcons[CaseStatusEnum.Open]}
                  />
                  {` ${translationsLookup.caseStatuses[CaseStatusEnum.Open]}`}
                </CaseStatusCell>
                <CaseStatusCell>
                  <FontAwesomeIcon
                    icon={CaseStatusIcons[CaseStatusEnum.Closed]}
                  />
                  {` ${translationsLookup.caseStatuses[CaseStatusEnum.Closed]}`}
                </CaseStatusCell>
              </CaseStatusWrapper>
            }
          />
        ),
        csvValue: (avm) =>
          translationsLookup.caseStatuses[avm.case.caseStatusID],
        render: (avm, { translations }) =>
          CaseStatusIcons[avm.case.caseStatusID] ? (
            <FontAwesomeIcon icon={CaseStatusIcons[avm.case.caseStatusID]} />
          ) : (
            translations.caseStatuses[avm.case.caseStatusID]
          ),
        width: 25,
      },
      {
        identifier: TableColumnEnum.AssignmentBookedTo,
        head: 'Tid',
        focusable: true,
        csvValue: (avm) => formatDateTime(avm.assignment.bookedTo),
        render: (avm, { onUpdateAssignment }, focused) =>
          focused ? (
            fullDateTimeColumn ? (
              <DateTimePickerCell
                assignmentViewModel={avm}
                onUpdateAssignment={onUpdateAssignment}
              />
            ) : (
              <TimePickerCell
                assignmentViewModel={avm}
                onUpdateAssignment={onUpdateAssignment}
              />
            )
          ) : fullDateTimeColumn ? (
            formatDateTime(avm.assignment.bookedTo)
          ) : (
            formatTime(avm.assignment.bookedTo)
          ),
        width: fullDateTimeColumn ? 145 : 50,
        css: css`
          &.${tableCellClassName} {
            color: ${({ theme }) => theme.colors.foreground.tint};
            font-family: monospace;
          }
        `,
        sortFunction: (a, b) =>
          a.assignment.bookedTo > b.assignment.bookedTo ? 1 : -1,
      },
      isPendingAssignmentsRoute && {
        identifier: TableColumnEnum.CaseType,
        head: 'Stått',
        csvValue: (avm) => {
          if (
            avm.assignment.assignmentTypeID ===
              AssignmentTypeEnum.PlannedFurtherTransport ||
            !avm.assignment.created
          ) {
            return '-';
          }
          const now = new Date();
          const created = new Date(avm.assignment.created);
          const diff = now.getTime() - created.getTime();
          return getHoursAndMinutesFromMillisecondsString(diff);
        },
        render: (avm) => {
          if (
            avm.assignment.assignmentTypeID ===
              AssignmentTypeEnum.PlannedFurtherTransport ||
            !avm.assignment.created
          ) {
            return '-';
          }
          const now = new Date();
          const created = new Date(avm.assignment.created);
          const diff = now.getTime() - created.getTime();
          return getHoursAndMinutesFromMillisecondsString(diff);
        },
        width: 130,
        sortFunction: (a, b) => {
          const aTime = a.assignment.created
            ? new Date(a.assignment.created).getTime()
            : 0;
          const bTime = b.assignment.created
            ? new Date(b.assignment.created).getTime()
            : 0;
          return aTime - bTime;
        },
      },
      {
        identifier: TableColumnEnum.AssignmentEstimatedStartTime,
        head: 'Est. start',
        focusable: false,
        csvValue: (avm) =>
          avm.assignment.estimatedStartTime
            ? formatTime(avm.assignment.estimatedStartTime)
            : '–',
        render: ({ assignment: { estimatedStartTime } }) =>
          estimatedStartTime ? formatTime(estimatedStartTime) : '–',
        width: 60,
        sortFunction: (a, b) =>
          (a.assignment.estimatedStartTime ?? 0) >
          (b.assignment.estimatedStartTime ?? 0)
            ? 1
            : -1,
        css: css`
          &.${tableCellClassName} {
            font-family: monospace;
          }
        `,
      },
      {
        identifier: TableColumnEnum.AssignmentDeadlineReady,
        hideByDefault: true,
        head: 'Senast klar för leverans',
        csvValue: (avm) => getDeadlineReady(avm),
        render: (avm) => getDeadlineReady(avm),
        width: 100,
        sortFunction: (a, b) => {
          const deadlineReadyA = getDeadlineReady(a);
          const deadlineReadyB = getDeadlineReady(b);
          if (!deadlineReadyA && !deadlineReadyB) {
            return 0;
          }
          if (!deadlineReadyA && deadlineReadyB) {
            return -1;
          }
          if (deadlineReadyA && !deadlineReadyB) {
            return 1;
          }
          return deadlineReadyA!.localeCompare(deadlineReadyB!);
        },
      },
      (isSearchRoute || isPendingAssignmentsRoute || isHomeDeliveriesRoute) && {
        identifier: TableColumnEnum.CaseType,
        head: 'Ärendetyp',
        csvValue: (avm) =>
          translationsLookup.caseTypes[
            String(avm.case.caseTypeID) as unknown as number
          ],
        render: (avm, { translations }) => {
          return translations.caseTypes[avm.case.caseTypeID];
        },
        width: 130,
        sortFunction: makeStringComparator(
          (a) => translationsLookup.caseTypes[a.case.caseTypeID] ?? ''
        ),
      },

      (isSearchRoute || isPendingAssignmentsRoute || isHomeDeliveriesRoute) && {
        identifier: TableColumnEnum.CaseType,
        head: 'Uppdragstyp',
        csvValue: (avm) =>
          translationsLookup.assignmentTypes[
            String(avm.assignment.assignmentTypeID) as unknown as number
          ],
        render: (avm, { translations }) => {
          return translations.assignmentTypes[avm.assignment.assignmentTypeID];
        },
        width: 130,
        sortFunction: makeStringComparator(
          (a) =>
            translationsLookup.assignmentTypes[a.assignment.assignmentTypeID] ??
            ''
        ),
      },

      {
        identifier: TableColumnEnum.AssignmentStatus,
        head: 'Status',
        focusable: true,
        csvValue: (avm) =>
          translationsLookup.assignmentStatuses[
            avm.assignment.assignmentStatusID
          ],
        render: (
          avm,
          { onUpdateAssignment, translations: { assignmentStatuses } },
          focused
        ) => {
          if (focused) {
            return (
              <StatusPickerCell
                assignmentStatuses={assignmentStatuses}
                assignmentViewModel={avm}
                onUpdateAssignment={onUpdateAssignment}
              />
            );
          }
          return assignmentStatuses[avm.assignment.assignmentStatusID];
        },
        width: 110,
        sortFunction: (a, b) =>
          a.assignment.assignmentStatusID > b.assignment.assignmentStatusID
            ? 1
            : -1,
      },

      isPendingAssignmentsRoute && {
        identifier: TableColumnEnum.AssignmentWarnings,
        head: '',
        csvHead: 'Varningar',
        css: css`
          &:not(.${tableHeadClassName}) {
            border-right: 0;
          }
          .alertIcon {
            font-size: 12px;
            color: ${({ theme }) => theme.colors.foreground.error};
          }
        `,
        csvValue: (avm) => {
          const over24h =
            avm.assignment.assignmentStatusID ===
              AssignmentStatusEnum.Created && bookedToIsOver24hAgo(avm);
          return over24h
            ? 'Mellanlandad i över 24h'
            : 'Mellanlandad mindre än 24h';
        },
        render: (avm) => {
          const over24h =
            avm.assignment.assignmentStatusID ===
              AssignmentStatusEnum.Created && bookedToIsOver24hAgo(avm);

          return (
            over24h && (
              <FontAwesomeIcon
                className="alertIcon"
                fixedWidth
                icon={faClockFour}
                title="Mellanlandad i över 24h"
              />
            )
          );
        },
        width: 22,
        sortFunction: (a, b) => {
          const over24hA =
            a.assignment.assignmentStatusID === AssignmentStatusEnum.Created &&
            bookedToIsOver24hAgo(a);

          const over24hB =
            b.assignment.assignmentStatusID === AssignmentStatusEnum.Created &&
            bookedToIsOver24hAgo(b);

          return booleanCompare(over24hA, over24hB);
        },
      },

      {
        identifier: TableColumnEnum.AssignmentUpdatesFromRide,
        head: '',
        csvHead: 'Uppdateringar från Ride',
        css: css`
          &:not(.${tableHeadClassName}) {
            border-right: 0;
          }
          .alertIcon {
            font-size: 12px;
            color: ${({ theme }) => theme.colors.foreground.error};
          }
        `,
        csvValue: (avm) =>
          avm.case.newDataFromRide
            ? 'Har ny uppdatering från Ride'
            : 'Inga nya uppdateringar från Ride',
        render: (avm) =>
          avm.case.newDataFromRide && (
            <FontAwesomeIcon
              className="alertIcon"
              fixedWidth
              icon={faCircleDot}
              title="Uppdatering från Ride"
            />
          ),
        width: 22,
        sortFunction: (a, b) =>
          booleanCompare(a.case.newDataFromRide, b.case.newDataFromRide),
      },
      {
        identifier: TableColumnEnum.AssignmentUnreadComments,
        head: '',
        csvHead: 'Olästa kommentarer',
        css: css`
          &:not(.${tableHeadClassName}) {
            border-right: 0;
          }
          .notifyIcon {
            font-size: 12px;
            color: ${({ theme }) => theme.colors.foreground.newMessage};
          }
        `,
        csvValue: (avm) =>
          avm.case.adminHasUnreadComments
            ? 'Har olästa kommentarer'
            : 'Saknar olästa kommentarer',
        render: (avm) =>
          avm.case.adminHasUnreadComments && (
            <FontAwesomeIcon
              className="notifyIcon"
              fixedWidth
              icon={faComment}
              title="Det finns olästa kommentarer"
            />
          ),
        width: 22,
        sortFunction: (a, b) =>
          booleanCompare(
            a.case.adminHasUnreadComments,
            b.case.adminHasUnreadComments
          ),
      },
      {
        identifier: TableColumnEnum.AssignmentWarnings,
        head: '',
        csvHead: 'Varningar',
        css: css`
          &:not(.${tableHeadClassName}) {
            border-right: 0;
          }
          .notifyIcon {
            font-size: 12px;
            color: ${({ theme }) => theme.colors.foreground.error};
          }
        `,
        csvValue: (avm) => {
          const warnings: WarningInfo[] =
            assignmentWarnings.getCaseAndAssignmentWarnings(avm);
          return warnings.map((w) => w.warning).join(', ');
        },
        render: (avm) => {
          const warnings = assignmentWarnings.getCaseAndAssignmentWarnings(avm);
          return <ExpandableWarningsWidget warnings={warnings} />;
        },
        width: 22,
        sortFunction: (a, b) => {
          const aIssues =
            assignmentWarnings.getCaseAndAssignmentWarnings(a).length;
          const bIssues =
            assignmentWarnings.getCaseAndAssignmentWarnings(b).length;

          return aIssues - bIssues;
        },
      },
      {
        identifier: TableColumnEnum.CaseHasMultipleAssignments,
        head: ' ',
        csvHead: 'Flera uppdrag',
        css: css`
          &:not(.${tableHeadClassName}) {
            border-right: 0;
          }
          .cars-icon {
            font-size: 15px;
          }
        `,
        csvValue: (avm) =>
          avm.case.assignmentList.length > 1
            ? 'Har flera uppdrag'
            : 'Har inga flera uppdrag',
        render: (avm) =>
          avm.case.assignmentList.length > 1 && (
            <span title={`${avm.case.assignmentList.length} uppdrag`}>
              <Cars className="cars-icon" />
            </span>
          ),
        width: 22,
        sortFunction: (a, b) =>
          a.case.assignmentList.length - b.case.assignmentList.length,
      },
      {
        identifier: TableColumnEnum.CaseLinkedCases,
        head: ' ',
        csvHead: 'Kopplade ärenden',
        css: css`
          .link-icon {
            font-size: 12px;
          }
        `,
        csvValue: (avm) =>
          avm.case.hasLinkedCases
            ? 'Har kopplade ärenden'
            : 'Saknar kopplade ärenden',
        render: (avm) =>
          avm.case.hasLinkedCases && (
            <FontAwesomeIcon
              className="link-icon"
              fixedWidth
              icon={faLink}
              title="Har kopplade ärenden"
            />
          ),
        width: 22,
        sortFunction: (a, b) =>
          booleanCompare(a.case.hasLinkedCases, b.case.hasLinkedCases),
      },
      {
        identifier: TableColumnEnum.CaseRegistrationNumber,
        head: 'Regnr',
        csvValue: (avm) => avm.case.registrationNumber,
        render: ({ case: { registrationNumber } }) => `${registrationNumber}`,
        width: 70,
        sortFunction: (a, b) =>
          a.case.registrationNumber > b.case.registrationNumber ? 1 : -1,

        css: css`
          &.${tableCellClassName} {
            font-family: monospace;
          }
        `,
      },
      {
        identifier: TableColumnEnum.CaseGearBox,
        head: ' ',
        csvHead: 'Växellåda',
        csvValue: (avm) => (avm.case.vehicleGearBox ? 'M' : 'A'),
        render: ({ case: { vehicleGearBox } }) =>
          `${vehicleGearBox ? 'M' : 'A'}`,
        width: 20,
        sortFunction: (a, b) =>
          a.case.vehicleGearBox && !b.case.vehicleGearBox ? 1 : -1,
      },
      userIsAdmin &&
        !isHomeDeliveriesRoute && {
          identifier: TableColumnEnum.CaseMileage,
          head: 'Miltal',
          csvValue: (avm) => avm.case.vehicleMileage,
          render: ({ case: { vehicleMileage } }) => vehicleMileage,
          width: 70,
          css: css`
            &.${tableCellClassName} {
              text-align: right;
              font-family: monospace;
            }
          `,
          sortFunction: (a, b) => a.case.vehicleMileage - b.case.vehicleMileage,
        },
      {
        identifier: TableColumnEnum.AssignmentFromAddress,
        head: 'Från',
        csvValue: ({
          assignment: { fromName, fromAddress, fromZip, fromCity },
        }) => {
          const addressString =
            `${fromAddress} ${fromZip} ${fromCity}`.replaceAll(',', ''); // Remove commas from address to avoid csv issues
          const isLogistics = fromName.startsWith('Logistik');

          if (isLogistics) {
            return logisticsStrangnas?.name ?? addressString;
          }
          let addr = `${fromAddress}${fromZip}${fromCity}`;
          addr = addr.toLowerCase().replaceAll(' ', '');

          return companiesDict[addr]?.name ?? addressString;
        },
        render: ({
          assignment: { fromName, fromAddress, fromZip, fromCity },
        }) => {
          const addressString = `${fromAddress} ${fromZip} ${fromCity}`;
          const isLogistics = fromName.startsWith('Logistik');

          if (isLogistics) {
            return logisticsStrangnas?.name ?? addressString;
          }
          let addr = `${fromAddress}${fromZip}${fromCity}`;
          addr = addr.toLowerCase().replaceAll(' ', '');
          const dictHit = companiesDict[addr];
          if (dictHit) {
            return `${dictHit.name} – ${addressString}`;
          }
          return addressString;
        },
        width: 320,
        sortFunction: (a, b) =>
          getVisibleAddressName(a).localeCompare(getVisibleAddressName(b)),
      },
      (isPurchasesRoute || isPendingAssignmentsRoute) && {
        identifier: TableColumnEnum.AssignmentFromCounty,
        head: 'Från län',
        csvValue: (avm) => avm.assignment.fromCounty?.areaName,
        render: ({ assignment: { fromCounty } }) =>
          fromCounty ? (
            <>
              <DotSpan dotColor={fromCounty.hexColorCode} />
              {fromCounty.areaName}
            </>
          ) : (
            ' '
          ),
        width: 140,
        sortFunction: (a, b) =>
          (a.assignment.fromCounty?.areaName || '') >
          (b.assignment.fromCounty?.areaName || '')
            ? 1
            : -1,
      },
      !isSearchRoute &&
        !isPurchasesRoute &&
        !isInternalDeliveriesRoute &&
        !isHomeDeliveriesRoute && {
          identifier: TableColumnEnum.AssignmentToName,
          head: 'Mottagare',
          csvValue: (avm) => avm.assignment.toName,
          render: ({ assignment: { toName } }) => toName,
          width: 160,
          sortFunction: (a, b) =>
            a.assignment.toName > b.assignment.toName ? 1 : -1,
        },
      {
        identifier: TableColumnEnum.AssignmentToAddress,
        head: 'Till',
        csvValue: ({ assignment: { toName, toAddress, toZip, toCity } }) => {
          const addressString = `${toAddress} ${toZip} ${toCity}`;
          const isLogistics = toName.startsWith('Logistik');

          if (isLogistics) {
            return logisticsStrangnas?.name ?? addressString;
          }
          let addr = `${toAddress}${toZip}${toCity}`;
          addr = addr.toLowerCase().replaceAll(' ', '');

          return companiesDict[addr]?.name ?? addressString;
        },
        render: (
          { assignment: { toName, toAddress, toZip, toCity } },
          { companies }
        ) => {
          const addressString = `${toAddress} ${toZip} ${toCity}`;
          const isLogistics = toName.startsWith('Logistik');

          if (isLogistics) {
            return logisticsStrangnas?.name ?? addressString;
          }
          let addr = `${toAddress}${toZip}${toCity}`;
          addr = addr.toLowerCase().replaceAll(' ', '');

          return companies[addr]?.name ?? addressString;
        },
        width: 320,
        sortFunction: (a, b) =>
          getVisibleAddressName(a).localeCompare(getVisibleAddressName(b)),
      },
      isHomeDeliveriesRoute && {
        identifier: TableColumnEnum.AssignmentToCounty,
        head: 'Län',
        csvValue: (avm) => avm.assignment.toCounty?.areaName,
        render: ({
          assignment: { fromCounty, toCounty },
          case: { caseTypeID },
        }) =>
          toCounty && caseTypeID === CaseTypeEnum.HomeDelivery ? (
            <span title={`Till ${toCounty.areaName}`}>
              <DotSpan dotColor={toCounty.hexColorCode} />
              {toCounty.areaName}
            </span>
          ) : fromCounty && caseTypeID === CaseTypeEnum.TradeIn ? (
            <span title={`Från ${fromCounty.areaName}`}>
              <DotSpan dotColor={fromCounty.hexColorCode} />
              {fromCounty.areaName}
            </span>
          ) : (
            ' '
          ),
        width: 140,
        sortFunction: (a, b) =>
          (a.assignment.toCounty?.areaName || '') >
          (b.assignment.toCounty?.areaName || '')
            ? 1
            : -1,
      },
      {
        identifier: TableColumnEnum.AssigneeUnavailable,
        head: ' ',
        csvHead: 'Tillgänglighetsvarningar',
        csvValue: (avm) =>
          avm.assignment.assigneeIsUnavailableOnDate ? 'Ej tillgänglig' : '',
        render: (avm) => {
          return avm.assignment.assigneeIsUnavailableOnDate ||
            avm.assignment.drivingPolicyAssignmentInNeedOfHandling ? (
            <span style={{ position: 'absolute' }}>
              <TooltipInfoWarning
                isUnavailableViolation={
                  avm.assignment.assigneeIsUnavailableOnDate
                }
                user={avm.assignment.assignedTo}
                fetchDrivingPolicyWarningsParams={
                  avm.assignment.assignedTo && {
                    userId: avm.assignment.assignedTo.userID,
                    bookedTo: avm.assignment.bookedTo,
                    estimatedStartTime: avm.assignment.estimatedStartTime,
                    estimatedDuration: avm.assignment.estimatedDuration,
                    excludeAssignmentId: avm.assignment.assignmentID,
                  }
                }
                showOnlyTooltip
              />
            </span>
          ) : null;
        },
        width: 22,
        sortFunction: (a, b) =>
          // TODO: Sort by assigneeIsUnavailableOnDate, and then by AssigneeWarnings enum
          booleanCompare(
            !!a.assignment.assigneeIsUnavailableOnDate,
            !!b.assignment.assigneeIsUnavailableOnDate
          ),
      },
      {
        identifier: TableColumnEnum.Assignee,
        head: 'Fälttestare',
        focusable: true,
        csvValue: (avm) => avm.assignment.assignedTo?.name ?? '',
        render: (assignmentViewModel, { onUpdateAssignment }) => (
          <CombinedUserAndTimeEstimationPickerCell
            hideEstimatedDurationAndStartTime={
              hideEstimatedDurationAndStartTime
            }
            assignmentViewModel={assignmentViewModel}
            onUpdateAssignment={onUpdateAssignment}
          />
        ),

        // <span>{assignmentViewModel.assignment.assignedTo?.name}</span>
        width: 150,
        sortFunction: (a, b) =>
          (a.assignment.assignedTo?.name ?? '') >
          (b.assignment.assignedTo?.name ?? '')
            ? 1
            : -1,
      },
      {
        identifier: TableColumnEnum.EstimatedDuration,
        head: 'Est. tid',
        csvValue: (avm) =>
          getHoursAndMinutesFromMillisecondsString(
            avm.assignment.estimatedDuration ?? 0
          ),
        focusable: true,
        render: (avm, { onUpdateAssignment }, focused) =>
          focused ? (
            <EstimatedDurationCell
              assignmentViewModel={avm}
              onUpdateAssignment={onUpdateAssignment}
            />
          ) : (
            `${
              avm.assignment.estimatedDuration
                ? getHoursAndMinutesFromMillisecondsString(
                    avm.assignment.estimatedDuration
                  )
                : '–'
            }`
          ),
        width: 80,
        sortFunction: (a, b) =>
          (a.assignment.estimatedDuration ?? 0) -
          (b.assignment.estimatedDuration ?? 0),
      },

      {
        identifier: TableColumnEnum.AssignmentInfoFromRide,
        head: 'Information från Ride',
        focusable: true,
        csvValue: (avm) =>
          avm.assignment.extraInfo
            ?.replaceAll('\n', ' ')
            .replaceAll(',', ' ') ?? '',
        render: (avm, { onUpdateAssignment }, focused) =>
          focused ? (
            <ExtraInfoCell
              assignmentViewModel={avm}
              onUpdateAssignment={onUpdateAssignment}
            />
          ) : (
            avm.assignment.extraInfo
          ),
        width: 200,
        sortFunction: (a, b) =>
          a.assignment.extraInfo > b.assignment.extraInfo ? 1 : -1,
      },
      {
        identifier: TableColumnEnum.AssignmentStandardizedComments,
        head: 'Egen information',
        focusable: true,
        csvValue: (avm) =>
          avm.assignment.standardizedComments
            .map((c) => c.comment)
            .join('; ')
            .replaceAll('\n', ' ')
            .replaceAll(',', ' ') ?? '',
        render: (avm, { onUpdateAssignment }, focused) =>
          focused ? (
            <StandardizedCommentsCell
              assignmentViewModel={avm}
              onUpdateAssignment={onUpdateAssignment}
            />
          ) : (
            avm.assignment.standardizedComments.map((c) => c.comment).join('; ')
          ),
        width: 200,
        sortFunction: (a, b) =>
          a.assignment.standardizedComments
            .map((c) => c.comment)
            .join('; ')
            .localeCompare(
              b.assignment.standardizedComments.map((c) => c.comment).join('; ')
            ),
      },
      isAssignmentApprovedFilter && {
        identifier: TableColumnEnum.AssignmentBreakTime,
        head: 'Rast',
        render: (avm) => `${avm.assignment.breakTime} min`,
        width: 100,
        sortFunction: (a, b) => a.assignment.breakTime - b.assignment.breakTime,
      },
      isAssignmentApprovedFilter && {
        identifier: TableColumnEnum.AssignmentWorkedTime,
        head: 'Arbetad tid',
        render: (avm) => avm.assignment.workedTimeDisplay,
        width: 100,
        sortFunction: (a, b) => {
          const durationAssignmentA =
            (a.assignment.endTime?.getTime() ?? 0) -
            (a.assignment.startTime?.getTime() ?? 0);

          const durationAssignmentB =
            (b.assignment.endTime?.getTime() ?? 0) -
            (b.assignment.startTime?.getTime() ?? 0);

          return durationAssignmentA - durationAssignmentB;
        },
      },
      {
        identifier: TableColumnEnum.AssignmentStartTime,
        head: 'Starttid',
        csvValue: (avm) => formatTime(avm.assignment.startTime),
        render: ({ assignment: { startTime } }) =>
          startTime ? formatTime(startTime) : ' ',
        width: 60,
        sortFunction: (a, b) =>
          (a.assignment.startTime ?? 0) > (b.assignment.startTime ?? 0)
            ? 1
            : -1,
      },
      {
        identifier: TableColumnEnum.AssignmentEndTime,
        head: 'Sluttid',
        csvValue: (avm) => formatTime(avm.assignment.endTime),
        render: ({ assignment: { endTime } }) =>
          endTime ? formatTime(endTime) : ' ',
        width: 60,
        sortFunction: (a, b) =>
          (a.assignment.endTime ?? 0) > (b.assignment.endTime ?? 0) ? 1 : -1,
      },
    ] as Column[];

    return columns.filter((c) => !!c); // remove undefined before returning columns
  }, [
    userIsGoSupport,
    isAssignmentApprovedFilter,
    translationsLookup.caseStatuses,
    translationsLookup.caseTypes,
    translationsLookup.assignmentTypes,
    translationsLookup.assignmentStatuses,
    fullDateTimeColumn,
    isSearchRoute,
    isPendingAssignmentsRoute,
    isHomeDeliveriesRoute,
    userIsAdmin,
    isPurchasesRoute,
    isInternalDeliveriesRoute,
    companiesDict,
    logisticsStrangnas?.name,
    getVisibleAddressName,
    hideEstimatedDurationAndStartTime,
  ]);
};

export default useAssignmentListColumnSettings;
