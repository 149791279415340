import { AssignmentViewModel, CompanyModel } from 'api';

const normalize = (str: string): string => {
  return str.toLowerCase().replace(/\s/g, '');
};

function addressMatches(
  company: CompanyModel,
  name: string,
  address: string,
  zip: string,
  city: string
) {
  return (
    normalize(name) === normalize(company.name) &&
    normalize(address) === normalize(company.address) &&
    normalize(zip) === normalize(company.zip) &&
    normalize(city) === normalize(company.city)
  );
}

// eslint-disable-next-line import/prefer-default-export
export function assignmentAddressesMatches(
  assignments: AssignmentViewModel[],
  fromAddress?: CompanyModel | null,
  toAddress?: CompanyModel | null
) {
  const filteredFrom = fromAddress
    ? assignments.filter((a) =>
        addressMatches(
          fromAddress,
          a.assignment.fromName,
          a.assignment.fromAddress,
          a.assignment.fromZip,
          a.assignment.fromCity
        )
      )
    : assignments;

  const filteredTo = toAddress
    ? filteredFrom.filter((a) =>
        addressMatches(
          toAddress,
          a.assignment.toName,
          a.assignment.toAddress,
          a.assignment.toZip,
          a.assignment.toCity
        )
      )
    : filteredFrom;

  return filteredTo;
}
