import useModalStack from 'contexts/modal/useModalStack';
import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import AvailabilityFormModal from './AvailabilityFormModal';
import useUsers from 'contexts/basicData/useUsers';
import {
  formatTime,
  getDayName,
  getHoursAndMinutesFromMillisecondsString,
} from 'utils/date-helpers';
import TooltipInfo from 'components/TooltipInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight, faClock } from '@fortawesome/free-solid-svg-icons';
import useTranslations from 'contexts/basicData/useTranslations';
import userPropsIconDictionary, {
  getUserPropsIconArray,
} from 'pages/Employees/filterableUserFields';
import { UserFormField } from '../useUserForm';
import AbsenceReasonIcon from './AbsenceReasonIcon';
import UserFormModal from '../UserFormModal';
import Table, { SortState } from 'components/Table';
import { ColumnSetting } from 'components/Table/utils';
import Checkbox from 'components/inputs/Checkbox';
import { EmployeeWithCounty } from './types';
import { isSameDay } from 'date-fns';
import { issueWarning } from './availability-helper';
import useTeamGroups from 'contexts/basicData/useTeamGroups';
import useTeams from 'contexts/basicData/useTeams';
import { useDrivingPolicyGetDrivingReports } from 'api/driving-policy/driving-policy';
import {
  AvailabilityDto,
  AvailabilityStatus,
  DrivingPolicyReportDto,
  TranslationLookups,
  UserModel,
} from 'api/model';
import { UserModel as OldUserModel } from 'api';
import { useHolidaysGetHolidays } from 'api/holidays/holidays';
import { useUserUpdateUserAvailability } from 'api/user/user';
import { makeBooleanComparator } from 'utils/sorting';
import { arraySpreadIf } from 'utils/spreading';

const MyTable = styled(Table)`
  display: box;
  widhth: 200px;
` as typeof Table;

// const InfoDot = styled.div`
//   position: absolute;
//   top: 2px;
//   left: 2px;
//   width: 7px;
//   height: 7px;
//   border-radius: 50%;
//   background-color: ${({ theme }) => theme.colors.foreground.tint};
//   box-shadow: 0px 0px 3px 0px white;
// `;

const NoWrap = styled.div<{
  black?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  white-space: nowrap;
`;

const DateBoxMeta = styled.div`
  position: absolute;
  flex: 1;
  display: flex;
  z-index: 1;
`;

const ProgressContainer = styled.div`
  width: 100%; // Make the container fill the full width
  height: 100%; // Make the container fill the full height
  background: ${({ theme }) =>
    theme.colors.background.tertiary}; // Background for the unfilled part
`;

// const ProgressBar = styled.div<{ widthPercent: number }>`
//   width: ${({ widthPercent }) => widthPercent}%;
//   background: ${({ widthPercent, theme }) =>
//     widthPercent === 100
//       ? theme.colors.foreground.error
//       : widthPercent > 70
//         ? theme.colors.foreground.warning // You can use your theme color here
//         : theme.colors.foreground.good};
//   height: 100%; // Make the progress bar fill the container's height
//   display: flex;
//   align-items: center;
//   padding: 0 5px;
//   color: ${({ theme, widthPercent }) =>
//     widthPercent === 100
//       ? theme.colors.foreground.button
//       : theme.colors.foreground.primary};
// `;

const ProgressBar = styled.div.attrs<{ widthPercent: number }>(
  ({ widthPercent, theme }) => ({
    style: {
      width: `${widthPercent}%`,
      background:
        widthPercent === 100
          ? theme.colors.foreground.error
          : widthPercent > 70
            ? theme.colors.foreground.warning
            : theme.colors.foreground.good,
      color:
        widthPercent === 100
          ? theme.colors.foreground.button
          : theme.colors.foreground.primary,
    },
  })
)<{ widthPercent: number }>`
  height: 100%; // Make the progress bar fill the container's height
  display: flex;
  align-items: center;
  padding: 0 5px;
`;

const DateBoxNumber = styled.div`
  position: absolute;
  font-weight: bold;
  flex: 1;
  display: flex;
  flex-direction: row-reverse;
`;

const Box = styled.div`
  border: 1px solid black;
  border-radius: 3px;
  height: 22px;
  margin: 2px;
  & > div {
    position: relative;
    height: 20px;
    display: flex;
    flex: 1;
  }
`;

const DateTitleContainer = styled.div`
  position: relative;
`;

const rowClassNameMaker = (rowId: number) => {
  return `row-${rowId}`;
};

const TableStylerWrapper = styled.div<{ updatingRows: number[] }>`
  display: contents;

  ${({ updatingRows }) => {
    return updatingRows.map(
      (id) => css`
        .${rowClassNameMaker(id)} {
          animation: assignmentlist-save-animation 1s infinite;
        }
      `
    );
  }}

  @keyframes assignmentlist-save-animation {
    0% {
      background: #ddeefd;
    }
    50% {
      background: ${({ theme }) => theme.colors.background.primary};
    }
    100% {
      background: #ddeefd;
    }
  }
`;

const TitleDate = styled.div<{
  flex: number;
  highlighted: boolean;
  isHoliday?: boolean;
}>`
  display: flex;
  flex: ${({ flex }) => flex};
  font-weight: bold;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  color: ${({ highlighted, theme }) =>
    highlighted
      ? theme.colors.foreground.tint
      : theme.colors.foreground.button};
  gap: 3px;

  ${({ isHoliday }) => isHoliday && 'color: red;'}

  & > span.weekday {
    font-size: 0.8em;
  }
  & > span.weekday {
    font-weight: normal;
  }
`;

export const Badge = styled.div<{ background?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  border-width: 0.5px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.foreground.primary};
  background-color: ${({ theme, background }) =>
    background || theme.colors.background.button};
  color: ${({ theme }) => theme.colors.foreground.button};
  text-align: center;
  padding: 1px;
  font-size: 0.7em;
`;

const DateBox = styled(Box)<{
  flex: number;
  colorCode?: string;
  isPast?: boolean;
  isUnspecified?: boolean;
  isUnavailable?: boolean;
}>`
  display: flex;
  max-width: 35px;
  width: 35px;
  flex-direction: row;
  flex: ${({ flex }) => flex};
  align-items: center;
  justify-content: center;
  user-select: none;
  font-weight: bold;
  background-color: ${({ colorCode }) => colorCode || 'transparent'};
  color: ${({ theme }) => theme.colors.foreground.primary};

  &:hover {
    box-shadow: 0 0 0 2px inset
      ${({ theme }) => theme.colors.background.selection};
  }
  opacity: ${({ isPast }) => (isPast ? 0.5 : 1)};
  margin: 0px !important;
  cursor: pointer;
  ${({ isUnavailable }) =>
    isUnavailable &&
    css`
      background: linear-gradient(
        to top right,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) calc(50% - 0.8px),
        rgba(0, 0, 0, 1) 50%,
        rgba(0, 0, 0, 0) calc(50% + 0.8px),
        rgba(0, 0, 0, 0) 100%
      );
    `}
`;

const Label = styled.div<{ inverted?: boolean }>`
  display: flex;
  flex-direction: row !important;
  gap: 5px;
  white-space: nowrap;
  padding: 0 5px;
  cursor: pointer;
  ${({ inverted }) => inverted && 'color: white;'}
  justify-content: flex-start;
  align-items: center;
`;

const AvailabilityCell = styled.div<{ available?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: monospace;
  gap: 5px;
  ${({ onClick }) => onClick && 'cursor: pointer;'}
  ${({ available, theme }) =>
    !available && `color: ${theme.colors.foreground.tertiary};`}
`;

const now = new Date();
now.setHours(0, 0, 0, 0);

const RowCss = css`
  align-items: center;
  display: flex;
  &:hover {
    overflow: visible;
    // children of this row
    & > div {
      z-index: 10;
    }
  }
`;

interface Props {
  employees: EmployeeWithCounty[];
  startDate: Date;
  endDate: Date;

  onUpdate: () => void;
}

const AvailabilityTable: React.FC<Props> = ({
  startDate,
  endDate,
  onUpdate,
  employees,
}) => {
  const { teamGroupsById } = useTeamGroups();
  const { teamsById } = useTeams();
  const isSingleDay = useMemo(() => {
    // return true if start and end date are the same day
    return (
      startDate &&
      endDate &&
      startDate.toDateString() === endDate.toDateString()
    );
  }, [startDate, endDate]);

  const {
    data: drivingPolicyReports,
    // status,
    // refetch,
  } = useDrivingPolicyGetDrivingReports({
    query: {
      enabled: isSingleDay,
    },
  });

  const drivingPolicyReportsByUserId = useMemo(() => {
    const map = new Map<number, DrivingPolicyReportDto>();
    drivingPolicyReports?.forEach((report) => {
      if (report.fieldTesterId === undefined) return;
      map.set(report.fieldTesterId, report);
    });
    return map;
  }, [drivingPolicyReports]);

  const [sortState, setSortState] = useState<SortState>({
    ascending: true,
    sortedColumnIndex: 0,
  });
  const theme = useTheme();
  const { availabilityStatusColorCodes } = useTranslations();

  const [highlightedDate, setHighlightedDate] = useState<number>();
  const [editUser, setEditUser] = useState<UserModel | null>(null);
  const [updatingUsers, setUpdatingUsers] = useState<number[]>([]);

  const users = useUsers();

  const modalStack = useModalStack();

  const holidays = useHolidaysGetHolidays();
  useEffect(() => {
    holidays.mutate({
      params: {
        from: startDate,
        to: endDate,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);
  // const holidays = useResponse(
  //   HolidaysClient,
  //   (c) => c.getHolidays(startDate, endDate),
  //   [startDate, endDate]
  // );

  const updateAvailabilityCall = useUserUpdateUserAvailability();
  // const updateAvailabilityCall = useApiCall(
  //   UserClient,
  //   (c, userId: number, availability: AvailabilityDto) =>
  //     c.updateUserAvailability(
  //       UpdateUserAvailabilityRequest.fromJS({
  //         userId,
  //         availability,
  //       })
  //     )
  // );

  const handleSaved = useCallback(async () => {
    onUpdate();
  }, [onUpdate]);

  const isHoliday = useCallback(
    (date: Date) => {
      // return true if date is a holiday or a sunday
      if (!holidays.data) return false;
      return (
        holidays.data?.find((h) => {
          const result = new Date(h.date).getDate() === date.getDate();

          return result;
        }) !== undefined || date.getDay() === 0
      );
    },
    [holidays]
  );

  const getAvailability = useCallback(
    (date: Date) => {
      // return the amount of employees without absence on a given date
      return employees.filter((e) => {
        const schedule = e.schedule.find((s) =>
          isSameDay(new Date(s.dateString), date)
        );
        return schedule?.scheduleStatus === AvailabilityStatus.AVAILABLE;
      }).length;
    },
    [employees]
  );

  const getContent = useCallback(
    (availability?: AvailabilityDto): React.ReactNode | null => {
      if (!availability) return null;

      const content: React.ReactNode[] = [];

      if (availability?.absenceStatus !== null) {
        content.push(
          <DateBoxMeta key="absence-reason">
            <AbsenceReasonIcon
              absenceReason={availability.absenceReason}
              absenceStatus={availability.absenceStatus}
            />
          </DateBoxMeta>
        );
      } else if (
        (availability.startString ?? '') !==
          (availability.originalTimes?.startString ?? '') ||
        (availability.endString ?? '') !==
          (availability.originalTimes?.endString ?? '')
      ) {
        // if availability.start and .end differ from originalTimes.start and .end, display a clock icon
        content.push(
          <DateBoxMeta key="schedule-icon">
            <FontAwesomeIcon
              color={theme.colors.foreground.button}
              icon={faClock}
              size="sm"
              title="Annan tid än schemalagd"
            />
          </DateBoxMeta>
        );
      }

      if (availability.isOnlyAvailableForFurtherTransport) {
        content.push(
          <DateBoxMeta key="futher-transport-icon">
            <Badge background={theme.colors.foreground.warning}>
              <FontAwesomeIcon icon={faAngleDoubleRight} />
            </Badge>
          </DateBoxMeta>
        );
      }

      if (
        availability?.bookedAssignments &&
        availability?.bookedAssignments > 0
      ) {
        content.push(
          <DateBoxNumber key="datebox-number">
            <Badge
              background={
                issueWarning(availability)
                  ? theme.colors.foreground.warning
                  : theme.colors.background.selection
              }
            >
              {availability?.bookedAssignments}
            </Badge>
          </DateBoxNumber>
        );
      }

      return content;
    },
    [
      theme.colors.background.selection,
      theme.colors.foreground.button,
      theme.colors.foreground.warning,
    ]
  );

  const columnSettings: ColumnSetting<
    EmployeeWithCounty,
    TranslationLookups
  >[] = useMemo(() => {
    const columns: ColumnSetting<EmployeeWithCounty, TranslationLookups>[] = [
      {
        head: 'Nr',
        css: RowCss,
        render: (e, t, f, i) => i + 1,
        width: 40,
      },
      {
        css: RowCss,
        head: 'Län',
        csvValue: (e) => e.zipCodeArea?.areaName ?? '',
        render: (e) => <Label>{e.zipCodeArea?.areaName}</Label>,
        width: 120,
        sortFunction: (a, b) =>
          (a.zipCodeArea?.areaName ?? '').localeCompare(
            b.zipCodeArea?.areaName ?? ''
          ),
      },
      {
        css: RowCss,
        head: 'Område',
        csvValue: (e) => e.location ?? '',
        render: (e) => <Label>{e.location}</Label>,
        width: 120,
        sortFunction: (a, b) => a.location.localeCompare(b.location),
      },
      {
        css: RowCss,
        head: 'Grupp',
        csvValue: (e) => teamsById.get(e.teamId ?? -1)?.name ?? '-',
        render: (e) => (
          <Label>{teamsById.get(e.teamId ?? -1)?.name ?? '-'}</Label>
        ),
        width: 150,
        sortFunction: (a, b) => {
          const teamA = teamsById.get(a.teamId ?? -1);
          const teamB = teamsById.get(b.teamId ?? -1);
          return (teamA?.name ?? '').localeCompare(teamB?.name ?? '');
        },
      },
      {
        css: RowCss,
        head: 'Avdelning',
        csvValue: (e) =>
          teamGroupsById.get(teamsById.get(e.teamId ?? -1)?.teamGroupId ?? -1)
            ?.name ?? '-',
        render: (e) => (
          <Label>
            {teamGroupsById.get(
              teamsById.get(e.teamId ?? -1)?.teamGroupId ?? -1
            )?.name ?? '-'}
          </Label>
        ),
        width: 140,
        sortFunction: (a, b) => {
          const teamA = teamsById.get(a.teamId ?? -1);
          const teamB = teamsById.get(b.teamId ?? -1);
          const groupA = teamGroupsById.get(teamA?.teamGroupId ?? -1);
          const groupB = teamGroupsById.get(teamB?.teamGroupId ?? -1);
          return (groupA?.name ?? '').localeCompare(groupB?.name ?? '');
        },
      },
      {
        css: RowCss,
        head: 'Namn',
        csvValue: (e) => e.fullName,
        render: (e) => (
          <Label onClick={() => setEditUser(users[e.id])} title={e.fullName}>
            {e.fullName}
          </Label>
        ),
        width: 150,
        sortFunction: (a, b) => a.fullName.localeCompare(b.fullName),
      },
      {
        css: RowCss,
        head: 'Ort',
        csvValue: (e) => e.city ?? '',
        render: (e) => (
          <Label onClick={() => setEditUser(users[e.id])} title={e.city}>
            {e.city}
          </Label>
        ),
        width: 100,
        sortFunction: (a, b) => a.city.localeCompare(b.city),
      },
      ...arraySpreadIf(isSingleDay, {
        head: '',
        csvHead:
          userPropsIconDictionary[UserFormField.canTestPremiumCars].title,
        csvValue: (usr: EmployeeWithCounty) =>
          usr.canTestPremiumCars ? 'Ja' : 'Nej',
        render: (usr: EmployeeWithCounty) =>
          userPropsIconDictionary[UserFormField.canTestPremiumCars].icon(
            usr.canTestPremiumCars
          ),
        width: 15,
        sortFunction: makeBooleanComparator(
          (usr: EmployeeWithCounty) => usr.canTestPremiumCars
        ),
      }),
      ...arraySpreadIf(isSingleDay, {
        head: '',
        csvHead: userPropsIconDictionary[UserFormField.canTestTrucks].title,
        csvValue: (usr: EmployeeWithCounty) =>
          usr.canTestTrucks ? 'Ja' : 'Nej',
        render: (usr: EmployeeWithCounty) =>
          userPropsIconDictionary[UserFormField.canTestTrucks].icon(
            usr.canTestTrucks
          ),
        width: 15,
        sortFunction: makeBooleanComparator(
          (usr: EmployeeWithCounty) => usr.canTestTrucks
        ),
      }),
      ...arraySpreadIf(isSingleDay, {
        head: '',
        csvHead: userPropsIconDictionary[UserFormField.canDoHomeDelivery].title,
        csvValue: (usr: EmployeeWithCounty) =>
          usr.canDoHomeDelivery ? 'Ja' : 'Nej',
        render: (usr: EmployeeWithCounty) =>
          userPropsIconDictionary[UserFormField.canDoHomeDelivery].icon(
            usr.canDoHomeDelivery
          ),
        width: 15,
        sortFunction: makeBooleanComparator(
          (usr: EmployeeWithCounty) => usr.canDoHomeDelivery
        ),
      }),
      ...arraySpreadIf(isSingleDay, {
        head: '',
        csvHead: userPropsIconDictionary[UserFormField.canDriveElectric].title,
        csvValue: (usr: EmployeeWithCounty) =>
          usr.canDriveElectric ? 'Ja' : 'Nej',
        render: (usr: EmployeeWithCounty) =>
          userPropsIconDictionary[UserFormField.canDriveElectric].icon(
            usr.canDriveElectric
          ),
        width: 15,
        sortFunction: makeBooleanComparator(
          (usr: EmployeeWithCounty) => usr.canDriveElectric
        ),
      }),
      ...arraySpreadIf(isSingleDay, {
        head: '',
        csvHead: userPropsIconDictionary[UserFormField.canDriveManual].title,
        csvValue: (usr: EmployeeWithCounty) =>
          usr.canDriveManual ? 'Ja' : 'Nej',
        render: (usr: EmployeeWithCounty) =>
          userPropsIconDictionary[UserFormField.canDriveManual].icon(
            usr.canDriveManual
          ),
        width: 15,
        sortFunction: makeBooleanComparator(
          (usr: EmployeeWithCounty) => usr.canDriveManual
        ),
      }),
      ...arraySpreadIf(isSingleDay, {
        head: '',
        csvHead: userPropsIconDictionary[UserFormField.canDriveTrucks].title,
        csvValue: (usr: EmployeeWithCounty) =>
          usr.canDriveTrucks ? 'Ja' : 'Nej',
        render: (usr: EmployeeWithCounty) =>
          userPropsIconDictionary[UserFormField.canDriveTrucks].icon(
            usr.canDriveTrucks
          ),
        width: 15,
        sortFunction: makeBooleanComparator(
          (usr: EmployeeWithCounty) => usr.canDriveTrucks
        ),
      }),
      ...arraySpreadIf(isSingleDay, {
        head: '',
        csvHead: userPropsIconDictionary[UserFormField.canDriveTrailers].title,
        csvValue: (usr: EmployeeWithCounty) =>
          usr.canDriveTrailers ? 'Ja' : 'Nej',
        render: (usr: EmployeeWithCounty) =>
          userPropsIconDictionary[UserFormField.canDriveTrailers].icon(
            usr.canDriveTrailers
          ),
        width: 15,
        sortFunction: makeBooleanComparator(
          (usr: EmployeeWithCounty) => usr.canDriveTrailers
        ),
      }),
      ...arraySpreadIf(isSingleDay, {
        head: '',
        csvHead: userPropsIconDictionary[UserFormField.canDrivePremium].title,
        csvValue: (usr: EmployeeWithCounty) =>
          usr.canDrivePremium ? 'Ja' : 'Nej',
        render: (usr: EmployeeWithCounty) =>
          userPropsIconDictionary[UserFormField.canDrivePremium].icon(
            usr.canDrivePremium
          ),
        width: 15,
        sortFunction: makeBooleanComparator(
          (usr: EmployeeWithCounty) => usr.canDrivePremium
        ),
      }),
      ...arraySpreadIf(isSingleDay, {
        head: '',
        csvHead: userPropsIconDictionary[UserFormField.canDoLongDrives].title,
        csvValue: (usr: EmployeeWithCounty) =>
          usr.canDoLongDrives ? 'Ja' : 'Nej',
        render: (usr: EmployeeWithCounty) =>
          userPropsIconDictionary[UserFormField.canDoLongDrives].icon(
            usr.canDoLongDrives
          ),
        width: 15,
        sortFunction: makeBooleanComparator(
          (usr: EmployeeWithCounty) => usr.canDoLongDrives
        ),
      }),
      ...arraySpreadIf(isSingleDay, {
        head: '',
        csvHead: userPropsIconDictionary[UserFormField.isFullTime].title,
        csvValue: (usr: EmployeeWithCounty) => (usr.isFullTime ? 'Ja' : 'Nej'),
        render: (usr: EmployeeWithCounty) =>
          userPropsIconDictionary[UserFormField.isFullTime].icon(
            usr.isFullTime
          ),
        width: 15,
        sortFunction: makeBooleanComparator(
          (usr: EmployeeWithCounty) => usr.isFullTime
        ),
      }),
      ...arraySpreadIf(isSingleDay, {
        head: '',
        csvHead:
          userPropsIconDictionary[UserFormField.certifiedToCallCustomers].title,
        csvValue: (usr: EmployeeWithCounty) =>
          usr.certifiedToCallCustomers ? 'Ja' : 'Nej',
        render: (usr: EmployeeWithCounty) =>
          userPropsIconDictionary[UserFormField.certifiedToCallCustomers].icon(
            usr.certifiedToCallCustomers
          ),
        width: 15,
        sortFunction: makeBooleanComparator(
          (usr: EmployeeWithCounty) => usr.certifiedToCallCustomers
        ),
      }),
      ...arraySpreadIf(isSingleDay, {
        head: '',
        csvHead: userPropsIconDictionary[UserFormField.canStayAtHotels].title,
        csvValue: (usr: EmployeeWithCounty) =>
          usr.canStayAtHotels ? 'Ja' : 'Nej',
        render: (usr: EmployeeWithCounty) =>
          userPropsIconDictionary[UserFormField.canStayAtHotels].icon(
            usr.canStayAtHotels
          ),
        width: 15,
        sortFunction: makeBooleanComparator(
          (usr: EmployeeWithCounty) => usr.canStayAtHotels
        ),
      }),
      {
        css: css`
          align-items: center;
          justify-content: center;
          display: flex;
        `,
        head: 'Heltid',
        csvValue: (e: EmployeeWithCounty) => (e.isFullTime ? 'Ja' : 'Nej'),
        render: (e: EmployeeWithCounty) => (
          <NoWrap onClick={() => setEditUser(users[e.id])}>
            {e.isFullTime &&
              getUserPropsIconArray(e)
                ?.filter((x) => x.field === UserFormField.isFullTime)
                ?.map((x) => (
                  <div aria-label={x.title} key={x.title} title={x.title}>
                    {x.icon}
                  </div>
                ))}
          </NoWrap>
        ),
        width: 65,
        sortFunction: (a, b) => {
          if (a.isFullTime === b.isFullTime) {
            return 0;
          }
          return a.isFullTime ? -1 : 1;
        },
      },
      {
        css: css`
          overflow: visible;
          align-items: center;
          justify-content: center;
          display: flex;
        `,
        head: 'Varning',
        csvValue: (e) =>
          e.unavailabilityWarning
            ? 'Har en eller flera avvikelser som behöver hanteras'
            : ' ',
        render: (e) =>
          e.unavailabilityWarning && (
            <TooltipInfo
              hoverable
              info={
                <span>
                  {`${e.fullName} har en eller flera avvikelser som behöver hanteras`}
                </span>
              }
              warning
            />
          ),
        width: 65,
        sortFunction: (a, b) => {
          if (a.unavailabilityWarning === b.unavailabilityWarning) {
            return 0;
          }
          return a.unavailabilityWarning ? -1 : 1;
        },
      },
    ];
    if (isSingleDay) {
      columns.push({
        css: css`
          align-items: center;
          justify-content: center;
          display: flex;
        `,
        head: 'Fullbokad',
        csvValue: (e) => (e.schedule[0]?.isFullyBooked ? 'Ja' : 'Nej'),
        render: (e) => (
          <NoWrap>
            <Checkbox
              checked={e.schedule[0]?.isFullyBooked}
              disabled={(
                [
                  AvailabilityStatus.UNAVAILABLE,
                  AvailabilityStatus.UNSPECIFIED,
                  AvailabilityStatus.PENDING_SPECIFICATION,
                ] as AvailabilityStatus[]
              ).includes(e.schedule[0]?.scheduleStatus)}
              onChange={async (ev) => {
                ev.preventDefault();
                const newValue = ev.currentTarget.checked;
                const availability = e.schedule[0];

                if (availability) {
                  const updatedAvailability: AvailabilityDto = {
                    ...availability,
                    userID: e.id,
                    dateString: availability.dateString,
                    isFullyBooked: newValue,
                  };
                  // add to list of updating users
                  setUpdatingUsers((prev) => [...prev, e.id]);
                  try {
                    await updateAvailabilityCall.mutateAsync({
                      data: {
                        userId: e.id,
                        availability: updatedAvailability,
                      },
                    });

                    onUpdate();
                  } catch (error) {
                    // console.log(error);
                  }
                  // remove from list of updating users
                  setUpdatingUsers((prev) => prev.filter((x) => x !== e.id));
                }
              }}
            />
          </NoWrap>
        ),
        width: 75,
        sortFunction: (a, b) => {
          if (a.schedule[0]?.isFullyBooked === b.schedule[0]?.isFullyBooked) {
            return 0;
          }
          return a.schedule[0]?.isFullyBooked ? -1 : 1;
        },
      });
    }

    for (
      let date = startDate;
      date <= endDate;
      date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1)
    ) {
      columns.push({
        head: (
          <div style={{ position: 'relative' }}>
            <TooltipInfo
              hoverable
              info={
                <div
                  style={{
                    width: '100%',
                    whiteSpace: 'nowrap',
                    color: 'black',
                  }}
                >
                  <span>Antal tillgängliga:</span>
                  <b>{getAvailability(date)}</b>
                </div>
              }
              node={
                <DateTitleContainer>
                  <TitleDate
                    flex={1}
                    highlighted={date.getDate() === highlightedDate}
                    isHoliday={isHoliday(date)}
                    key={`title_${date}`}
                  >
                    <span className="weekday">{getDayName(date)}</span>
                    <span className="date">
                      {`${date.getDate()}/${date.getMonth() + 1}`}
                    </span>
                  </TitleDate>
                </DateTitleContainer>
              }
            />
          </div>
        ),
        render: (e) => {
          const schedule = e.schedule.find((s) =>
            isSameDay(new Date(s.dateString), date)
          );

          if (!schedule) return null;

          return (
            <DateBox
              colorCode={
                !e.isAvailableForDriving
                  ? 'grey'
                  : availabilityStatusColorCodes[schedule.scheduleStatus]
              }
              flex={1}
              isPast={date < now}
              isUnavailable={
                schedule.scheduleStatus === AvailabilityStatus.UNAVAILABLE
              }
              key={date.toISOString()}
              onClick={() =>
                modalStack.push(
                  <AvailabilityFormModal
                    availability={schedule}
                    name={e.fullName}
                    onClose={() => modalStack.pop()}
                    onSaved={() => handleSaved()}
                    userId={e.id}
                  />
                )
              }
              onMouseOver={() => setHighlightedDate(date.getDate())}
            >
              {getContent(schedule)}
            </DateBox>
          );
        },
        width: 40,
      });
      if (isSingleDay) {
        // add column showing the user's availability for the day
        columns.push({
          css: RowCss,
          head: 'Tillgänglighet',
          csvValue: (e) => {
            const schedule = e.schedule.find((s) =>
              isSameDay(new Date(s.dateString), date)
            );

            if (!schedule) return ' ';

            return schedule?.startString && schedule?.endString
              ? `${schedule.startString} - ${schedule.endString}`
              : ' ';
          },
          render: (e) => {
            const schedule = e.schedule.find((s) =>
              isSameDay(new Date(s.dateString), date)
            );

            if (!schedule) return null;

            return (
              <AvailabilityCell
                available={
                  schedule.scheduleStatus === AvailabilityStatus.AVAILABLE
                }
              >
                {schedule?.startString &&
                  schedule?.endString &&
                  `${schedule.startString} - ${schedule.endString}`}
              </AvailabilityCell>
            );
          },
          width: 125,
          sortFunction: (a, b) => {
            // sort on start time
            const scheduleA = a.schedule.find((s) =>
              isSameDay(new Date(s.dateString), date)
            );
            const scheduleB = b.schedule.find((s) =>
              isSameDay(new Date(s.dateString), date)
            );

            return (
              scheduleA?.startString?.localeCompare(
                scheduleB?.startString ?? ''
              ) ?? 0
            );
          },
        });

        columns.push({
          css: RowCss,
          head: 'Senast bokad till',
          render: (e) => {
            const schedule = e.schedule.find((s) =>
              isSameDay(new Date(s.dateString), date)
            );

            if (!schedule) return null;

            return (
              <AvailabilityCell
                available={
                  schedule.scheduleStatus === AvailabilityStatus.AVAILABLE
                }
                onClick={() => {
                  modalStack.push(
                    <AvailabilityFormModal
                      availability={schedule}
                      name={e.fullName}
                      onClose={() => modalStack.pop()}
                      onSaved={() => handleSaved()}
                      userId={e.id}
                    />
                  );
                }}
              >
                {e.lastDerivedEstimatedEndTime
                  ? `${e.lastDerivedEstimatedEndTime.getDate()}/${
                      e.lastDerivedEstimatedEndTime.getMonth() + 1
                    } ${formatTime(e.lastDerivedEstimatedEndTime)}`
                  : '-'}
              </AvailabilityCell>
            );
          },
          width: 125,
          sortFunction: (a, b) => {
            if (
              a.lastDerivedEstimatedEndTime &&
              !b.lastDerivedEstimatedEndTime
            ) {
              return -1;
            }

            if (
              !a.lastDerivedEstimatedEndTime &&
              b.lastDerivedEstimatedEndTime
            ) {
              return 1;
            }

            if (
              !a.lastDerivedEstimatedEndTime &&
              !b.lastDerivedEstimatedEndTime
            ) {
              return 0;
            }

            return (
              +a.lastDerivedEstimatedEndTime! - +b.lastDerivedEstimatedEndTime!
            );
          },
        });

        columns.push(
          {
            head: 'Körtid innevarande vecka',
            css: RowCss,
            render: (e) => {
              const report = drivingPolicyReportsByUserId.get(e.id);
              const value = Math.max(report?.hoursDrivenThisWeek || 0, 0);
              return report?.hoursDrivenThisWeek ? (
                <ProgressContainer>
                  <ProgressBar
                    widthPercent={
                      value ? (value / 0.48 >= 100 ? 100 : value / 0.48) : 0
                    }
                  >
                    {getHoursAndMinutesFromMillisecondsString(
                      value * 1000 * 60 * 60
                    )}
                  </ProgressBar>
                </ProgressContainer>
              ) : (
                '-'
              );
            },
            sortFunction: (a, b) => {
              const reportA = drivingPolicyReportsByUserId.get(a.id);
              const reportB = drivingPolicyReportsByUserId.get(b.id);

              return (
                (reportA?.hoursDrivenThisWeek ?? 0) -
                (reportB?.hoursDrivenThisWeek ?? 0)
              );
            },
            width: 200,
          },
          {
            head: 'Körtid innevarande månad',
            css: RowCss,
            render: (e) => {
              const report = drivingPolicyReportsByUserId.get(e.id);
              const value = Math.max(report?.hoursDrivenThisMonth || 0, 0);

              return value ? (
                <ProgressContainer>
                  <ProgressBar
                    widthPercent={
                      value ? (value / 1.92 >= 100 ? 100 : value / 1.92) : 0
                    }
                  >
                    {getHoursAndMinutesFromMillisecondsString(
                      value * 1000 * 60 * 60
                    )}
                  </ProgressBar>
                </ProgressContainer>
              ) : (
                '-'
              );
            },
            sortFunction: (a, b) => {
              const reportA = drivingPolicyReportsByUserId.get(a.id);
              const reportB = drivingPolicyReportsByUserId.get(b.id);

              return (
                (reportA?.hoursDrivenThisMonth ?? 0) -
                (reportB?.hoursDrivenThisMonth ?? 0)
              );
            },
            width: 200,
          },
          {
            head: 'Körtid senaste 28d',
            css: RowCss,
            render: (e) => {
              const report = drivingPolicyReportsByUserId.get(e.id);
              const value = Math.max(report?.hoursDrivenLast28Days || 0, 0);

              return value ? (
                <ProgressContainer>
                  <ProgressBar
                    widthPercent={
                      value ? (value / 1.92 >= 100 ? 100 : value / 1.92) : 0
                    }
                  >
                    {getHoursAndMinutesFromMillisecondsString(
                      value * 1000 * 60 * 60
                    )}
                  </ProgressBar>
                </ProgressContainer>
              ) : (
                '-'
              );
            },
            sortFunction: (a, b) => {
              const reportA = drivingPolicyReportsByUserId.get(a.id);
              const reportB = drivingPolicyReportsByUserId.get(b.id);

              return (
                (reportA?.hoursDrivenLast28Days ?? 0) -
                (reportB?.hoursDrivenLast28Days ?? 0)
              );
            },
            width: 200,
          }
        );
      }
    }
    return columns;
  }, [
    isSingleDay,
    teamsById,
    teamGroupsById,
    users,
    updateAvailabilityCall,
    onUpdate,
    startDate,
    endDate,
    getAvailability,
    highlightedDate,
    isHoliday,
    availabilityStatusColorCodes,
    getContent,
    modalStack,
    handleSaved,
    drivingPolicyReportsByUserId,
  ]);

  return (
    <Suspense fallback="Laddar...">
      <TableStylerWrapper updatingRows={updatingUsers}>
        <MyTable
          columnSettings={columnSettings}
          initialSortState={sortState}
          onSortStateChange={setSortState}
          rowClassName={(user) => rowClassNameMaker(user.id)}
          rows={employees}
          useColumnWidthAsFlexAmount={
            // when device width > 768
            window.innerWidth > 768
          }
        />
        {editUser && (
          <UserFormModal
            canEdit
            onCancel={() => setEditUser(null)}
            onSaved={onUpdate}
            user={editUser as OldUserModel}
          />
        )}
      </TableStylerWrapper>
    </Suspense>
  );
};

export default AvailabilityTable;
