import { CompanyClient, CompanyModel } from 'api';
import { atom, selector, useRecoilState, useRecoilValue } from 'recoil';
import { useApiCall } from 'swaggerhooks/lib';

const getCompanyAddressDictKey = (address: string, zip: string, city: string) =>
  `${address}${zip}${city}`.toLowerCase().replaceAll(' ', '');

export const companiesAtom = atom<{
  companies: CompanyModel[];
  allCompanies: CompanyModel[];
  companiesById: Map<number, CompanyModel>;
  activeCompaniesByAddrDict: Map<string, CompanyModel>;
  companiesByAddrDict: Map<string, CompanyModel>;
  getCompanyAddressDictKey: (
    address: string,
    zip: string,
    city: string
  ) => string;
}>({
  key: 'companiesLookup',
  default: selector({
    key: 'companiesLookupSelector',
    get: async (): Promise<{
      companies: CompanyModel[];
      allCompanies: CompanyModel[];
      companiesById: Map<number, CompanyModel>;
      activeCompaniesByAddrDict: Map<string, CompanyModel>;
      companiesByAddrDict: Map<string, CompanyModel>;
      getCompanyAddressDictKey: (
        address: string,
        zip: string,
        city: string
      ) => string;
    }> => {
      const url = `/api/Company/GetCompanies`.replace(/[?&]$/, '');

      const options: RequestInit = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
      };

      const response = await fetch(url, options);

      if (!response.ok) {
        return {
          companies: [],
          allCompanies: [],
          companiesById: new Map(),
          activeCompaniesByAddrDict: new Map(),
          companiesByAddrDict: new Map(),
          getCompanyAddressDictKey,
        };
      }

      const deserialized: CompanyModel[] = await response.json();
      const onlyActiveCompanies = deserialized.filter((c) => c.active);

      return {
        companies: onlyActiveCompanies,
        allCompanies: deserialized,
        companiesById: new Map(deserialized.map((e) => [e.companyID, e])),
        activeCompaniesByAddrDict: onlyActiveCompanies.reduce((acc, curr) => {
          // acc[companyAddress] = curr;
          acc.set(
            getCompanyAddressDictKey(curr.address, curr.zip, curr.city),
            curr
          );
          return acc;
        }, new Map<string, CompanyModel>()),
        companiesByAddrDict: deserialized.reduce((acc, curr) => {
          // acc[companyAddress] = curr;
          acc.set(
            getCompanyAddressDictKey(curr.address, curr.zip, curr.city),
            curr
          );
          return acc;
        }, new Map<string, CompanyModel>()),
        getCompanyAddressDictKey,
      };
    },
  }),
});

export const useRefreshCompaniesCall = () => {
  const [, setState] = useRecoilState(companiesAtom);

  const apiCall = useApiCall(CompanyClient, async (c) => {
    const result = await c.getCompanies();
    setState({
      companies: result?.filter((x) => x.active),
      allCompanies: result,
      companiesById: new Map(result.map((comp) => [comp.companyID, comp])),
      activeCompaniesByAddrDict: result
        ?.filter((x) => x.active)
        .reduce((acc, curr) => {
          let companyAddress = `${curr.address}${curr.zip}${curr.city}`;
          companyAddress = companyAddress.toLowerCase().replaceAll(' ', '');
          acc.set(companyAddress, curr);
          return acc;
        }, new Map<string, CompanyModel>()),
      companiesByAddrDict: result.reduce((acc, curr) => {
        let companyAddress = `${curr.address}${curr.zip}${curr.city}`;
        companyAddress = companyAddress.toLowerCase().replaceAll(' ', '');
        acc.set(companyAddress, curr);
        return acc;
      }, new Map<string, CompanyModel>()),
      getCompanyAddressDictKey,
    });
    return result;
  });

  return apiCall;
};

const useCompanies = () => {
  return useRecoilValue(companiesAtom);
};

export default useCompanies;
