import { useAverageAssignmentDurationsGetAverageAssignmentDurationHook } from 'api/average-assignment-durations/average-assignment-durations';
import {
  AssignmentModel,
  CaseTypeEnum,
  EstimatedAssignmentDurationDto,
} from 'api/model';
import TooltipInfo from 'components/TooltipInfo';
import Checkbox from 'components/inputs/Checkbox';
import Select from 'components/inputs/Select';
import TextButton from 'components/inputs/TextButton';
import useTranslations from 'contexts/basicData/useTranslations';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { openGoogleMapsDirections } from 'utils/address-helper';
import { getHoursAndMinutesFromMillisecondsString } from 'utils/date-helpers';
import esitimatedAssignmentDurations from 'utils/esitimatedAssignmentDurations';

const HighlightedText = styled.span`
  color: ${({ theme }) => theme.colors.foreground.tint};
`;
const PaddedSpan = styled.span`
  padding: 0 5px;
`;

interface Props {
  caseType: CaseTypeEnum;
  disabled?: boolean;
  assignment: AssignmentModel;
  hideSelectAverageCheckbox?: boolean; // hiding this until we have better data
  placeholder?: string;
  blurOnSelect?: boolean;
  error?: boolean;
  onChangeEstimatedDuration: (estimatedDuration: number) => void;
  onChangeSystemEstimatedDurationExact: (estimatedDuration: number) => void;
  onChangeSystemEstimatedDurationApprox: (estimatedDuration: number) => void;
  width?: number;
  hideMapsIcon?: boolean;
}
const EstimatedDurationPicker: React.FC<Props> = ({
  assignment,
  blurOnSelect = true,
  caseType,
  disabled,
  error,
  hideSelectAverageCheckbox,
  onChangeEstimatedDuration,
  onChangeSystemEstimatedDurationExact,
  onChangeSystemEstimatedDurationApprox,
  placeholder,
  width,
  hideMapsIcon,
}) => {
  const selectRef = useRef<HTMLSelectElement>(null);
  const [suggestedEstimatedDuration, setSuggestedEstimatedDuration] = useState<
    { min: number; max: number; average: number } | undefined
  >();
  const { zipCodeAreas } = useTranslations();
  const avgDurationCallTimeoutRef = useRef<NodeJS.Timeout>();

  const [estimatedDurationData, setEstimatedDurationData] =
    useState<EstimatedAssignmentDurationDto>();

  useEffect(() => {
    const captureFn = (event: Event) => {
      event.preventDefault();
    };
    if (blurOnSelect) {
      selectRef.current?.addEventListener('blur', captureFn);
    }
  }, [blurOnSelect]);

  const isUsingRecommendedDuration =
    assignment.estimatedDuration === suggestedEstimatedDuration?.average ?? 0;

  const noFromOrToAddress =
    !assignment.fromAddress ||
    !assignment.toAddress ||
    !assignment.fromZip ||
    !assignment.toZip ||
    !assignment.fromCity ||
    !assignment.toCity;

  const getAverageAssignmentDurationCall =
    useAverageAssignmentDurationsGetAverageAssignmentDurationHook();

  useEffect(
    () => {
      const fetchAverageDuration = async () => {
        // if (state)
        const { assignmentTypeID, fromCounty, toCounty, fromZip, toZip } =
          assignment;
        if (
          !fromCounty?.areaName ||
          !toCounty?.areaName ||
          !fromZip ||
          !toZip
        ) {
          return; // we need them for the average duration
        }

        // get the number of minutes from the average duration
        const result = await getAverageAssignmentDurationCall({
          caseType,
          assignmentType: assignmentTypeID,
          fromZipCode: fromZip,
          toZipCode: toZip,
          fromCounty: fromCounty.areaName,
          toCounty: toCounty?.areaName,
        });

        if (result !== null && result.sampleSize > 0) {
          // convert the number of seconds to milliseconds in order to use the estimatedDurationOptions array
          const millisAverage = Math.round(result.average * 1000 * 60);
          const millisMin = result.min * 1000 * 60;
          const millisMax = result.max * 1000 * 60;

          // return the nearest value in the estimatedDurationOptions array
          const nearestAverage =
            esitimatedAssignmentDurations.getNearestEstimatedDuration(
              millisAverage
            );
          const nearestMin =
            esitimatedAssignmentDurations.getNearestEstimatedDuration(
              millisMin
            );
          const nearestMax =
            esitimatedAssignmentDurations.getNearestEstimatedDuration(
              millisMax
            );

          // note down our system estimates
          if (assignment.systemEstimatedDurationExact !== millisAverage) {
            onChangeSystemEstimatedDurationExact(millisAverage);
          }
          if (assignment.systemEstimatedDurationApprox !== nearestAverage) {
            onChangeSystemEstimatedDurationApprox(nearestAverage);
          }

          setSuggestedEstimatedDuration({
            min: nearestMin,
            max: nearestMax,
            average: nearestAverage,
          });
        } else {
          setSuggestedEstimatedDuration(undefined);
        }
        setEstimatedDurationData(result ?? undefined);
      };
      if (avgDurationCallTimeoutRef.current) {
        clearTimeout(avgDurationCallTimeoutRef.current);
      }
      avgDurationCallTimeoutRef.current = setTimeout(fetchAverageDuration, 500);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      caseType,
      zipCodeAreas,
      assignment.assignedTo?.userID,
      assignment.fromZip,
      assignment.toZip,
      assignment.assignmentTypeID,
    ]
  );

  const estimatedDurationOptions = useMemo(() => {
    const result = esitimatedAssignmentDurations.estimatedDurationOptions;
    if (
      suggestedEstimatedDuration !== undefined &&
      !result.includes(suggestedEstimatedDuration.average)
    ) {
      result.push(suggestedEstimatedDuration.average);
    }
    if (
      assignment.estimatedDuration &&
      !result.includes(assignment.estimatedDuration)
    ) {
      result.push(assignment.estimatedDuration);
    }

    // sort the array
    result.sort((a, b) => a - b);

    return result;
  }, [assignment.estimatedDuration, suggestedEstimatedDuration]);

  return (
    <>
      <Select
        disabled={disabled}
        error={error}
        onChange={(eve) => {
          onChangeEstimatedDuration(Number(eve.currentTarget.value));
          if (blurOnSelect) {
            eve.currentTarget.blur();
          }
        }}
        ref={selectRef}
        small
        width={width}
        value={assignment.estimatedDuration}
      >
        <option key="unset" value={undefined}>
          {placeholder ?? 'Välj estimerad tid'}
        </option>
        {estimatedDurationOptions.map((option) => (
          <option key={option} value={option}>
            {getHoursAndMinutesFromMillisecondsString(option)}
            {suggestedEstimatedDuration?.average === option && ' (genomsnitt)'}
          </option>
        ))}
      </Select>
      <HighlightedText>
        {!hideSelectAverageCheckbox &&
          suggestedEstimatedDuration !== undefined && (
            <PaddedSpan>
              <Checkbox
                checked={isUsingRecommendedDuration}
                disabled={isUsingRecommendedDuration || disabled}
                onChange={() => {
                  if (suggestedEstimatedDuration === undefined) return;
                  onChangeEstimatedDuration(suggestedEstimatedDuration.average);
                }}
                onClick={() => {
                  selectRef.current?.blur();
                }}
              >
                {`${
                  isUsingRecommendedDuration ? 'Använder' : 'Använd'
                } genomsnitt: ${getHoursAndMinutesFromMillisecondsString(
                  suggestedEstimatedDuration.average
                )}`}
              </Checkbox>
            </PaddedSpan>
          )}{' '}
        {!hideSelectAverageCheckbox &&
          estimatedDurationData?.warnings &&
          estimatedDurationData?.warnings.length > 0 && (
            <TooltipInfo
              hoverable
              info={estimatedDurationData?.warnings?.map((warning) => (
                <div key={warning} style={{ width: 250 }}>
                  {warning}
                </div>
              ))}
              left={-10}
              warning
            />
          )}
        {!hideMapsIcon && (
          <TextButton
            disabled={noFromOrToAddress}
            onClick={() =>
              openGoogleMapsDirections({
                fromAddress: assignment.fromAddress,
                fromCity: assignment.fromCity,
                fromZip: assignment.fromZip,
                toAddress: assignment.toAddress,
                toCity: assignment.toCity,
                toZip: assignment.toZip,
              })
            }
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            title="Öppna i Google Maps"
          >
            <img
              alt="Google maps icon"
              height={15}
              src="/images/googlemapsiconsmall.png"
              width={15}
            />
          </TextButton>
        )}
      </HighlightedText>
    </>
  );
};
export default EstimatedDurationPicker;
