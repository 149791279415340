import { AbsenceReason, AbsenceStatus } from 'api';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBaby,
  faBabyCarriage,
  faBed,
  faHandHoldingHeart,
  faQuestion,
  faThermometerFull,
  faUmbrellaBeach,
} from '@fortawesome/free-solid-svg-icons';
import useTranslations from 'contexts/basicData/useTranslations';
import React from 'react';

const Badge = styled.div<{ background?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 17px;
  height: 17px;
  border-radius: 2px;
  border-width: 0.5px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.foreground.primary};
  background-color: ${({ theme, background }) =>
    background || theme.colors.background.button};
  color: ${({ theme }) => theme.colors.foreground.button};
  text-align: center;
  padding: 1px;
  font-size: 0.7em;
`;

const getAbsenceIcon = (absenceReason: AbsenceReason | null | undefined) => {
  if (absenceReason === null) {
    return faQuestion;
  }
  switch (absenceReason) {
    case AbsenceReason.VACATION:
      return faUmbrellaBeach;
    case AbsenceReason.ILLNESS:
      return faThermometerFull;
    case AbsenceReason.CARE_OF_CHILDREN:
      return faBaby;
    case AbsenceReason.CARE_OF_RELATIVE:
      return faHandHoldingHeart;
    case AbsenceReason.REST_DAY:
      return faBed;
    case AbsenceReason.PARENTAL_LEAVE:
      return faBabyCarriage;
    case AbsenceReason.OTHER:
    default:
      return faQuestion;
  }
};

interface Props {
  absenceStatus?: AbsenceStatus;
  absenceReason?: AbsenceReason | null;
}

const AbsenceReasonIcon: React.FC<Props> = ({
  absenceStatus,
  absenceReason,
}) => {
  const { absenceReasons } = useTranslations();

  return (
    <Badge
      background={
        absenceStatus === AbsenceStatus.APPROVED
          ? 'green'
          : absenceStatus === AbsenceStatus.DECLINED
            ? 'red'
            : undefined
      }
      title={(absenceReason && absenceReasons[absenceReason]) || undefined}
    >
      <FontAwesomeIcon icon={getAbsenceIcon(absenceReason)} />
    </Badge>
  );
};

export default AbsenceReasonIcon;
