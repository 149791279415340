import { WidgetMetadata } from 'components/Dashboard/types';

export enum TLWidgetsEnum {
  FTsOnStandby,
  PlaceholderAssignments,
  MissingExpectedExpenses,
  IncompleteDayPlan,
}

export const UPDATE_INTERVAL = 15000; // 15sek

export const DefaultTLWidgets: WidgetMetadata<TLWidgetsEnum>[] = [
  {
    identifier: TLWidgetsEnum.FTsOnStandby,
    shortTitle: 'Fälttestare på standby',
    longTitle: 'Fälttestare på standby',
    active: true,
  },
  {
    identifier: TLWidgetsEnum.PlaceholderAssignments,
    shortTitle: 'Utfyllnadsuppdrag',
    longTitle: 'Utfyllnadsuppdrag som måste ersättas',
    active: true,
  },
  {
    identifier: TLWidgetsEnum.MissingExpectedExpenses,
    shortTitle: 'Saknade kvitton',
    longTitle: 'Saknade förväntade kvitton',
    active: true,
  },
  {
    identifier: TLWidgetsEnum.IncompleteDayPlan,
    shortTitle: 'Ofullständig dagsplan',
    longTitle: 'Ofullständig dagsplan',
    active: true,
  },
];

export const LongTitles: Record<TLWidgetsEnum, string> =
  DefaultTLWidgets.reduce(
    (titles, widget) => ({
      ...titles,
      [widget.identifier]: widget.longTitle,
    }),
    {} as Record<TLWidgetsEnum, string>
  );
