/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  AITestResult,
  AITestResultModel,
  AiVerifyWorkGearIsInPhoto200Five,
  AiVerifyWorkGearIsInPhoto200Four,
  AiVerifyWorkGearIsInPhoto200One,
  AiVerifyWorkGearIsInPhoto200Three,
  AiVerifyWorkGearIsInPhoto200Two,
  AiVerifyWorkGearIsInPhotoB64200Five,
  AiVerifyWorkGearIsInPhotoB64200Four,
  AiVerifyWorkGearIsInPhotoB64200One,
  AiVerifyWorkGearIsInPhotoB64200Three,
  AiVerifyWorkGearIsInPhotoB64200Two,
  AiVerifyWorkGearIsInPhotoParams,
  GetPromptRequestBody,
  TestWorkGearIsInPhotoB64Request,
  VerifyWorkGearIsInPhotoB64Request,
  WorkGearType,
} from '.././model';
import useAiVerifyWorkGearIsInPhotoMutator from '.././mutator/custom-instance';
import useAiVerifyWorkGearIsInPhotoB64Mutator from '.././mutator/custom-instance';
import useAiTestAnalyzeImageForWorkGearB64Mutator from '.././mutator/custom-instance';
import useAiGetLatestAITestResultModelsMutator from '.././mutator/custom-instance';
import useAiGetUserPromptMutator from '.././mutator/custom-instance';
import useAiGetSystemPromptMutator from '.././mutator/custom-instance';

export const useAiVerifyWorkGearIsInPhotoHook = () => {
  const aiVerifyWorkGearIsInPhoto = useAiVerifyWorkGearIsInPhotoMutator<
    | AiVerifyWorkGearIsInPhoto200One
    | AiVerifyWorkGearIsInPhoto200Two
    | AiVerifyWorkGearIsInPhoto200Three
    | AiVerifyWorkGearIsInPhoto200Four
    | AiVerifyWorkGearIsInPhoto200Five
  >();

  return (
    workGearType: WorkGearType[],
    params?: AiVerifyWorkGearIsInPhotoParams
  ) => {
    return aiVerifyWorkGearIsInPhoto({
      url: `/api/Ai/VerifyWorkGearIsInPhoto`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: workGearType,
      params,
    });
  };
};

export const useAiVerifyWorkGearIsInPhotoMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAiVerifyWorkGearIsInPhotoHook>>>,
    TError,
    { data: WorkGearType[]; params?: AiVerifyWorkGearIsInPhotoParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAiVerifyWorkGearIsInPhotoHook>>>,
  TError,
  { data: WorkGearType[]; params?: AiVerifyWorkGearIsInPhotoParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const aiVerifyWorkGearIsInPhoto = useAiVerifyWorkGearIsInPhotoHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAiVerifyWorkGearIsInPhotoHook>>>,
    { data: WorkGearType[]; params?: AiVerifyWorkGearIsInPhotoParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return aiVerifyWorkGearIsInPhoto(data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type AiVerifyWorkGearIsInPhotoMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAiVerifyWorkGearIsInPhotoHook>>>
>;
export type AiVerifyWorkGearIsInPhotoMutationBody = WorkGearType[];
export type AiVerifyWorkGearIsInPhotoMutationError = unknown;

export const useAiVerifyWorkGearIsInPhoto = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAiVerifyWorkGearIsInPhotoHook>>>,
    TError,
    { data: WorkGearType[]; params?: AiVerifyWorkGearIsInPhotoParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useAiVerifyWorkGearIsInPhotoHook>>>,
  TError,
  { data: WorkGearType[]; params?: AiVerifyWorkGearIsInPhotoParams },
  TContext
> => {
  const mutationOptions = useAiVerifyWorkGearIsInPhotoMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAiVerifyWorkGearIsInPhotoB64Hook = () => {
  const aiVerifyWorkGearIsInPhotoB64 = useAiVerifyWorkGearIsInPhotoB64Mutator<
    | AiVerifyWorkGearIsInPhotoB64200One
    | AiVerifyWorkGearIsInPhotoB64200Two
    | AiVerifyWorkGearIsInPhotoB64200Three
    | AiVerifyWorkGearIsInPhotoB64200Four
    | AiVerifyWorkGearIsInPhotoB64200Five
  >();

  return (
    verifyWorkGearIsInPhotoB64Request: VerifyWorkGearIsInPhotoB64Request
  ) => {
    return aiVerifyWorkGearIsInPhotoB64({
      url: `/api/Ai/VerifyWorkGearIsInPhotoB64`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: verifyWorkGearIsInPhotoB64Request,
    });
  };
};

export const useAiVerifyWorkGearIsInPhotoB64MutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAiVerifyWorkGearIsInPhotoB64Hook>>>,
    TError,
    { data: VerifyWorkGearIsInPhotoB64Request },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAiVerifyWorkGearIsInPhotoB64Hook>>>,
  TError,
  { data: VerifyWorkGearIsInPhotoB64Request },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const aiVerifyWorkGearIsInPhotoB64 = useAiVerifyWorkGearIsInPhotoB64Hook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAiVerifyWorkGearIsInPhotoB64Hook>>>,
    { data: VerifyWorkGearIsInPhotoB64Request }
  > = (props) => {
    const { data } = props ?? {};

    return aiVerifyWorkGearIsInPhotoB64(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AiVerifyWorkGearIsInPhotoB64MutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAiVerifyWorkGearIsInPhotoB64Hook>>>
>;
export type AiVerifyWorkGearIsInPhotoB64MutationBody =
  VerifyWorkGearIsInPhotoB64Request;
export type AiVerifyWorkGearIsInPhotoB64MutationError = unknown;

export const useAiVerifyWorkGearIsInPhotoB64 = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAiVerifyWorkGearIsInPhotoB64Hook>>>,
    TError,
    { data: VerifyWorkGearIsInPhotoB64Request },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useAiVerifyWorkGearIsInPhotoB64Hook>>>,
  TError,
  { data: VerifyWorkGearIsInPhotoB64Request },
  TContext
> => {
  const mutationOptions =
    useAiVerifyWorkGearIsInPhotoB64MutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAiTestAnalyzeImageForWorkGearB64Hook = () => {
  const aiTestAnalyzeImageForWorkGearB64 =
    useAiTestAnalyzeImageForWorkGearB64Mutator<AITestResult>();

  return (testWorkGearIsInPhotoB64Request: TestWorkGearIsInPhotoB64Request) => {
    return aiTestAnalyzeImageForWorkGearB64({
      url: `/api/Ai/TestAnalyzeImageForWorkGearB64`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: testWorkGearIsInPhotoB64Request,
    });
  };
};

export const useAiTestAnalyzeImageForWorkGearB64MutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAiTestAnalyzeImageForWorkGearB64Hook>>
    >,
    TError,
    { data: TestWorkGearIsInPhotoB64Request },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useAiTestAnalyzeImageForWorkGearB64Hook>>
  >,
  TError,
  { data: TestWorkGearIsInPhotoB64Request },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const aiTestAnalyzeImageForWorkGearB64 =
    useAiTestAnalyzeImageForWorkGearB64Hook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useAiTestAnalyzeImageForWorkGearB64Hook>>
    >,
    { data: TestWorkGearIsInPhotoB64Request }
  > = (props) => {
    const { data } = props ?? {};

    return aiTestAnalyzeImageForWorkGearB64(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AiTestAnalyzeImageForWorkGearB64MutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useAiTestAnalyzeImageForWorkGearB64Hook>>
  >
>;
export type AiTestAnalyzeImageForWorkGearB64MutationBody =
  TestWorkGearIsInPhotoB64Request;
export type AiTestAnalyzeImageForWorkGearB64MutationError = unknown;

export const useAiTestAnalyzeImageForWorkGearB64 = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAiTestAnalyzeImageForWorkGearB64Hook>>
    >,
    TError,
    { data: TestWorkGearIsInPhotoB64Request },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useAiTestAnalyzeImageForWorkGearB64Hook>>
  >,
  TError,
  { data: TestWorkGearIsInPhotoB64Request },
  TContext
> => {
  const mutationOptions =
    useAiTestAnalyzeImageForWorkGearB64MutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAiGetLatestAITestResultModelsHook = () => {
  const aiGetLatestAITestResultModels =
    useAiGetLatestAITestResultModelsMutator<AITestResultModel[]>();

  return (signal?: AbortSignal) => {
    return aiGetLatestAITestResultModels({
      url: `/api/Ai/GetLatestAITestResultModels`,
      method: 'GET',
      signal,
    });
  };
};

export const getAiGetLatestAITestResultModelsQueryKey = () => {
  return [`/api/Ai/GetLatestAITestResultModels`] as const;
};

export const useAiGetLatestAITestResultModelsQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useAiGetLatestAITestResultModelsHook>>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAiGetLatestAITestResultModelsHook>>
    >,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAiGetLatestAITestResultModelsQueryKey();

  const aiGetLatestAITestResultModels = useAiGetLatestAITestResultModelsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useAiGetLatestAITestResultModelsHook>>>
  > = ({ signal }) => aiGetLatestAITestResultModels(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAiGetLatestAITestResultModelsHook>>
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AiGetLatestAITestResultModelsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAiGetLatestAITestResultModelsHook>>>
>;
export type AiGetLatestAITestResultModelsQueryError = unknown;

export const useAiGetLatestAITestResultModels = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useAiGetLatestAITestResultModelsHook>>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAiGetLatestAITestResultModelsHook>>
    >,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useAiGetLatestAITestResultModelsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useAiGetUserPromptHook = () => {
  const aiGetUserPrompt = useAiGetUserPromptMutator<string>();

  return (getPromptRequestBody: GetPromptRequestBody) => {
    return aiGetUserPrompt({
      url: `/api/Ai/GetUserPrompt`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: getPromptRequestBody,
    });
  };
};

export const useAiGetUserPromptMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAiGetUserPromptHook>>>,
    TError,
    { data: GetPromptRequestBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAiGetUserPromptHook>>>,
  TError,
  { data: GetPromptRequestBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const aiGetUserPrompt = useAiGetUserPromptHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAiGetUserPromptHook>>>,
    { data: GetPromptRequestBody }
  > = (props) => {
    const { data } = props ?? {};

    return aiGetUserPrompt(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AiGetUserPromptMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAiGetUserPromptHook>>>
>;
export type AiGetUserPromptMutationBody = GetPromptRequestBody;
export type AiGetUserPromptMutationError = unknown;

export const useAiGetUserPrompt = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAiGetUserPromptHook>>>,
    TError,
    { data: GetPromptRequestBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useAiGetUserPromptHook>>>,
  TError,
  { data: GetPromptRequestBody },
  TContext
> => {
  const mutationOptions = useAiGetUserPromptMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAiGetSystemPromptHook = () => {
  const aiGetSystemPrompt = useAiGetSystemPromptMutator<string>();

  return (getPromptRequestBody: GetPromptRequestBody) => {
    return aiGetSystemPrompt({
      url: `/api/Ai/GetSystemPrompt`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: getPromptRequestBody,
    });
  };
};

export const useAiGetSystemPromptMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAiGetSystemPromptHook>>>,
    TError,
    { data: GetPromptRequestBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAiGetSystemPromptHook>>>,
  TError,
  { data: GetPromptRequestBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const aiGetSystemPrompt = useAiGetSystemPromptHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAiGetSystemPromptHook>>>,
    { data: GetPromptRequestBody }
  > = (props) => {
    const { data } = props ?? {};

    return aiGetSystemPrompt(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AiGetSystemPromptMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAiGetSystemPromptHook>>>
>;
export type AiGetSystemPromptMutationBody = GetPromptRequestBody;
export type AiGetSystemPromptMutationError = unknown;

export const useAiGetSystemPrompt = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAiGetSystemPromptHook>>>,
    TError,
    { data: GetPromptRequestBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useAiGetSystemPromptHook>>>,
  TError,
  { data: GetPromptRequestBody },
  TContext
> => {
  const mutationOptions = useAiGetSystemPromptMutationOptions(options);

  return useMutation(mutationOptions);
};
