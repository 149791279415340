import { useEffect, useMemo, useState } from 'react';
import Widget from '../../../components/Dashboard/Widget';
import Table from 'components/Table';
import { ColumnSetting } from 'components/Table/utils';
import useUsers from 'contexts/basicData/useUsers';
import { formatDateTime } from 'utils/date-helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import Routes from 'constants/Routes';
import { useDashboardControls } from 'contexts/Dashboard/useDashboardControls';
import { AssignmentDTO } from 'api/model';
import { LongTitles, TLWidgetsEnum, UPDATE_INTERVAL } from '../utils';
import { Centered, getTableRowClassName } from '../../GoSupport/table-helpers';
import useTranslations from 'contexts/basicData/useTranslations';
import { useTransportLogisticsGetAssignmentsWithMissingExpectedExpenses } from 'api/transport-logistics/transport-logistics';

const TABLE_NAME = 'placeholder-assignments';

const PlaceholderAssignmentsWidget = () => {
  const [assignments, setAssignments] = useState<AssignmentDTO[]>([]);
  const users = useUsers();
  const {
    dashboardState: { daterange, selectedBusinessUnits, searchText },
  } = useDashboardControls();
  const translations = useTranslations();

  const getAssignments =
    useTransportLogisticsGetAssignmentsWithMissingExpectedExpenses(
      {
        startDate: daterange[0] ?? undefined,
        endDate: daterange[1] ?? undefined,
        businessUnitIDs: selectedBusinessUnits,
      },
      {
        query: {
          refetchInterval: UPDATE_INTERVAL,
        },
      }
    );

  useEffect(() => {
    if (getAssignments.data) {
      setAssignments(getAssignments.data);
    } else {
      setAssignments([] as AssignmentDTO[]);
    }
  }, [getAssignments.data]);

  const filteredAssignments = useMemo(() => {
    if (!searchText.length) {
      return assignments;
    }
    return assignments
      ? assignments.filter((assignment) => {
          return (
            assignment.registrationNumber
              .toLowerCase()
              .includes(searchText.toLowerCase()) ||
            (users[assignment.assignedToID]?.name ?? '')
              .toLowerCase()
              .includes(searchText.toLowerCase())
          );
        })
      : [];
  }, [assignments, searchText, users]);

  const columnSettings: ColumnSetting<AssignmentDTO, any>[] = useMemo(() => {
    const columns: ColumnSetting<AssignmentDTO, any>[] = [
      {
        head: 'Nr',
        render: (e, t, f, i) => i + 1,
        width: 15,
      },
      {
        head: 'Status',
        render: (row) =>
          `${row.assignmentStatus ? translations.assignmentStatuses[row.assignmentStatus] : ''}`,
        width: 50,
        sortFunction: (a, b) => {
          return a.assignmentStatus - b.assignmentStatus;
        },
      },
      {
        head: 'Bokat till',
        render: (row) => `${formatDateTime(row.bookedTo)}`,
        width: 100,
        sortFunction: (a, b) => a.bookedTo.getTime() - b.bookedTo.getTime(),
      },
      {
        head: 'Estimerad starttid',
        render: (row) => `${formatDateTime(row.estimatedStartTime)}`,
        width: 100,
        // sort by startTime which can be undefined
        sortFunction: (a, b) => {
          if (a.estimatedStartTime && b.estimatedStartTime) {
            return (
              a.estimatedStartTime.getTime() - b.estimatedStartTime.getTime()
            );
          }
          return 0;
        },
      },
      {
        head: 'Saknade utgifter',
        render: (row) => (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {row.expenseTypes
              ? row.expenseTypes?.map((r) => {
                  return (
                    <span key={`expense-type-${r}`}>
                      {translations.expenseTypes[r]}{' '}
                    </span>
                  );
                })
              : ''}
          </>
        ),
        width: 100,
        // sort by missing expected expenses which can be undefined
        sortFunction: (a, b) => {
          if (a.expenseTypes && b.expenseTypes) {
            return a.expenseTypes.length - b.expenseTypes.length;
          }
          return 0;
        },
      },
      {
        head: 'Fälttestare',
        render: (row) => {
          return (
            <span>
              {users[row.assignedToID] ? users[row.assignedToID].name : ''}
            </span>
          );
        },
        width: 100,
        sortFunction: (a, b) =>
          (users[a.assignedToID]?.name ?? '').localeCompare(
            users[b.assignedToID]?.name ?? ''
          ),
      },
      {
        head: 'Telefonnummer',
        render: (row) => {
          return (
            <span>
              {users[row.assignedToID]
                ? users[row.assignedToID].phoneNumber
                : ''}
            </span>
          );
        },
        width: 100,
        sortFunction: (a, b) =>
          (users[a.assignedToID]?.phoneNumber ?? '').localeCompare(
            users[b.assignedToID]?.phoneNumber ?? ''
          ),
      },
      {
        head: ' ',
        render: (row) => (
          <Centered>
            <FontAwesomeIcon
              onClick={() => {
                window.open(
                  `${Routes.search.index}/${row.caseID}`,
                  row.caseID.toString() ?? '_blank'
                );
              }}
              icon={faArrowUpRightFromSquare}
              fontSize="18px"
              style={{ cursor: 'pointer' }}
            />
          </Centered>
        ),
        width: 20,
      },
    ];
    return columns;
  }, [translations.assignmentStatuses, translations.expenseTypes, users]);

  return (
    <Widget
      title={LongTitles[TLWidgetsEnum.MissingExpectedExpenses]}
      count={assignments.length}
      isLoading={getAssignments.isLoading}
      info="Utfyllnadsuppdrag som saknar förväntade kvitton, dessa ska läggas in innan uppdraget skickas ut."
    >
      <Table
        columnSettings={columnSettings}
        rows={filteredAssignments}
        rowClassName={(row) => getTableRowClassName(TABLE_NAME, row.id)}
        useColumnWidthAsFlexAmount
      />
    </Widget>
  );
};

export default PlaceholderAssignmentsWidget;
