import React from 'react';
import styled from 'styled-components';
import { formatDateTime } from 'utils/date-helpers';
import { GenericFileViewer } from 'components/Files/FileViewer';
import { containers } from 'constants/AppConstants';
import { AITestResultModel } from 'api/model';
import Button from 'components/inputs/Button';
import useUsers from 'contexts/basicData/useUsers';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ResultWrapper = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  border: 1px solid ${({ theme }) => theme.colors.border.primary};
  padding: 10px;
`;

const JSONDataDiv = styled.div`
  overflow: auto;
  resize: both;
  font-family: monospace;
  font-size: 12px;

  ol {
    list-style-type: none;
  }

  li {
    border: 1px solid ${({ theme }) => theme.colors.border.primary};
    background-color: ${({ theme }) => theme.colors.background.secondary};
    margin-bottom: 2px;
    padding: 5px;
  }
`;

const getStringArray = (json: string): string[] => {
  try {
    return JSON.parse(json);
  } catch (e) {
    return [json];
  }
};

type Props = {
  history?: AITestResultModel[];
  onReuseUserPrompt: (prompt: string) => void;
  onReuseSystemPrompt: (prompt: string) => void;
};
const AITestHistory: React.FC<Props> = ({
  history,
  onReuseUserPrompt,
  onReuseSystemPrompt,
}) => {
  const users = useUsers();
  return (
    <Wrapper>
      <h1>AI Test History</h1>
      {history?.map((result) => (
        <ResultWrapper key={result.id}>
          <div>
            <GenericFileViewer
              fileGuid={result.fileGuid}
              fileEnding={result.fileEnding}
              container={containers.aiTestResults}
              maxHeight={100}
            />
          </div>
          <div>
            <h2>
              {formatDateTime(result.created)} -{' '}
              {users[result.createdByID]?.name ?? result.createdByID}
            </h2>
            <h3>Prompt</h3>
            <JSONDataDiv>
              <ol>
                {getStringArray(result.prompt).map((s) => (
                  <li key={s}>{s}</li>
                ))}
              </ol>
              <Button
                onClick={() =>
                  onReuseSystemPrompt(getStringArray(result.prompt)[0])
                }
              >
                Återanvänd SystemPrompt
              </Button>
              <Button
                onClick={() =>
                  onReuseUserPrompt(getStringArray(result.prompt)[1])
                }
              >
                Återanvänd UserPrompt
              </Button>
            </JSONDataDiv>
            <h3>Result</h3>
            <JSONDataDiv>{result.result}</JSONDataDiv>
          </div>
        </ResultWrapper>
      ))}
    </Wrapper>
  );
};

export default AITestHistory;
