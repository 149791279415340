/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AbsenceReason,
  AbsenceStatus,
  AssignmentDeviationEnum,
  IAvailabilityDto,
  ITranslationLookups,
  UpdateUserAvailabilityRequest,
  UserClient,
} from 'api';
import DatePicker from 'components/inputs/DatePicker';
import TextArea from 'components/inputs/TextArea';
import Modal from 'components/Modal';
import LoadingSpinner from 'components/spinners/LoadingSpinner';
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { RequestStatus, useApiCall, useResponse } from 'swaggerhooks/lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCarAlt,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faExternalLinkAlt,
  faHand,
  faInfoCircle,
  faPlus,
  faTimes,
  faTrash,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import AuthorizedButton from 'components/inputs/AuthorizedButton';
import {
  formatDate,
  formatDateTime,
  formatTime,
  getHoursAndMinutesFromMillisecondsString,
  toDateFromHHmm,
} from 'utils/date-helpers';
import TextButton from 'components/inputs/TextButton';
import Routes from 'constants/Routes';
import useTranslations from 'contexts/basicData/useTranslations';
import { lightGreen, lightGrey, lightRed } from 'constants/Colors';
import Checkbox from 'components/inputs/Checkbox';
import AbsenceReasonIcon from './AbsenceReasonIcon';
import SearchableSelect, {
  SearchableSelectItem,
  SelectOption,
} from 'components/inputs/SearchableSelect';
import useModalStack from 'contexts/modal/useModalStack';
import useUsers from 'contexts/basicData/useUsers';
import { useDrivingPolicyGetAssignedTimesForUser } from 'api/driving-policy/driving-policy';
import AssignedTimesTimeline from 'components/AssignedTimesTimeline';
import Table from 'components/Table';
import { ProgressBar, ProgressContainer, RowCss } from '../DrivingPolicy';
import { ColumnSetting } from 'components/Table/utils';
import {
  AssignedTimeDto,
  AssignmentModel,
  AssignmentRequestModel,
  AssignmentStatusEnum,
  AssignmentTypeEnum,
  AssignmentViewModel,
  AvailabilityDto,
  CaseExtendedModel,
  CaseModel,
  CaseStatusEnum,
  CaseTypeEnum,
} from 'api/model';
import Button from 'components/inputs/Button';
import { useAssignmentGetBookedAssignmentsForUserAndDate } from 'api/assignment/assignment';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import ContextWrappedCreateCaseModal from 'components/CreateCaseModal';
import { IAddress } from 'utils/address';
import Address from './Address';
import { useAssignmentRequestsGetAssignmentRequestsForUserAndDate } from 'api/assignment-requests/assignment-requests';
import useCompanies from 'contexts/basicData/useCompanies';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import WorkTimeWidget from './WorkTimeWidget';
import {
  useUserDeleteAvailability,
  useUserGetEmployeeAvailabilityForDate,
  useUserUpdateUserAvailability,
} from 'api/user/user';
import { showException } from 'utils/exception-helper';

const Container = styled.div`
  display: flex;
  gap: 20px;
  padding-bottom: 15px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  gap: 20px;
  padding: 10px;
  padding-right: 0px;
`;

const DrivingTimesContainer = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colors.border.primary};
  display: flex;
  flex-direction: column;
  align-self: flex-end;
`;

const TimeLineContainer = styled.div`
  max-width: 700px;
  min-width: 700px;
  /* max-height: 400px; */
  overflow-x: auto;
  overflow-y: auto;
`;

const BoldSpan = styled.span`
  font-weight: bold;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 10px;
  user-select: none;
  align-items: center;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  gap: 10px;
`;

const InputButton = styled.button`
  width: 120px;
  height: 35px;
  text-align: center;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 4px;
  border: none;
  border-radius: 3px;
`;

const Label = styled.div`
  margin-bottom: 5px;
  min-height: 1em;
  padding-left: 3px;
`;

const Title = styled.h2`
  margin-top: 15px;
  font-size: ${({ theme }) => theme.sizes.font.medium};

  &:first-of-type {
    margin-top: 0;
  }
`;

const TableRowCellWraper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
`;

const AbsenceButton = styled(AuthorizedButton)<{
  selected?: boolean;
  colorSelected?: string;
  colorDeselected?: string;
}>`
  flex: 1;
  margin-left: 0;
  margin-right: auto;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.foreground.primary};
  ${({ selected, colorSelected, colorDeselected, theme }) =>
    selected
      ? css`
          background: ${colorSelected || theme.colors.background.selection};
        `
      : css`
          background: ${colorDeselected || theme.colors.background.button};
        `}
`;

const CustomInput = forwardRef((props: any, ref) => (
  <InputButton {...props} ref={ref} style={{ cursor: 'pointer' }} type="button">
    {props.value}
  </InputButton>
));

const generateDefaultValues = (availability?: AvailabilityDto) => {
  if (!availability) return {};
  return {
    // user: availability.user,
    userID: availability.userID,
    // date: availability.date,
    dateString: availability.dateString,
    startString:
      availability.absenceReason !== null
        ? undefined
        : (availability.startString ?? availability.originalTimes?.startString),
    endString:
      availability.absenceReason !== null
        ? undefined
        : (availability.endString ?? availability.originalTimes?.endString),
    absenceStatus: availability.absenceStatus ?? undefined,
    absenceReason: availability.absenceReason ?? undefined,
    comment: availability.comment,
    adminComment: availability.adminComment,
    isFullyBooked: availability.isFullyBooked ?? false,
    scheduleStatus: availability.scheduleStatus,
    isOnlyAvailableForFurtherTransport:
      availability.isOnlyAvailableForFurtherTransport ?? false,
  };
};

type AvailabilityFormModalProps = {
  viewOnly?: boolean;
  name: string;
  userId: number;
  availability?: AvailabilityDto;
  showUserFields?: boolean;
  initialDate?: Date;
  onClose?(): void;
  onSaved?(): void;
};

const AvailabilityFormModal = ({
  name,
  userId,
  availability: propsAvailability,
  onClose,
  onSaved,
  showUserFields = true,
  viewOnly,
  initialDate,
}: AvailabilityFormModalProps) => {
  const users = useUsers();
  const modalStack = useModalStack();
  const { absenceReasons, absenceStatuses } = useTranslations();

  const [currentDate, setCurrentDate] = useState<Date>(
    initialDate ??
      (propsAvailability?.date ? new Date(propsAvailability.date) : new Date())
  );

  const tomorrow = useMemo(
    () =>
      new Date(
        new Date(currentDate).setDate(new Date(currentDate).getDate() + 1)
      ),
    [currentDate]
  );

  const availabilityCall = useUserGetEmployeeAvailabilityForDate(
    {
      userId,
      date: currentDate,
    },
    {
      query: {
        enabled: userId !== undefined,
      },
    }
  );

  const availability = availabilityCall.data ?? propsAvailability;

  const goToPreviousDate = () => {
    setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() - 1)));
  };

  const goToNextDate = () => {
    setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() + 1)));
  };

  const { data: assignedTimes } = useDrivingPolicyGetAssignedTimesForUser(
    { userId, limit: 5, beforeTime: tomorrow }
    // { query: { enabled: false } }
  );

  const { register, handleSubmit, setValue, watch } = useForm<IAvailabilityDto>(
    {
      defaultValues: generateDefaultValues(availability),
    }
  );
  const startInputRef = useRef(null);
  const endInputRef = useRef(null);

  const absenceReasonOptions = Object.entries(absenceReasons)
    .filter(([reason]) => Number(reason) !== AbsenceReason.OTHER)
    .map(([reason, reasonName]) => ({
      key: reason,
      value: Number(reason),
      label: (
        <Row>
          <AbsenceReasonIcon absenceReason={Number(reason)} />
          {reasonName}
        </Row>
      ),
    }));

  const updateCall = useUserUpdateUserAvailability();
  // const updateCall = useApiCall(
  //   UserClient,
  //   (c, a: AvailabilityDto, id: number) =>
  //     c.updateUserAvailability({
  //       availability: a,
  //       userId: id,
  //     } as UpdateUserAvailabilityRequest)
  // );

  const removeCall = useUserDeleteAvailability();
  // const removeCall = useApiCall(UserClient, (c, id: number) =>
  //   c.deleteAvailability(id)
  // );

  const handleSaveClick = handleSubmit(async (data) => {
    if (!data) return;

    // TODO: Fix this in the backend?
    // if (process.env.NODE_ENV !== 'development') {
    //   data.date.setHours(
    //     data.date.getHours() +
    //       (data.date.getTimezoneOffset() * data.date.getTimezoneOffset()) / 3600
    //   );

    //   if (data.start) {
    //     // convert data.start to UTC
    //     data.start.setUTCHours(data.start.getHours());
    //   }
    //   if (data.end) {
    //     // convert data.end to UTC
    //     data.end.setUTCHours(data.end.getHours());
    //   }
    // }

    try {
      await updateCall.mutateAsync({
        data: {
          availability: data,
          userId,
        },
      });
      onSaved?.();
      modalStack.pop();
    } catch (error) {
      showException(error);
    }
  });

  const removeAvailability = async () => {
    try {
      await removeCall.mutateAsync({
        params: {
          availabilityId: availability?.id,
        },
      });
      onSaved?.();
      modalStack.pop();
    } catch (error) {
      showException(error);
    }
  };

  const handleCloseClick = () => {
    onClose?.();
  };

  const handleRemoveClick = async () => {
    modalStack.push(
      <Modal
        buttons={[
          {
            label: 'Ja',
            onClick: removeAvailability,
          },
          {
            label: 'Nej',
            onClick: () => modalStack.pop(),
          },
        ]}
        onClose={() => modalStack.pop()}
        title="Ta bort tillgänglighet"
      >
        <p>Är du säker på att du vill ta bort tillgängligheten?</p>
      </Modal>
    );
  };

  const renderAbsenceReasonOption = (
    key: string,
    option: SelectOption<number | undefined, AbsenceReason | undefined>,
    onClick: () => void
  ) => (
    <SearchableSelectItem key={key} onClick={onClick}>
      {option.label}
    </SearchableSelectItem>
  );

  const getColorForHoursDrivenLast24h = (hours: number) => {
    if (hours >= 0 && hours < 9) {
      return 'green';
    }
    if (hours >= 0 && hours < 13) {
      return 'rgb(255, 123, 0)';
    }
    return 'red';
  };

  const columnSettings: ColumnSetting<AssignedTimeDto, ITranslationLookups>[] =
    useMemo(() => {
      const columns: ColumnSetting<AssignedTimeDto, ITranslationLookups>[] = [
        {
          head: 'Uppdrag',
          css: RowCss,
          render: (as) => (
            <TableRowCellWraper>
              <b>{as.registrationNumber}</b>
              <span>{as.assignmentId}</span>
            </TableRowCellWraper>
          ),
          width: 80,
        },
        {
          head: 'Start- och sluttid',
          css: RowCss,
          render: (as) => (
            <TableRowCellWraper>
              {' '}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  fontWeight: 'bold',
                }}
              >
                <div>{formatTime(as.start)}</div>
                <div>{formatTime(as.end)}</div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <div>{formatDate(as.start)}</div>
                <div>{formatDate(as.end)}</div>
              </div>
            </TableRowCellWraper>
          ),
          width: 180,
        },
        {
          head: (
            <>
              <span>Körtid 24h</span>
              <FontAwesomeIcon
                icon={faInfoCircle}
                title="Beräknad körtid för uppdraget och tidigare
              uppdrag för 24h bakåt utifrån uppdragets sluttid."
              />
            </>
          ),
          css: RowCss,
          render: (as) => (
            <span>
              {as.report.hoursDrivenLast24h ? (
                <b
                  style={{
                    color: getColorForHoursDrivenLast24h(
                      as.report.hoursDrivenLast24h
                    ),
                  }}
                >
                  {getHoursAndMinutesFromMillisecondsString(
                    as.report.hoursDrivenLast24h * 60 * 60 * 1000
                  )}
                </b>
              ) : (
                '-'
              )}
            </span>
          ),
          width: 100,
        },
        {
          head: (
            <>
              <span>Arb. tid idag</span>
              <FontAwesomeIcon
                icon={faInfoCircle}
                title="Arbetad i kalendertid från första uppdraget innevarande dag fram till och med detta uppdrag."
              />
            </>
          ),
          css: RowCss,
          render: (as) => (
            <span>
              {as.report.workHoursToday ? (
                <b
                  style={{
                    color: getColorForHoursDrivenLast24h(
                      as.report.workHoursToday
                    ),
                  }}
                >
                  {getHoursAndMinutesFromMillisecondsString(
                    as.report.workHoursToday * 60 * 60 * 1000
                  )}
                </b>
              ) : (
                '-'
              )}
            </span>
          ),
          width: 100,
        },
        {
          head: (
            <span>
              Dygnsvila
              <FontAwesomeIcon
                icon={faInfoCircle}
                title="Status för om uppdraget håller sig inom tillåten samanhängande dygnsvila/arbetstidslängd."
              />
            </span>
          ),
          css: RowCss,
          render: (as) => (
            <FontAwesomeIcon
              icon={
                as.report.hasConsecutiveHoursOfRestLast24Hours
                  ? faCheck
                  : faWarning
              }
              style={{
                color: as.report.hasConsecutiveHoursOfRestLast24Hours
                  ? 'green'
                  : 'red',
              }}
              title={
                as.report.hasConsecutiveHoursOfRestLast24Hours
                  ? ''
                  : 'För lite sammanhängande dygnsvila eller för lång arbetsdag'
              }
            />
          ),
          width: 90,
        },
        {
          head: (
            <>
              <span style={{ marginRight: '5px' }}>Innevarande vecka</span>
              <FontAwesomeIcon
                icon={faInfoCircle}
                title="Beräknad körtid för veckan uppdraget körs, aktuella veckan utgår ifrån uppdragets sluttid."
              />
            </>
          ),
          css: RowCss,
          render: (as) => {
            const value = Math.max(as.report?.hoursDrivenThisWeek || 0, 0);
            return as.report?.hoursDrivenThisWeek ? (
              <ProgressContainer>
                <ProgressBar
                  widthPercent={
                    value ? (value / 0.48 >= 100 ? 100 : value / 0.48) : 0
                  }
                >
                  {getHoursAndMinutesFromMillisecondsString(
                    value * 1000 * 60 * 60
                  )}
                </ProgressBar>
              </ProgressContainer>
            ) : (
              '-'
            );
          },
          width: 200,
        },
        {
          head: (
            <>
              <span>De senaste 28 dagarna</span>
              <FontAwesomeIcon
                icon={faInfoCircle}
                title="Beräknad körtid för uppdraget och tidigare
                  uppdrag för 28 dygn bakåt utifrån uppdragets sluttid."
              />
            </>
          ),
          css: RowCss,
          render: (as) => {
            const value = Math.max(as.report?.hoursDrivenLast28Days || 0, 0);
            return as.report?.hoursDrivenLast28Days ? (
              <ProgressContainer>
                <ProgressBar
                  widthPercent={
                    value ? (value / 1.92 >= 100 ? 100 : value / 1.92) : 0
                  }
                >
                  {getHoursAndMinutesFromMillisecondsString(
                    value * 1000 * 60 * 60
                  )}
                </ProgressBar>
              </ProgressContainer>
            ) : (
              '-'
            );
          },

          width: 200,
        },
      ];
      return columns;
    }, []);

  const renderContent = () => {
    switch (updateCall.status) {
      case 'idle':
      case 'success':
      case 'error':
        return (
          <>
            <Container>
              <FormContainer>
                <Col>
                  <Title>
                    <TextButton hoverAnimation onClick={goToPreviousDate}>
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </TextButton>
                    {availability?.dateString}
                    <TextButton hoverAnimation onClick={goToNextDate}>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </TextButton>
                  </Title>
                  {showUserFields && (
                    <>
                      <Row>
                        <Col>
                          <Label>Från</Label>
                          <DatePicker
                            customInput={
                              <CustomInput inputRef={startInputRef} />
                            }
                            dateFormat="HH:mm"
                            disabled={viewOnly}
                            locale="sv"
                            maxTime={new Date(0, 0, 0, 23, 59, 0)}
                            minTime={new Date(0, 0, 0, 0, 0, 0)}
                            onChange={(date) => {
                              if (date) {
                                setValue('startString', formatTime(date));
                              } else {
                                setValue('startString', undefined);
                              }
                            }}
                            selected={
                              watch('startString')
                                ? toDateFromHHmm(watch('startString')!)
                                : undefined
                            }
                            shouldCloseOnSelect
                            showTimeSelect
                            showTimeSelectOnly
                            timeCaption="Börjar"
                            timeFormat="HH:mm"
                            timeIntervals={15}
                          />
                        </Col>

                        <Col>
                          <Label>Till</Label>
                          <DatePicker
                            customInput={<CustomInput inputRef={endInputRef} />}
                            dateFormat="HH:mm"
                            disabled={viewOnly}
                            locale="sv"
                            maxTime={new Date(0, 0, 0, 23, 59, 0)}
                            minTime={new Date(0, 0, 0, 0, 0, 0)}
                            onChange={(date) => {
                              if (date) {
                                setValue('endString', formatTime(date));
                              } else {
                                setValue('endString', undefined);
                              }
                            }}
                            selected={
                              watch('endString')
                                ? toDateFromHHmm(watch('endString')!)
                                : undefined
                            }
                            shouldCloseOnSelect
                            showTimeSelect
                            showTimeSelectOnly
                            timeCaption="Slutar"
                            timeFormat="HH:mm"
                            timeIntervals={15}
                          />
                        </Col>
                      </Row>
                      {!viewOnly && (
                        <Row>
                          <Checkbox
                            checked={watch('absenceStatus') !== undefined}
                            disabled={viewOnly}
                            onChange={(e) => {
                              if (e.currentTarget.checked) {
                                setValue('startString', undefined);
                                setValue('endString', undefined);
                                setValue(
                                  'absenceStatus',
                                  AbsenceStatus.REQUESTED
                                );
                              } else {
                                setValue(
                                  'startString',
                                  availability?.originalTimes?.startString
                                );
                                setValue(
                                  'endString',
                                  availability?.originalTimes?.endString
                                );
                                setValue('absenceStatus', undefined);
                                setValue('absenceStatus', undefined);
                                setValue('absenceReason', undefined);
                              }
                            }}
                          >
                            Ej tillgänglig
                          </Checkbox>
                        </Row>
                      )}
                    </>
                  )}
                </Col>
                {watch('absenceStatus') !== undefined && (
                  <Col>
                    {watch('comment') && (
                      <Row>
                        <Col>{`Fälttestarens kommentar: ${watch('comment')}`}</Col>
                      </Row>
                    )}
                    <Row>
                      <BoldSpan style={{ fontWeight: 'bold' }}>
                        Frånvaro begärd för:
                      </BoldSpan>{' '}
                      <SearchableSelect
                        onOptionClicked={(value) => {
                          setValue('absenceReason', value);
                        }}
                        optionRender={renderAbsenceReasonOption}
                        options={absenceReasonOptions}
                        searchFilter={(str, ops) => ops}
                        selectedValue={watch('absenceReason')}
                      />
                    </Row>
                    <TextArea
                      {...register('adminComment')}
                      placeholder="Admin-kommentar"
                      style={{ width: 'auto', minHeight: 100 }}
                    />
                    {!viewOnly && (
                      <Row>
                        <AbsenceButton
                          colorDeselected={lightGrey}
                          colorSelected={lightRed}
                          icon={
                            (watch('absenceStatus') ===
                              AbsenceStatus.DECLINED && (
                              <FontAwesomeIcon icon={faTimes} />
                            )) ||
                            undefined
                          }
                          onClick={() => {
                            setValue('absenceStatus', AbsenceStatus.DECLINED);
                          }}
                          selected={
                            watch('absenceStatus') === AbsenceStatus.DECLINED
                          }
                        >
                          {absenceStatuses[AbsenceStatus.DECLINED]}
                        </AbsenceButton>
                        <AbsenceButton
                          colorDeselected={lightGrey}
                          colorSelected={lightGreen}
                          icon={
                            (watch('absenceStatus') ===
                              AbsenceStatus.APPROVED && (
                              <FontAwesomeIcon icon={faCheck} />
                            )) ||
                            undefined
                          }
                          onClick={() => {
                            setValue('absenceStatus', AbsenceStatus.APPROVED);
                          }}
                          selected={
                            watch('absenceStatus') === AbsenceStatus.APPROVED
                          }
                        >
                          {absenceStatuses[AbsenceStatus.APPROVED]}
                        </AbsenceButton>
                      </Row>
                    )}
                    {availability?.absenceStatus !== undefined &&
                      availability.absenceStatus !== null &&
                      availability.absenceApprovedOrDeclinedByID !==
                        undefined &&
                      availability.absenceApprovedOrDeclinedAt && (
                        <Row>
                          {`${absenceStatuses[availability.absenceStatus]} av
                      ${
                        users[availability.absenceApprovedOrDeclinedByID]
                          ?.name ?? '-'
                      }
                      ${
                        typeof availability.absenceApprovedOrDeclinedAt ===
                        'string'
                          ? formatDateTime(
                              new Date(availability.absenceApprovedOrDeclinedAt)
                            )
                          : ''
                      }`}
                        </Row>
                      )}
                  </Col>
                )}
                <Col>
                  <Title>Begränsa bokningar</Title>
                  <Checkbox
                    checked={watch('isFullyBooked')}
                    disabled={viewOnly}
                    onChange={(ev) => {
                      setValue('isFullyBooked', ev.currentTarget.checked);
                    }}
                  >
                    Markera som fullbokad
                  </Checkbox>
                  <Checkbox
                    checked={watch('isOnlyAvailableForFurtherTransport')}
                    disabled={viewOnly}
                    onChange={(ev) => {
                      setValue(
                        'isOnlyAvailableForFurtherTransport',
                        ev.currentTarget.checked
                      );
                    }}
                  >
                    Kan endast bokas på vidaretransport
                  </Checkbox>
                </Col>
                <Row />
                <Row>
                  {!viewOnly && (
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-evenly',
                      }}
                    >
                      <Button
                        onClick={handleRemoveClick}
                        style={{ display: 'flex', gap: '5px' }}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                        Ta bort
                      </Button>
                      <Button onClick={handleSaveClick}>Spara</Button>
                    </div>
                  )}
                </Row>
                {availability && (
                  <WorkTimeWidget availability={availability} userId={userId} />
                )}
              </FormContainer>
              <DrivingTimesContainer>
                <TimeLineContainer>
                  {assignedTimes && (
                    <AssignedTimesTimeline assignedTimes={assignedTimes} />
                  )}
                </TimeLineContainer>
              </DrivingTimesContainer>
            </Container>
            {assignedTimes && (
              <>
                <Title style={{ marginBottom: '5px' }}>
                  Körtidspolicy-status per uppdrag
                </Title>
                <Table
                  columnSettings={columnSettings}
                  rows={assignedTimes
                    .filter((at) => at.report)
                    .sort((a, b) => {
                      if (a.start < b.start) return 1;
                      if (a.start > b.start) return -1;
                      return 0;
                    })}
                />
              </>
            )}
          </>
        );

      case 'loading':
      default:
        return <LoadingSpinner>Sparar...</LoadingSpinner>;
    }
  };

  return (
    <Modal
      buttons={
        viewOnly || updateCall.status === 'loading'
          ? []
          : [
              {
                label: 'Stäng',
                onClick: handleCloseClick,
              },
            ]
      }
      onClose={handleCloseClick}
      title={`Tillgänglighet för ${name}`}
    >
      {renderContent()}
    </Modal>
  );
};

export default AvailabilityFormModal;
