/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */
import { useQuery } from '@tanstack/react-query';
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  AssignmentDTO,
  FieldTesterIncompleteDayPlanDTO,
  StandbyFTDTO,
  TransportLogisticsGetAssignmentsWithMissingExpectedExpensesParams,
  TransportLogisticsGetFieldtestersWithOutsideHomeCountyParams,
  TransportLogisticsGetPlaceholderCaseAssignmentsParams,
  TransportLogisticsGetStandbyFTDTOsParams,
} from '.././model';
import useTransportLogisticsGetStandbyFTDTOsMutator from '.././mutator/custom-instance';
import useTransportLogisticsGetPlaceholderCaseAssignmentsMutator from '.././mutator/custom-instance';
import useTransportLogisticsGetAssignmentsWithMissingExpectedExpensesMutator from '.././mutator/custom-instance';
import useTransportLogisticsGetFieldtestersWithOutsideHomeCountyMutator from '.././mutator/custom-instance';

export const useTransportLogisticsGetStandbyFTDTOsHook = () => {
  const transportLogisticsGetStandbyFTDTOs =
    useTransportLogisticsGetStandbyFTDTOsMutator<StandbyFTDTO[]>();

  return (
    params?: TransportLogisticsGetStandbyFTDTOsParams,
    signal?: AbortSignal
  ) => {
    return transportLogisticsGetStandbyFTDTOs({
      url: `/api/TransportLogistics/GetStandbyFTDTOs`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getTransportLogisticsGetStandbyFTDTOsQueryKey = (
  params?: TransportLogisticsGetStandbyFTDTOsParams
) => {
  return [
    `/api/TransportLogistics/GetStandbyFTDTOs`,
    ...(params ? [params] : []),
  ] as const;
};

export const useTransportLogisticsGetStandbyFTDTOsQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useTransportLogisticsGetStandbyFTDTOsHook>>
  >,
  TError = unknown,
>(
  params?: TransportLogisticsGetStandbyFTDTOsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useTransportLogisticsGetStandbyFTDTOsHook>>
      >,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getTransportLogisticsGetStandbyFTDTOsQueryKey(params);

  const transportLogisticsGetStandbyFTDTOs =
    useTransportLogisticsGetStandbyFTDTOsHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<ReturnType<typeof useTransportLogisticsGetStandbyFTDTOsHook>>
    >
  > = ({ signal }) => transportLogisticsGetStandbyFTDTOs(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useTransportLogisticsGetStandbyFTDTOsHook>>
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TransportLogisticsGetStandbyFTDTOsQueryResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useTransportLogisticsGetStandbyFTDTOsHook>>
  >
>;
export type TransportLogisticsGetStandbyFTDTOsQueryError = unknown;

export const useTransportLogisticsGetStandbyFTDTOs = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useTransportLogisticsGetStandbyFTDTOsHook>>
  >,
  TError = unknown,
>(
  params?: TransportLogisticsGetStandbyFTDTOsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useTransportLogisticsGetStandbyFTDTOsHook>>
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useTransportLogisticsGetStandbyFTDTOsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useTransportLogisticsGetPlaceholderCaseAssignmentsHook = () => {
  const transportLogisticsGetPlaceholderCaseAssignments =
    useTransportLogisticsGetPlaceholderCaseAssignmentsMutator<
      AssignmentDTO[]
    >();

  return (
    params?: TransportLogisticsGetPlaceholderCaseAssignmentsParams,
    signal?: AbortSignal
  ) => {
    return transportLogisticsGetPlaceholderCaseAssignments({
      url: `/api/TransportLogistics/GetPlaceholderCaseAssignments`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getTransportLogisticsGetPlaceholderCaseAssignmentsQueryKey = (
  params?: TransportLogisticsGetPlaceholderCaseAssignmentsParams
) => {
  return [
    `/api/TransportLogistics/GetPlaceholderCaseAssignments`,
    ...(params ? [params] : []),
  ] as const;
};

export const useTransportLogisticsGetPlaceholderCaseAssignmentsQueryOptions = <
  TData = Awaited<
    ReturnType<
      ReturnType<typeof useTransportLogisticsGetPlaceholderCaseAssignmentsHook>
    >
  >,
  TError = unknown,
>(
  params?: TransportLogisticsGetPlaceholderCaseAssignmentsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useTransportLogisticsGetPlaceholderCaseAssignmentsHook
          >
        >
      >,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getTransportLogisticsGetPlaceholderCaseAssignmentsQueryKey(params);

  const transportLogisticsGetPlaceholderCaseAssignments =
    useTransportLogisticsGetPlaceholderCaseAssignmentsHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useTransportLogisticsGetPlaceholderCaseAssignmentsHook
        >
      >
    >
  > = ({ signal }) =>
    transportLogisticsGetPlaceholderCaseAssignments(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useTransportLogisticsGetPlaceholderCaseAssignmentsHook
        >
      >
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TransportLogisticsGetPlaceholderCaseAssignmentsQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useTransportLogisticsGetPlaceholderCaseAssignmentsHook
        >
      >
    >
  >;
export type TransportLogisticsGetPlaceholderCaseAssignmentsQueryError = unknown;

export const useTransportLogisticsGetPlaceholderCaseAssignments = <
  TData = Awaited<
    ReturnType<
      ReturnType<typeof useTransportLogisticsGetPlaceholderCaseAssignmentsHook>
    >
  >,
  TError = unknown,
>(
  params?: TransportLogisticsGetPlaceholderCaseAssignmentsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useTransportLogisticsGetPlaceholderCaseAssignmentsHook
          >
        >
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    useTransportLogisticsGetPlaceholderCaseAssignmentsQueryOptions(
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useTransportLogisticsGetAssignmentsWithMissingExpectedExpensesHook =
  () => {
    const transportLogisticsGetAssignmentsWithMissingExpectedExpenses =
      useTransportLogisticsGetAssignmentsWithMissingExpectedExpensesMutator<
        AssignmentDTO[]
      >();

    return (
      params?: TransportLogisticsGetAssignmentsWithMissingExpectedExpensesParams,
      signal?: AbortSignal
    ) => {
      return transportLogisticsGetAssignmentsWithMissingExpectedExpenses({
        url: `/api/TransportLogistics/GetAssignmentsWithMissingExpectedExpenses`,
        method: 'GET',
        params,
        signal,
      });
    };
  };

export const getTransportLogisticsGetAssignmentsWithMissingExpectedExpensesQueryKey =
  (
    params?: TransportLogisticsGetAssignmentsWithMissingExpectedExpensesParams
  ) => {
    return [
      `/api/TransportLogistics/GetAssignmentsWithMissingExpectedExpenses`,
      ...(params ? [params] : []),
    ] as const;
  };

export const useTransportLogisticsGetAssignmentsWithMissingExpectedExpensesQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        ReturnType<
          typeof useTransportLogisticsGetAssignmentsWithMissingExpectedExpensesHook
        >
      >
    >,
    TError = unknown,
  >(
    params?: TransportLogisticsGetAssignmentsWithMissingExpectedExpensesParams,
    options?: {
      query?: UseQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<
              typeof useTransportLogisticsGetAssignmentsWithMissingExpectedExpensesHook
            >
          >
        >,
        TError,
        TData
      >;
    }
  ) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getTransportLogisticsGetAssignmentsWithMissingExpectedExpensesQueryKey(
        params
      );

    const transportLogisticsGetAssignmentsWithMissingExpectedExpenses =
      useTransportLogisticsGetAssignmentsWithMissingExpectedExpensesHook();

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useTransportLogisticsGetAssignmentsWithMissingExpectedExpensesHook
          >
        >
      >
    > = ({ signal }) =>
      transportLogisticsGetAssignmentsWithMissingExpectedExpenses(
        params,
        signal
      );

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useTransportLogisticsGetAssignmentsWithMissingExpectedExpensesHook
          >
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type TransportLogisticsGetAssignmentsWithMissingExpectedExpensesQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useTransportLogisticsGetAssignmentsWithMissingExpectedExpensesHook
        >
      >
    >
  >;
export type TransportLogisticsGetAssignmentsWithMissingExpectedExpensesQueryError =
  unknown;

export const useTransportLogisticsGetAssignmentsWithMissingExpectedExpenses = <
  TData = Awaited<
    ReturnType<
      ReturnType<
        typeof useTransportLogisticsGetAssignmentsWithMissingExpectedExpensesHook
      >
    >
  >,
  TError = unknown,
>(
  params?: TransportLogisticsGetAssignmentsWithMissingExpectedExpensesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useTransportLogisticsGetAssignmentsWithMissingExpectedExpensesHook
          >
        >
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    useTransportLogisticsGetAssignmentsWithMissingExpectedExpensesQueryOptions(
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useTransportLogisticsGetFieldtestersWithOutsideHomeCountyHook =
  () => {
    const transportLogisticsGetFieldtestersWithOutsideHomeCounty =
      useTransportLogisticsGetFieldtestersWithOutsideHomeCountyMutator<
        FieldTesterIncompleteDayPlanDTO[]
      >();

    return (
      params?: TransportLogisticsGetFieldtestersWithOutsideHomeCountyParams,
      signal?: AbortSignal
    ) => {
      return transportLogisticsGetFieldtestersWithOutsideHomeCounty({
        url: `/api/TransportLogistics/GetFieldtestersWithOutsideHomeCounty`,
        method: 'GET',
        params,
        signal,
      });
    };
  };

export const getTransportLogisticsGetFieldtestersWithOutsideHomeCountyQueryKey =
  (params?: TransportLogisticsGetFieldtestersWithOutsideHomeCountyParams) => {
    return [
      `/api/TransportLogistics/GetFieldtestersWithOutsideHomeCounty`,
      ...(params ? [params] : []),
    ] as const;
  };

export const useTransportLogisticsGetFieldtestersWithOutsideHomeCountyQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        ReturnType<
          typeof useTransportLogisticsGetFieldtestersWithOutsideHomeCountyHook
        >
      >
    >,
    TError = unknown,
  >(
    params?: TransportLogisticsGetFieldtestersWithOutsideHomeCountyParams,
    options?: {
      query?: UseQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<
              typeof useTransportLogisticsGetFieldtestersWithOutsideHomeCountyHook
            >
          >
        >,
        TError,
        TData
      >;
    }
  ) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getTransportLogisticsGetFieldtestersWithOutsideHomeCountyQueryKey(params);

    const transportLogisticsGetFieldtestersWithOutsideHomeCounty =
      useTransportLogisticsGetFieldtestersWithOutsideHomeCountyHook();

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useTransportLogisticsGetFieldtestersWithOutsideHomeCountyHook
          >
        >
      >
    > = ({ signal }) =>
      transportLogisticsGetFieldtestersWithOutsideHomeCounty(params, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useTransportLogisticsGetFieldtestersWithOutsideHomeCountyHook
          >
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type TransportLogisticsGetFieldtestersWithOutsideHomeCountyQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useTransportLogisticsGetFieldtestersWithOutsideHomeCountyHook
        >
      >
    >
  >;
export type TransportLogisticsGetFieldtestersWithOutsideHomeCountyQueryError =
  unknown;

export const useTransportLogisticsGetFieldtestersWithOutsideHomeCounty = <
  TData = Awaited<
    ReturnType<
      ReturnType<
        typeof useTransportLogisticsGetFieldtestersWithOutsideHomeCountyHook
      >
    >
  >,
  TError = unknown,
>(
  params?: TransportLogisticsGetFieldtestersWithOutsideHomeCountyParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useTransportLogisticsGetFieldtestersWithOutsideHomeCountyHook
          >
        >
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    useTransportLogisticsGetFieldtestersWithOutsideHomeCountyQueryOptions(
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
