// gets the media type from a base64 string, e.g. data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD -> image/jpeg
const getMediaTypeFromB64 = (b64: string) => {
  const match = b64.match(/^data:(.*?);base64/);
  if (match) {
    return match[1];
  }
  return null;
};

// eslint-disable-next-line import/prefer-default-export
export { getMediaTypeFromB64 };
