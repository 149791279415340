import {
  useAiGetLatestAITestResultModels,
  useAiGetSystemPrompt,
  useAiGetUserPrompt,
  useAiTestAnalyzeImageForWorkGearB64,
  useAiVerifyWorkGearIsInPhotoB64,
} from 'api/ai/ai';
import {
  useFileFindFileUploadsByCase,
  useFileGetFileHook,
} from 'api/file/file';
import {
  CaseEventType,
  VerifyWorkGearIsInPhotoB64Request,
  WorkGearType,
} from 'api/model';
import { GenericFileViewer } from 'components/Files/FileViewer';
import Button from 'components/inputs/Button';
import Checkbox from 'components/inputs/Checkbox';
import Input from 'components/inputs/Input';
import LabelWrap from 'components/inputs/LabelWrap';
import useTranslations from 'contexts/basicData/useTranslations';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useDebounceCallback } from 'usehooks-ts';
import { showException } from 'utils/exception-helper';
import { getMediaTypeFromB64 } from 'utils/image-utils';
import AITestHistory from './AITestHistory';
import { useQueryClient } from '@tanstack/react-query';
import AutoSizedTextArea from 'components/inputs/AutoSizedTextArea';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  align-items: flex-start;
  gap: 20px;
  padding: 20px;
  textarea {
    width: 500px;
    height: 600px;
  }

  pre {
    max-width: 90vw;
    overflow: auto;
    white-space: pre;
    resize: both;
    border: 1px solid ${({ theme }) => theme.colors.border.primary};
  }
`;

const TransparentButton = styled(Button)`
  width: 100%;
  background-color: transparent;
`;

const Horizontal = styled.div`
  display: flex;
  gap: 20px;
`;

const Vertical = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

// const ImageWrapper = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: center;
// `;

type AIPromtData = {
  mediaTye: string;
  b64: string;
  width: number;
  height: number;
};

const WorkGearTypeOptions = Object.values(WorkGearType).map((value) => ({
  label: value,
  value,
}));

const TestAIPromptPage: FC = () => {
  const queryClient = useQueryClient();

  const userPromptCall = useAiGetUserPrompt();
  const systemPromptCall = useAiGetSystemPrompt();
  const aiHistoryCall = useAiGetLatestAITestResultModels();

  const { workGearTypes: workGearTypesTranslations } = useTranslations();
  const [caseIdOrRegNr, setCaseIdOrRegNr] = useState<string>('');
  const [currentSystemPrompt, setCurrentSystemPrompt] = useState<string>('');
  const [currentUserPrompt, setCurrentUserPrompt] = useState<string>('');
  const [uploadedImage, setUploadedImage] = useState<AIPromtData>();
  const [selectedImageB64, setSelectedImageB64] = useState<string>('');
  const { watch, setValue } = useForm<VerifyWorkGearIsInPhotoB64Request>({
    defaultValues: {
      workGearTypes: [],
    },
  });

  const testVerifyWorkGearIsInPhotoCall = useAiTestAnalyzeImageForWorkGearB64();
  const verifyWorkGearIsInPhotoCall = useAiVerifyWorkGearIsInPhotoB64();

  const fileGetFileUploadsByCaseCall = useFileFindFileUploadsByCase();

  const fileGetFileCall = useFileGetFileHook();
  const { workGearTypes, greenPlateNumber } = watch();

  useEffect(() => {
    const updatePrompts = async () => {
      try {
        setCurrentUserPrompt(
          await userPromptCall.mutateAsync({
            data: {
              workGearTypes,
              greenPlateNumber,
            },
          })
        );
        setCurrentSystemPrompt(
          await systemPromptCall.mutateAsync({
            data: {
              workGearTypes,
              greenPlateNumber,
            },
          })
        );
      } catch (error) {
        showException(error);
      }
    };
    updatePrompts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workGearTypes, greenPlateNumber]);

  const testImageWithTestPrompot = async () => {
    if (!selectedImageB64) {
      return;
    }
    const mediaType = getMediaTypeFromB64(selectedImageB64);
    if (!mediaType) {
      showException('Could not determine media type');
      return;
    }
    try {
      await testVerifyWorkGearIsInPhotoCall.mutateAsync({
        data: {
          base64Image: selectedImageB64,
          mediaType,
          workGearTypes,
          greenPlateNumber,
          systemPrompt: currentSystemPrompt,
          userPrompt: currentUserPrompt,
        },
      });

      // refetch the history
      await queryClient.invalidateQueries(aiHistoryCall.queryKey);
      aiHistoryCall.refetch();
    } catch (error) {
      showException(error);
    }
  };

  const testImageWithProductionPrompt = async () => {
    if (!selectedImageB64) {
      return;
    }
    const mediaType = getMediaTypeFromB64(selectedImageB64);
    if (!mediaType) {
      showException('Could not determine media type');
      return;
    }
    try {
      await verifyWorkGearIsInPhotoCall.mutateAsync({
        data: {
          base64Image: selectedImageB64,
          mediaType,
          workGearTypes,
          greenPlateNumber,
        },
      });

      // refetch the history
      await queryClient.invalidateQueries(aiHistoryCall.queryKey);
      aiHistoryCall.refetch();
    } catch (error) {
      showException(error);
    }
  };

  const fetchImages = async () => {
    try {
      await fileGetFileUploadsByCaseCall.mutateAsync({
        params: {
          caseEventType: CaseEventType.WorkGearPhoto,
          caseIdOrRegistrationNumber: caseIdOrRegNr,
        },
      });
    } catch (error) {
      showException(error);
    }
  };

  // replace selectedImageB64 with uploadedImage.b64 when the image is uploaded
  useEffect(() => {
    if (uploadedImage) {
      setSelectedImageB64(uploadedImage.b64);
    }
  }, [uploadedImage]);

  // const getAiVerification = useCallback(
  //   (workGearType: WorkGearType) => {
  //     const result = verifyWorkGearIsInPhotoCall.data?.[workGearType];
  //     return result !== WorkGearImageEvaluationResultType.True ? (
  //       <span style={{ color: 'red' }}>- hittades ej i bilden</span>
  //     ) : (
  //       <span style={{ color: 'green' }}>
  //         <FontAwesomeIcon icon={faCheckCircle} />
  //       </span>
  //     );
  //   },
  //   [verifyWorkGearIsInPhotoCall.data]
  // );

  const debouncedFetchImages = useDebounceCallback(fetchImages, 1000, {});
  return (
    <Wrapper>
      <div>
        <h1>Testa att utvärdera en bild med AI</h1>

        <LabelWrap>
          <Horizontal style={{ alignItems: 'center' }}>
            <Input
              style={{ height: 35 }}
              type="file"
              onChange={(eve) => {
                const file = eve.target.files?.[0];
                if (!file) {
                  return;
                }

                const reader = new FileReader();
                reader.onload = (e) => {
                  const img = new Image();
                  img.onload = () => {
                    setUploadedImage({
                      mediaTye: file.type,
                      b64: e.target?.result as string,
                      width: img.width,
                      height: img.height,
                    });
                  };
                  img.src = e.target?.result as string;
                };
                reader.readAsDataURL(file);
              }}
            />
            eller
            <Vertical>
              <Horizontal>
                <Input
                  type="text"
                  placeholder="Ärende-id eller regnr"
                  onChange={(e) => {
                    setCaseIdOrRegNr(e.target.value);
                  }}
                  value={caseIdOrRegNr}
                />
                <Button onClick={debouncedFetchImages}>Hämta bilder</Button>
              </Horizontal>
              <Horizontal
                style={{ maxHeight: 200, maxWidth: 300, overflowX: 'auto' }}
              >
                {fileGetFileUploadsByCaseCall.data?.map((file) => (
                  <TransparentButton
                    onClick={async () => {
                      // get the signed uri
                      const blob = await fileGetFileCall({
                        guid: file.attachmentFileGuid!,
                        fileEnding: file.attachmentFileEnding!,
                      });

                      // convert the signed uri to base64
                      const reader = new FileReader();
                      reader.onload = () => {
                        setSelectedImageB64(reader.result as string);
                      };
                      reader.readAsDataURL(blob);
                    }}
                  >
                    <GenericFileViewer
                      maxHeight={100}
                      fileEnding={file.attachmentFileEnding!}
                      fileGuid={file.attachmentFileGuid!}
                    />
                    Välj bild
                  </TransparentButton>
                ))}
              </Horizontal>
            </Vertical>
          </Horizontal>
        </LabelWrap>

        <LabelWrap label="Vald bild">
          {selectedImageB64 && (
            <div
              style={{
                backgroundImage: `url(${selectedImageB64})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '100%',
                height: 300,
              }}
            />
          )}
        </LabelWrap>

        <LabelWrap label="Leta efter">
          {WorkGearTypeOptions.map((option) => (
            <Checkbox
              key={option.value}
              name="workGearTypes"
              value={option.value}
              onChange={(e) => {
                const { checked } = e.currentTarget;
                const updatedWorkGearTypes = [...watch().workGearTypes];
                if (checked) {
                  updatedWorkGearTypes.push(option.value);
                } else {
                  const index = updatedWorkGearTypes.indexOf(option.value);
                  updatedWorkGearTypes.splice(index, 1);
                }
                setValue('workGearTypes', updatedWorkGearTypes);
              }}
            >
              {workGearTypesTranslations[option.value]}
            </Checkbox>
          ))}
        </LabelWrap>
        {workGearTypes.includes(WorkGearType.GreenPlate) && (
          <LabelWrap label="Grönskylt">
            <Input
              type="text"
              name="greenPlateNumber"
              value={greenPlateNumber}
              onChange={(e) => {
                setValue('greenPlateNumber', e.target.value);
              }}
            />
          </LabelWrap>
        )}
        {currentSystemPrompt && (
          <LabelWrap label={<strong>System prompt</strong>}>
            <AutoSizedTextArea
              value={currentSystemPrompt}
              onChange={(e) => {
                setCurrentSystemPrompt(e.currentTarget.value);
              }}
            />
          </LabelWrap>
        )}
        {currentUserPrompt && (
          <LabelWrap label={<strong>User prompt</strong>}>
            <AutoSizedTextArea
              value={currentUserPrompt}
              onChange={(e) => {
                setCurrentUserPrompt(e.currentTarget.value);
              }}
            />
          </LabelWrap>
        )}

        <Button
          onClick={testImageWithTestPrompot}
          disabled={
            testVerifyWorkGearIsInPhotoCall.status === 'loading' ||
            !selectedImageB64 ||
            workGearTypes.length === 0
          }
        >
          {testVerifyWorkGearIsInPhotoCall.status === 'loading'
            ? 'Testar...'
            : 'Testa bild med testprompt'}
        </Button>
        <Button
          onClick={testImageWithProductionPrompt}
          disabled={
            verifyWorkGearIsInPhotoCall.status === 'loading' ||
            !selectedImageB64 ||
            workGearTypes.length === 0
          }
        >
          {verifyWorkGearIsInPhotoCall.status === 'loading'
            ? 'Testar...'
            : 'Testa bild med produktionsprompt'}
        </Button>
      </div>
      <div>
        <AITestHistory
          history={aiHistoryCall.data}
          onReuseUserPrompt={(prompt) => {
            setCurrentUserPrompt(prompt);
          }}
          onReuseSystemPrompt={(prompt) => {
            setCurrentSystemPrompt(prompt);
          }}
        />
      </div>
    </Wrapper>
  );
};

export default TestAIPromptPage;
