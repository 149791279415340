import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IAddress } from 'utils/address';
import { getHoursAndMinutesFromMillisecondsString } from 'utils/date-helpers';
import useCompanies from 'contexts/basicData/useCompanies';
import useZipCodeAreas from 'hooks/useZipCodeAreas';
import toast from 'react-hot-toast';
import { SliderOption } from 'components/inputs/Slider';
import useGetFixedAssignmentTimeFromAddresses from '../CaseAssignments/useGetFixedAssignmentTimeFromAddresses';
import { AssignmentModel } from 'api/model';

interface UsePlannedStopOverFormProps {
  originalAssignment: AssignmentModel;
  onAddressPicked(
    stopoverAddress: IAddress,
    currentAssignmentEstimatedDuration: number,
    newAssignmentEstimatedDuration: number,
    newAssignmentEstimatedStartTime: Date
  ): void;
}

function usePlannedStopOverForm({
  originalAssignment,
  onAddressPicked,
}: UsePlannedStopOverFormProps) {
  const { companies, getCompanyAddressDictKey } = useCompanies();
  const { zipCodeAreaFromZipCode } = useZipCodeAreas();
  const { getFixedAssignmentTimeFromAddressData } =
    useGetFixedAssignmentTimeFromAddresses();

  const { watch, setValue: setStopOverAddressValue } = useForm<IAddress>({});
  const { name, address, zip, city, county } = watch();

  const [
    currentAssignmentEstimatedDurationId,
    setCurrentAssignmentEstimatedDurationId,
  ] = useState<number>(0);
  const [
    newAssignmentEstimatedDurationId,
    setNewAssignmentEstimatedDurationId,
  ] = useState<number>(0);
  const [
    estimatedStartTimeOfNewAssignment,
    setEstimatedStartTimeOfNewAssignment,
  ] = useState<Date>();

  const fixedAssignmentTimeFromStartToStopover: number | undefined =
    useMemo(() => {
      // find company by address
      const company = companies.find(
        (x) =>
          getCompanyAddressDictKey(x.address, x.zip, x.city) ===
          getCompanyAddressDictKey(address, zip, city)
      );

      const toCounty = zipCodeAreaFromZipCode(company?.zip ?? '');

      if (!company || !county) return undefined;
      const result = getFixedAssignmentTimeFromAddressData({
        fromAddress: {
          name: originalAssignment.fromName,
          address: originalAssignment.fromAddress,
          zip: originalAssignment.fromZip,
          city: originalAssignment.fromCity,
          county: originalAssignment.fromCounty,
        },
        toAddress: {
          name: company.name,
          address: company.address,
          zip: company.zip,
          city: company.city,
          county: toCounty,
        },
      });

      return result?.fixedTimeInMillseconds;
    }, [
      address,
      city,
      companies,
      county,
      getCompanyAddressDictKey,
      getFixedAssignmentTimeFromAddressData,
      originalAssignment.fromAddress,
      originalAssignment.fromCity,
      originalAssignment.fromCounty,
      originalAssignment.fromName,
      originalAssignment.fromZip,
      zip,
      zipCodeAreaFromZipCode,
    ]);

  const fixedAssignmentTimeFromStopoverToEnd: number | undefined =
    useMemo(() => {
      // find company by address
      const company = companies.find(
        (x) =>
          getCompanyAddressDictKey(x.address, x.zip, x.city) ===
          getCompanyAddressDictKey(address, zip, city)
      );
      const fromCounty = zipCodeAreaFromZipCode(company?.zip ?? '');

      if (!company || !county) return undefined;

      const result = getFixedAssignmentTimeFromAddressData({
        fromAddress: {
          name: company.name,
          address: company.address,
          zip: company.zip,
          city: company.city,
          county: fromCounty,
        },
        toAddress: {
          name: originalAssignment.toName,
          address: originalAssignment.toAddress,
          zip: originalAssignment.toZip,
          city: originalAssignment.toCity,
          county: originalAssignment.toCounty,
        },
      });

      return result?.fixedTimeInMillseconds;
    }, [
      address,
      city,
      companies,
      county,
      getCompanyAddressDictKey,
      getFixedAssignmentTimeFromAddressData,
      originalAssignment.toAddress,
      originalAssignment.toCity,
      originalAssignment.toCounty,
      originalAssignment.toName,
      originalAssignment.toZip,
      zip,
      zipCodeAreaFromZipCode,
    ]);

  const hasFixedAssignmentTime =
    fixedAssignmentTimeFromStartToStopover !== undefined ||
    fixedAssignmentTimeFromStopoverToEnd !== undefined;

  const adjustEstimatedStartTimeToNextDay = () => {
    if (!estimatedStartTimeOfNewAssignment) return;
    const newDate = new Date(estimatedStartTimeOfNewAssignment);
    newDate.setDate(newDate.getDate() + 1); // set to next day
    newDate.setHours(7); // set to 7:00
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);
    setEstimatedStartTimeOfNewAssignment(newDate);
  };

  /**
   * Returns a number of possible estimated durations in milliseconds, with:
   * - the min value being 0
   * - the max value being the original assignment's estimated duration
   * - in steps / increments of 1 minute
   * - including the original assignment's estimated duration (even if it's not a multiple of 1 minute)
   */
  const estimatedDurationOptions = useMemo((): SliderOption[] => {
    const originalDuration = originalAssignment.estimatedDuration ?? 0;
    const maxDuration = Math.ceil(originalDuration / 60000) * 60000; // Round up to the nearest minute
    const options: SliderOption[] = [];

    for (let i = 0; i <= maxDuration; i += 60000) {
      // Increment by 1 minute
      options.push({
        value: i,
        label: getHoursAndMinutesFromMillisecondsString(i),
      });
    }

    // Add fixed times if any
    if (fixedAssignmentTimeFromStartToStopover) {
      options.push({
        value: fixedAssignmentTimeFromStartToStopover,
        label: getHoursAndMinutesFromMillisecondsString(
          fixedAssignmentTimeFromStartToStopover
        ),
      });
    }
    if (fixedAssignmentTimeFromStopoverToEnd) {
      options.push({
        value: fixedAssignmentTimeFromStopoverToEnd,
        label: getHoursAndMinutesFromMillisecondsString(
          fixedAssignmentTimeFromStopoverToEnd
        ),
      });
    }

    // Sort the options in ascending order
    options.sort((a, b) => a.value - b.value);

    return options;
  }, [
    fixedAssignmentTimeFromStartToStopover,
    fixedAssignmentTimeFromStopoverToEnd,
    originalAssignment.estimatedDuration,
  ]);

  const isOkToCreateStopover =
    !!name &&
    !!address &&
    !!zip &&
    !!city &&
    !!county &&
    !!currentAssignmentEstimatedDurationId &&
    !!newAssignmentEstimatedDurationId &&
    !!estimatedStartTimeOfNewAssignment;

  const handleOk = () => {
    if (!isOkToCreateStopover) {
      return;
    }
    onAddressPicked(
      { name, address, zip, city, county },
      estimatedDurationOptions[currentAssignmentEstimatedDurationId].value,
      estimatedDurationOptions[newAssignmentEstimatedDurationId].value,
      estimatedStartTimeOfNewAssignment!
    );
  };

  const updateEstimatedStartBasedOnNewDurations = (
    currentAssignmentEstimatedDurationValue: number
  ) => {
    const originalStartTime = originalAssignment.estimatedStartTime;
    if (!originalStartTime) return;
    const newEstimatedStartTime = new Date(
      originalStartTime.getTime() + currentAssignmentEstimatedDurationValue
    );
    setEstimatedStartTimeOfNewAssignment(newEstimatedStartTime);
  };

  const handleCurrentAssignmentEstimatedDurationChange = (
    durationId: number
  ) => {
    const { value } = estimatedDurationOptions[durationId];
    const originalDuration = originalAssignment.estimatedDuration ?? 0;
    const remainingDuration = originalDuration - value;
    const remainingDurationIndex = estimatedDurationOptions.findIndex(
      (option) => option.value >= remainingDuration
    );
    setCurrentAssignmentEstimatedDurationId(durationId);

    if (remainingDurationIndex >= 0) {
      setNewAssignmentEstimatedDurationId(remainingDurationIndex);
    }
    updateEstimatedStartBasedOnNewDurations(value);
  };

  const handleNewAssignmentEstimatedDurationChange = (durationId: number) => {
    const { value } = estimatedDurationOptions[durationId];
    const originalDuration = originalAssignment.estimatedDuration ?? 0;
    const remainingDuration = originalDuration - value;
    const remainingDurationIndex = estimatedDurationOptions.findIndex(
      (option) => option.value >= remainingDuration
    );
    setNewAssignmentEstimatedDurationId(durationId);

    if (remainingDurationIndex >= 0) {
      setCurrentAssignmentEstimatedDurationId(remainingDurationIndex);
    }
    updateEstimatedStartBasedOnNewDurations(remainingDuration);
  };

  useEffect(() => {
    const originalDuration = originalAssignment.estimatedDuration ?? 0;
    const halfDuration = Math.ceil(originalDuration / 2);
    const halfDurationIndex = estimatedDurationOptions.findIndex(
      (option) => option.value >= halfDuration
    );
    handleCurrentAssignmentEstimatedDurationChange(halfDurationIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!fixedAssignmentTimeFromStartToStopover) return;
    const fixedTimeIndex = estimatedDurationOptions.findIndex(
      (option) => option.value === fixedAssignmentTimeFromStartToStopover
    );
    if (fixedTimeIndex >= 0) {
      handleCurrentAssignmentEstimatedDurationChange(fixedTimeIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fixedAssignmentTimeFromStartToStopover]);

  useEffect(() => {
    if (!fixedAssignmentTimeFromStopoverToEnd) return;
    const fixedTimeIndex = estimatedDurationOptions.findIndex(
      (option) => option.value === fixedAssignmentTimeFromStopoverToEnd
    );
    if (fixedTimeIndex >= 0) {
      handleNewAssignmentEstimatedDurationChange(fixedTimeIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fixedAssignmentTimeFromStopoverToEnd]);

  const disableCreation =
    (name === originalAssignment.toName &&
      address === originalAssignment.toAddress &&
      zip === originalAssignment.toZip &&
      city === originalAssignment.toCity &&
      county?.id === originalAssignment.toCounty?.id) ||
    !name ||
    !address ||
    !zip ||
    !city ||
    !county ||
    currentAssignmentEstimatedDurationId === 0 ||
    newAssignmentEstimatedDurationId === 0;

  const originalStartTime = originalAssignment.estimatedStartTime;
  const originalEndTime =
    originalAssignment.estimatedStartTime &&
    originalAssignment.estimatedDuration
      ? new Date(
          originalAssignment.estimatedStartTime.getTime() +
            originalAssignment.estimatedDuration
        )
      : undefined;

  const updatedCurrentAssignmentEndTimeBasedOnNewDurations = useMemo(() => {
    if (!originalStartTime) return undefined;
    const result = new Date(
      originalStartTime.getTime() +
        estimatedDurationOptions[currentAssignmentEstimatedDurationId].value
    );
    return result;
  }, [
    currentAssignmentEstimatedDurationId,
    estimatedDurationOptions,
    originalStartTime,
  ]);

  const updatedNewAssignmentEndTimeBasedOnNewDurations = useMemo(() => {
    if (!estimatedStartTimeOfNewAssignment) return undefined;
    const result = new Date(
      estimatedStartTimeOfNewAssignment.getTime() +
        estimatedDurationOptions[newAssignmentEstimatedDurationId].value
    );
    return result;
  }, [
    estimatedStartTimeOfNewAssignment,
    estimatedDurationOptions,
    newAssignmentEstimatedDurationId,
  ]);

  const endsTooLateOrTooEarly =
    !!address &&
    !!city &&
    !!zip &&
    !!county &&
    updatedNewAssignmentEndTimeBasedOnNewDurations &&
    (updatedNewAssignmentEndTimeBasedOnNewDurations.getHours() > 19 ||
      updatedNewAssignmentEndTimeBasedOnNewDurations.getHours() < 7);

  const handleEstimatedStartTimeChange = (newDate: Date) => {
    if (
      updatedCurrentAssignmentEndTimeBasedOnNewDurations &&
      newDate.getTime() <
        updatedCurrentAssignmentEndTimeBasedOnNewDurations.getTime()
    ) {
      toast.error(
        'Starttiden kan inte vara tidigare än sluttiden för uppdrag 1'
      );
      return;
    }
    setEstimatedStartTimeOfNewAssignment(newDate);
  };

  return {
    stopOverAddress: { name, address, zip, city, county },
    setStopOverAddressValue,
    estimatedDurationOptions,
    currentAssignmentEstimatedDurationId,
    newAssignmentEstimatedDurationId,
    estimatedStartTimeOfNewAssignment,
    setEstimatedStartTimeOfNewAssignment,
    endsTooLateOrTooEarly,
    disableCreation,
    originalStartTime,
    originalEndTime,
    updatedCurrentAssignmentEndTimeBasedOnNewDurations,
    updatedNewAssignmentEndTimeBasedOnNewDurations,
    fixedAssignmentTimeFromStartToStopover,
    fixedAssignmentTimeFromStopoverToEnd,
    hasFixedAssignmentTime,
    handleCurrentAssignmentEstimatedDurationChange,
    handleNewAssignmentEstimatedDurationChange,
    handleEstimatedStartTimeChange,
    adjustEstimatedStartTimeToNextDay,
    handleOk,
  };
}

export default usePlannedStopOverForm;
