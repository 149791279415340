import { FC, useEffect } from 'react';
import { generatePath, Outlet, useNavigate } from 'react-router-dom';

import AssignmentsList from 'components/AssignmentsList';
import FilterBar from 'components/filterBar/FilterBar';
import { AssignmentExtendedModel, CaseTypeEnum } from 'api';
import { RequestStatus } from 'swaggerhooks/lib';
import TransparentSpinner from 'components/spinners/TransparentSpinner';
import ErrorTryAgain from 'components/ErrorTryAgain';
import useFilteredAssignments from './useFilteredAssignments';
import { useAssignmentsFilters } from 'contexts/assignmentsFilters/useAssignmentsFilters';
import Roles from 'constants/Roles';
import useMe from 'contexts/authentication/useMe';
import useAssignmentsBulkOperations from 'pages/GlobalSearch/useAssignmentsBulkOperations';
import BulkMessages from 'pages/GlobalSearch/BulkMessages';
import BulkOperations from 'pages/GlobalSearch/BulkOperations';
import styled from 'styled-components';

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

interface Props {
  caseType: CaseTypeEnum;
  /** for example Routes.purchases.case which contains "/inkop/:id". The substring ":id" is required. */
  casePathPattern: string;
}

const CasesPage: FC<Props> = ({ caseType, casePathPattern }) => {
  const navigate = useNavigate();
  const {
    state: { visibleStatuses },
  } = useAssignmentsFilters();
  const { filteredAssignments, status, update } =
    useFilteredAssignments(caseType);
  const me = useMe();
  const userIsGoSupport = me?.roles.includes(Roles.GoSupport);

  const handleAssignmentItemClick = (model: AssignmentExtendedModel) => {
    navigate(generatePath(casePathPattern, { id: String(model.case.caseID) }));
  };

  const assignmentsBulkOperations = useAssignmentsBulkOperations(
    filteredAssignments ?? [],
    () => update(false)
  );

  const bulkMessagesButton = userIsGoSupport ? (
    <BulkMessages bulkOperations={assignmentsBulkOperations} />
  ) : null;

  useEffect(() => {
    // When caseType changes we are on a new route, clear selected assignments
    return () => {
      assignmentsBulkOperations.clearSelectedAssignments();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseType]);

  // And clear selected assignments when changing status-filter
  useEffect(() => {
    assignmentsBulkOperations.clearSelectedAssignments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleStatuses]);

  const renderList = () => {
    if (status === RequestStatus.Error) {
      return <ErrorTryAgain onTryAgain={() => update(true)} />;
    }

    if (!filteredAssignments) {
      return <TransparentSpinner />;
    }

    return (
      <>
        {!!filteredAssignments.length && userIsGoSupport && (
          <Horizontal>
            <BulkOperations
              bulkActions={[bulkMessagesButton]}
              bulkOperations={assignmentsBulkOperations}
              handleAssignmentItemClick={(model: AssignmentExtendedModel) =>
                handleAssignmentItemClick(model)
              }
            />
          </Horizontal>
        )}
        <AssignmentsList
          assignments={filteredAssignments ?? []}
          bulkOperations={assignmentsBulkOperations}
          loading={status === RequestStatus.Fetching}
          onAssignmentItemClick={handleAssignmentItemClick}
          onAssignmentUpdated={() => update(false)}
          showStatusColors={visibleStatuses === null}
        />
      </>
    );
  };

  return (
    <>
      <Outlet />
      <FilterBar assignments={filteredAssignments ?? []} showAdvancedFilters />
      {renderList()}
    </>
  );
};

export default CasesPage;
