import DashboardTransportLogistics from './DashboardTransportLogistics';
import { DashboardControlsProvider } from 'contexts/Dashboard/useDashboardControls';
import ViewActionsBar from '../../components/Dashboard/ViewActionsBar';
import TLFilterBar from './TLFilterBar';
import { DefaultTLWidgets, TLWidgetsEnum } from './utils';

const TransportLogisticsPage = () => {
  return (
    <DashboardControlsProvider<TLWidgetsEnum>
      storageKey="TransportLogistics"
      defaultWidgets={DefaultTLWidgets}
    >
      <TLFilterBar />
      <ViewActionsBar showSendToFieldTestersModal />
      <DashboardTransportLogistics />
    </DashboardControlsProvider>
  );
};

export default TransportLogisticsPage;
