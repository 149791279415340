import DashboardGoSupport from './DashboardGoSupport';
import { DashboardControlsProvider } from 'contexts/Dashboard/useDashboardControls';
import ViewActionsBar from '../../components/Dashboard/ViewActionsBar';
import GoSupportFilterBar from './GoSupportFilterBar';
import { DefaultGoSupportWidgets, GoSupportWidgetsEnum } from './utils';

const GoSupportPage = () => {
  return (
    <DashboardControlsProvider<GoSupportWidgetsEnum>
      storageKey="GoSupport"
      defaultWidgets={DefaultGoSupportWidgets}
    >
      <GoSupportFilterBar />
      <ViewActionsBar />
      <DashboardGoSupport />
    </DashboardControlsProvider>
  );
};

export default GoSupportPage;
